@mixin nav-item {
  display: block;
  padding: 1.5rem;
  color: currentColor;
  font-weight: 500;
  background-color: #212121;
  margin-bottom: 1px;
}

.main-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: $nav-height-small;
  position: absolute;
  top: 0;
  .main-nav-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(.75);
  }
  @include media-breakpoint-down(lg) {
      .main-nav-logo {
        width: $logo-size;
      }
  }
  @include media-breakpoint-down(md) {
    height: 100%;
    top: 0;
      .main-nav-logo {
        height: 100%;
        img {
          height: 100%;
        }
      }
  }

  .main-nav-toggle {
    cursor: pointer;
    border: 0;
    padding: 2rem;
    position: absolute;
    background: transparent;
    right: 0;
    z-index: 200;
    color: currentColor;
    transition: color 0.4s ease-out;
    height: $nav-height-small;
    @include media-breakpoint-down(lg) {
        height: $nav-height;
    }
    @include media-breakpoint-down(ms) {
      padding: 2rem 1rem;
    }
    svg {
      width: 1.5rem;
    }
    span {
      display: block;
      width: 1.5rem;
      height: 0.125rem;
      background-color: currentColor;
      border-radius: 0.5rem;
      position: relative;
      transition: background-color 0.4s ease-out;
      @include media-breakpoint-down(ms) {
        width: 1.5rem;
      }
      &::before,
      &::after {
        content: '';
        display: block;
        background-color: currentColor;
        border-radius: 0.5rem;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(-0.5rem);
        transition: transform 0.3s ease-out;
      }
      &::after {
        transform: translateY(0.5rem);
      }
    }
  }
}
.main-nav-content {
  width: 100%;
  background-color: #212121;
  height: 100vh;
  z-index: 120;
  position: fixed;
  overflow-y: auto;
  left: 100%;
  top: 0;
  font-weight: 300;
  transition: transform 0.3s ease-out;
  padding: 1.25rem 3.25rem 2rem 2rem;
  .nav-search {
    margin: 0 2rem 1.5rem 0;
    input {
      height: 3.25rem;
      color: #ffffff;
      border: 1px solid transparent;
      background-color: #000;
      &:focus {
        border-color: #888888;
        box-shadow: none;
      }
    }
  }
  .subaru-accordion {
      .card {
          background: transparent;
          .card-header {
              display: flex;
              align-items: center;
              justify-content: space-between;
              background: transparent;
              padding: 0.5rem 2rem;
              font-size: 1rem;
              text-transform: none;
              text-align: left;
              padding-left: 2rem;
              font-weight: 500;
              color: $white;
              transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
              transition-duration: 300ms;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              &:hover {
                opacity: 1;
                left: 0;
                background-color: #171717;
              }
              &.big {
                font-size: 1.125rem;
              }
              svg {
                width: 16px;
                height: 16px;
                cursor: pointer;
                color: white;
                fill: none;
                box-sizing: border-box;
                border-width: 0;
                border-style: solid;
                border-color: #F0F0F0;
                display: block;
                pointer-events: none;
                height: 1rem;
                width: 1rem;
                transform: rotate(180deg);
              }
              &.collapsed svg {
                transform: rotate(0deg);
              }
              // &:after {
              //     background: transparent;
              //     padding: 0.5rem 0;
              //     font-size: 0.75rem;
              // }
              // &.collapsed:after {
              //     background: transparent;
              //     padding: 0.5rem 0;
              //     font-size: 0.75rem;
              // }
          }
          .card-body {
              background: transparent;
              padding: 0.5rem 2rem;
              ul.footer-list {
                  margin: 0;
                  padding-left: 0;
                  li {
                      a {
                        display: block;
                      }
                      a,
                      .card-header {
                          font-size: 0.875rem;
                          padding-top: 0.5rem;
                          padding-bottom: 0.5rem;
                          transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
                          transition-duration: 300ms;
                          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                          padding-left: 0.75rem;
                          padding-right: 2rem;
                          line-height: 1.65rem;
                          font-weight: 500;
                          transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
                          transition-duration: 300ms;
                          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                          &:hover {
                            opacity: 1;
                            left: 0;
                            background-color: #171717;
                          }
                          @include media-breakpoint-up(md) {
                            padding-left: 1.5rem;
                          }
                          @include media-breakpoint-up(lg) {
                          }
                      }
                      .subaru-accordion {
                        .card-header {
                          font-size: 1rem;
                          padding-right: 0 !important;
                        }
                        .card-body {
                          background: transparent;
                          padding-left: 0.75rem;
                          padding-right: 0;
                          
                          @include media-breakpoint-up(md) {
                            padding-left: 1.5rem;
                          }
                        }
                      }
                  }
              }
          }
      }
      .accordion-content {
          .card {
              .card-body {
                  background: transparent;
              }
          }
      }
  }
  hr {
    margin-left: 2rem;
    margin-right: 2rem;
    border-color: #333;
  }
  @include media-breakpoint-up(md) {
    width: 50%;
  }
  @include media-breakpoint-up(lg) {
    width: 33.33333%;
    padding-top: 5rem;
  }
  @include media-breakpoint-up(xl) {
    width: 25%;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
    }
  }
  .menu-main-menu-container {
    margin-left: -2rem;
    margin-right: -3.25rem;
    padding: 0.75rem 0;
    &:not(:last-child) {
      position: relative;
      padding-bottom: 1rem;
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 4rem);
        border-bottom: 1px solid #333;
      }
    }
    &:not(:first-of-type) {
      padding-top: 1rem;
    }
  }
  .menu-item-list {
    & > .menu-item {
      display: block;
      font-weight: 500;
      color: #ffffff;
      line-height: 1.5;
      position: relative;
      font-size: 0.875rem;
      letter-spacing: normal;
      cursor: pointer;
      & > a {
        display: block;
        color: currentColor;
        padding: 0.5rem 2rem;
        font-size: inherit;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
        transition-duration: 300ms;
        text-decoration: none;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        &:hover {
          opacity: 1;
          left: 0;
          text-decoration: none;
          background-color: #171717;
        }
      }
      &.is-accordion-submenu-parent {
        &[aria-expanded='true'] {
          & > a {
            background-color: #000000;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }
    }
    .menu-item {
      a {
        font-weight: 500;
        font-size: 1rem;
        position: relative;
        transition: background 0.3s ease,
                    color 0.3s ease;
      }
      &.active {
        a {
          background-color: transparent;
          transition: background 0.3s ease,
                      color 0.3s ease;
        }
      }
      &.has-submenu-toggle > a {
        margin-right: 0;
      }
      .submenu-toggle {
        color: currentColor;
        height: rem-calc(42);
        width: rem-calc(42);
        right: 1rem;
        &:hover {
          background-color: #000000;
        }
        &::after {
          content: '\e918';
          width: auto;
          height: auto;
          color: currentColor;
          border: 0;
          font-size: 0.75rem;
          @include icon-font;
        }
      }
      &.is-accordion-submenu-parent {
        &:not(.is-submenu-item) {
          & > a {
            z-index: 5;
            display: inline-block;
            &:hover {
              background-color: rgba(0, 0, 0, 0);
            }
          }
          & > .submenu-toggle {
            right: 0;
            width: 100%;
            text-align: right;
            padding-right: 2rem;
            &[aria-expanded='true'] {
              background-color: #000000;
              &:hover {
                background-color: rgba(0, 0, 0, 0.4);
              }
            }
            &:hover {
              background-color: rgba(0, 0, 0, 0.2);
            }
          }
        }
        &:not(.has-submenu-toggle) > a {
          position: relative;
          &::after {
            content: '\e918';
            width: auto;
            height: auto;
            border: 0;
            right: 2rem;
            margin-top: -0.5em;
            font-size: 0.75rem;
            @include icon-font;
          }
        }
        &.is-submenu-item {
          display: flex;
          flex-wrap: wrap;
          .submenu-toggle {
            position: relative;
            top: auto;
            right: auto;
            height: rem-calc(30);
            flex-grow: 1;
            text-align: left;
            padding-left: 0.75rem;
            &:hover {
              background: transparent;
            }
          }
        }
      }
      .is-accordion-submenu {
        padding: 0.5rem 1rem 0.75rem 3rem;
        background-color: rgba(0, 0, 0, 0.2);
        flex: 0 0 100%;
        a {
          font-size: 0.9rem;
          padding: 0.5rem 0;
          color: currentColor;
          &::after {
            position: static;
            display: inline-block;
            margin-left: 0.5rem;
          }
        }
        .is-accordion-submenu {
          padding-top: 0;
          padding-left: 2rem;
          background: none;
          a {
            font-weight: 300;
          }
        }
      }
    }
  }
}

.main-nav.main-nav-open {
  .main-nav-content {
    transform: translateX(-100%);
  }
  .main-nav-toggle {
    color: #ffffff;
    span {
      background-color: transparent;
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
  }
}
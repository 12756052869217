@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-Light.woff2') format('woff2'),
    url('/fonts/DINPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-LightItalic.woff2') format('woff2'),
    url('/fonts/DINPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-Medium.woff2') format('woff2'),
    url('/fonts/DINPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-Italic.woff2') format('woff2'),
    url('/fonts/DINPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-MediumItalic.woff2') format('woff2'),
    url('/fonts/DINPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-Bold.woff2') format('woff2'),
    url('/fonts/DINPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: url('/fonts/DINPro-Black.woff2') format('woff2'),
    url('/fonts/DINPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'subaru-icons';
  src: url('/fonts/subaru-icons.eot?h67hzt');
  src: url('/fonts/subaru-icons.eot?h67hzt#iefix') format('embedded-opentype'),
    url('/fonts/subaru-icons.ttf?h67hzt') format('truetype'),
    url('/fonts/subaru-icons.woff?h67hzt') format('woff'),
    url('/fonts/subaru-icons.svg?h67hzt#subaru-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@mixin icon-font() {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'subaru-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*=' icon-'] {
  @include icon-font;
}

.icon-event-calendar:before {
  content: '\e92c';
}
.icon-minus:before {
  content: '\e92b';
}
.icon-twitter:before {
  content: '\e922';
}
.icon-youtube:before {
  content: '\e923';
}
.icon-linkedin:before {
  content: '\e924';
}
.icon-instagram:before {
  content: '\e925';
}
.icon-brochure-outlined:before {
  content: '\e926';
}
.icon-location-outlined:before {
  content: '\e927';
}
.icon-drive-outlined:before {
  content: '\e928';
}
.icon-facebook:before {
  content: '\e929';
}
.icon-finance-outlined:before {
  content: '\e92a';
}
.icon-upload:before {
  content: '\e900';
}
.icon-Group-453:before {
  content: '\e901';
}
.icon-profile:before {
  content: '\e902';
}
.icon-home:before {
  content: '\e903';
}
.icon-search:before {
  content: '\e904';
}
.icon-arrow-up-right:before {
  content: '\e905';
}
.icon-delete:before {
  content: '\e906';
}
.icon-arrow-up:before {
  content: '\e907';
}
.icon-font:before {
  content: '\e908';
}
.icon-shrink:before {
  content: '\e909';
}
.icon-expand:before {
  content: '\e90a';
}
.icon-skip-forward:before {
  content: '\e90b';
}
.icon-pause:before {
  content: '\e90c';
}
.icon-play:before {
  content: '\e90d';
}
.icon-skip-back:before {
  content: '\e90e';
}
.icon-loop:before {
  content: '\e90f';
}
.icon-chat:before {
  content: '\e910';
}
.icon-plus:before {
  content: '\e911';
}
.icon-close:before {
  content: '\e912';
}
.icon-right:before {
  content: '\e913';
}
.icon-up:before {
  content: '\e914';
}
.icon-down:before {
  content: '\e915';
}
.icon-left:before {
  content: '\e916';
}
.icon-check:before {
  content: '\e917';
}
.icon-chevron-down:before {
  content: '\e918';
}
.icon-chevron-up:before {
  content: '\e919';
}
.icon-chevron-left:before {
  content: '\e91a';
}
.icon-chevron-right:before {
  content: '\e91b';
}
.icon-info:before {
  content: '\e91c';
}
.icon-chevron-right-slim:before {
  content: '\e91d';
}
.icon-chevron-left-slim:before {
  content: '\e91e';
}
.icon-chevron-up-slim:before {
  content: '\e91f';
}
.icon-restart:before {
  content: '\e920';
}
.icon-lock:before {
  content: '\e921';
}

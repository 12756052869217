.subaru-accordion {
    margin-top: 1px;
    .card {
        border-radius: 0;
        border: 0;
        background: transparent;
        .card-header {
            background: $midgrey;
            border-radius: 0;
            padding: 1.5rem 0;
            position: relative;
            cursor: pointer;
            display: block;
            border-bottom: 0;
            &.collapsed:after,
            // &:after {
            //     content: '\f077';
            //     font-family: "Font Awesome 5 Pro";
            //     position: absolute;
            //     top: 50%;
            //     left: inherit;
            //     right: 0;
            //     transform: translateY(-50%);
            //     margin-top: 0;
            //     font-size: 1.25rem;
            //     font-weight: 700;
            //     background-color: $primary;
            //     height: calc(100% - 1px);
            //     width: 70px;
            //     text-align: center;
            //     padding-top: 30px;
            //     line-height: 1;
            //     color: $white;
            //     @include media-breakpoint-down(md) {
            //         font-size: 1rem;
            //         padding-top: 27px;
            //     }
            // }
            // &.collapsed:after {
            //     content: '\f078';
            //     background-color: $midgrey;
            //     color: $white;
            //     @include media-breakpoint-down(sm) {
            //         font-size: 1rem;
            //     }
            // }
            h3 {
                margin-top: 0;
                font-size: 1.25rem;
                padding-left: 3rem;
                color: $white;
                text-align: center;
                text-transform: uppercase;
                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                    padding: 0 70px 0 15px;
                }
                @include media-breakpoint-down(ms) {
                }
            }
        }
        .card-body {
            background: $white;
            padding: 2rem 3rem;
            margin-bottom: 0;
            &.times {
                background: $black;
                color: $white;
            }
            @include media-breakpoint-down(ms) {
                padding: 1.5rem;
            }
            ul {
                padding-left: 30px;
                li {
                    list-style: circle;
                }
            }
        }
    }
}
// .search-accordion .card .accordion-content > .card-body {
//     background: transparent !important;
// }
// .search-accordion > .card {
//     margin-bottom: 0 !important;
// }
body {
  font-family: 'DIN Pro', sans-serif;
  font-weight: 300;
  letter-spacing: -0.025em;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6,.h6 {
    text-transform: uppercase;
    font-weight: 700;
}
.small-text {
    font-size: 0.75rem;
}
.intro-bold {
  font-weight: 700;
  font-size: 1.25rem;
}
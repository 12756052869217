.custom-select {
    &.form-control {
        color: rgba($text-color, 0.9);
    }
}
.enquiry-form {
    margin: 4rem 0 0;
    position: relative;
    @include media-breakpoint-down(md) {
        margin: 2rem 0 0;
    }
    .btn-primary {
        margin-top: 1.5rem;
        padding-left: 2rem;
        padding-right: 2rem;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    ::-webkit-input-placeholder { /* Edge */
      color: rgba($text-color, 0.9);
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: rgba($text-color, 0.9);
    }

    ::placeholder {
      color: rgba($text-color, 0.9);
    }
}
.form-control {
    border-radius: 0;
    border-color: #707070;
    color: $primary;
    height: 48px;
}
textarea {
    &.form-control {
        height: 31vh;
    }
}
.form-check-label {
    font-size: 0.75rem;
}
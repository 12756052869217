@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0099d4;
  --lightgrey: #f5f5f5;
  --midgrey: #e7e7e7;
  --darkgrey: #3c3c3b;
  --white: #ffffff;
  --black: #000;
  --pink: #FFCBFD;
  --darkerGrey: #212121;
  --primary: #0099d4;
  --secondary: #767676;
  --success: #28a745;
  --info: #c8c8c8;
  --warning: #ffc107;
  --danger: #BA2B5D;
  --light: #f8f9fa;
  --dark: #767676;
  --breakpoint-xs: 0;
  --breakpoint-ms: 375px;
  --breakpoint-plus: 378px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1500px;
  --breakpoint-xxxl: 1750px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #f8fafc;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0099d4;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #006288;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f8fafc;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxxl,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-plus,
.container-ms {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 375px) {
  .container-ms, .container {
    max-width: 375px;
  }
}
@media (min-width: 378px) {
  .container-plus, .container-ms, .container {
    max-width: 430px;
  }
}
@media (min-width: 576px) {
  .container-sm, .container-plus, .container-ms, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-plus, .container-ms, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-plus, .container-ms, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-plus, .container-ms, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1500px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-plus, .container-ms, .container {
    max-width: 1410px;
  }
}
@media (min-width: 1750px) {
  .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-plus, .container-ms, .container {
    max-width: 1660px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxxl,
.col-xxxl-auto, .col-xxxl-12, .col-xxxl-11, .col-xxxl-10, .col-xxxl-9, .col-xxxl-8, .col-xxxl-7, .col-xxxl-6, .col-xxxl-5, .col-xxxl-4, .col-xxxl-3, .col-xxxl-2, .col-xxxl-1, .col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-plus,
.col-plus-auto, .col-plus-12, .col-plus-11, .col-plus-10, .col-plus-9, .col-plus-8, .col-plus-7, .col-plus-6, .col-plus-5, .col-plus-4, .col-plus-3, .col-plus-2, .col-plus-1, .col-ms,
.col-ms-auto, .col-ms-12, .col-ms-11, .col-ms-10, .col-ms-9, .col-ms-8, .col-ms-7, .col-ms-6, .col-ms-5, .col-ms-4, .col-ms-3, .col-ms-2, .col-ms-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 375px) {
  .col-ms {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-ms-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-ms-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-ms-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-ms-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-ms-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-ms-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-ms-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-ms-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-ms-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-ms-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-ms-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-ms-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-ms-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-ms-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-ms-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-ms-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-ms-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-ms-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-ms-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-ms-first {
    order: -1;
  }

  .order-ms-last {
    order: 13;
  }

  .order-ms-0 {
    order: 0;
  }

  .order-ms-1 {
    order: 1;
  }

  .order-ms-2 {
    order: 2;
  }

  .order-ms-3 {
    order: 3;
  }

  .order-ms-4 {
    order: 4;
  }

  .order-ms-5 {
    order: 5;
  }

  .order-ms-6 {
    order: 6;
  }

  .order-ms-7 {
    order: 7;
  }

  .order-ms-8 {
    order: 8;
  }

  .order-ms-9 {
    order: 9;
  }

  .order-ms-10 {
    order: 10;
  }

  .order-ms-11 {
    order: 11;
  }

  .order-ms-12 {
    order: 12;
  }

  .offset-ms-0 {
    margin-left: 0;
  }

  .offset-ms-1 {
    margin-left: 8.3333333333%;
  }

  .offset-ms-2 {
    margin-left: 16.6666666667%;
  }

  .offset-ms-3 {
    margin-left: 25%;
  }

  .offset-ms-4 {
    margin-left: 33.3333333333%;
  }

  .offset-ms-5 {
    margin-left: 41.6666666667%;
  }

  .offset-ms-6 {
    margin-left: 50%;
  }

  .offset-ms-7 {
    margin-left: 58.3333333333%;
  }

  .offset-ms-8 {
    margin-left: 66.6666666667%;
  }

  .offset-ms-9 {
    margin-left: 75%;
  }

  .offset-ms-10 {
    margin-left: 83.3333333333%;
  }

  .offset-ms-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 378px) {
  .col-plus {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-plus-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-plus-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-plus-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-plus-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-plus-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-plus-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-plus-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-plus-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-plus-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-plus-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-plus-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-plus-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-plus-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-plus-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-plus-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-plus-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-plus-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-plus-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-plus-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-plus-first {
    order: -1;
  }

  .order-plus-last {
    order: 13;
  }

  .order-plus-0 {
    order: 0;
  }

  .order-plus-1 {
    order: 1;
  }

  .order-plus-2 {
    order: 2;
  }

  .order-plus-3 {
    order: 3;
  }

  .order-plus-4 {
    order: 4;
  }

  .order-plus-5 {
    order: 5;
  }

  .order-plus-6 {
    order: 6;
  }

  .order-plus-7 {
    order: 7;
  }

  .order-plus-8 {
    order: 8;
  }

  .order-plus-9 {
    order: 9;
  }

  .order-plus-10 {
    order: 10;
  }

  .order-plus-11 {
    order: 11;
  }

  .order-plus-12 {
    order: 12;
  }

  .offset-plus-0 {
    margin-left: 0;
  }

  .offset-plus-1 {
    margin-left: 8.3333333333%;
  }

  .offset-plus-2 {
    margin-left: 16.6666666667%;
  }

  .offset-plus-3 {
    margin-left: 25%;
  }

  .offset-plus-4 {
    margin-left: 33.3333333333%;
  }

  .offset-plus-5 {
    margin-left: 41.6666666667%;
  }

  .offset-plus-6 {
    margin-left: 50%;
  }

  .offset-plus-7 {
    margin-left: 58.3333333333%;
  }

  .offset-plus-8 {
    margin-left: 66.6666666667%;
  }

  .offset-plus-9 {
    margin-left: 75%;
  }

  .offset-plus-10 {
    margin-left: 83.3333333333%;
  }

  .offset-plus-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1500px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1750px) {
  .col-xxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxxl-first {
    order: -1;
  }

  .order-xxxl-last {
    order: 13;
  }

  .order-xxxl-0 {
    order: 0;
  }

  .order-xxxl-1 {
    order: 1;
  }

  .order-xxxl-2 {
    order: 2;
  }

  .order-xxxl-3 {
    order: 3;
  }

  .order-xxxl-4 {
    order: 4;
  }

  .order-xxxl-5 {
    order: 5;
  }

  .order-xxxl-6 {
    order: 6;
  }

  .order-xxxl-7 {
    order: 7;
  }

  .order-xxxl-8 {
    order: 8;
  }

  .order-xxxl-9 {
    order: 9;
  }

  .order-xxxl-10 {
    order: 10;
  }

  .order-xxxl-11 {
    order: 11;
  }

  .order-xxxl-12 {
    order: 12;
  }

  .offset-xxxl-0 {
    margin-left: 0;
  }

  .offset-xxxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxxl-3 {
    margin-left: 25%;
  }

  .offset-xxxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxxl-6 {
    margin-left: 50%;
  }

  .offset-xxxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxxl-9 {
    margin-left: 75%;
  }

  .offset-xxxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxxl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e2f3;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7acae9;
}

.table-hover .table-primary:hover {
  background-color: #a2d9ef;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a2d9ef;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d9d9d9;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b8b8b8;
}

.table-hover .table-secondary:hover {
  background-color: #cccccc;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cccccc;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #f0f0f0;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #e2e2e2;
}

.table-hover .table-info:hover {
  background-color: #e3e3e3;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #e3e3e3;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #ecc4d2;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #db91ab;
}

.table-hover .table-danger:hover {
  background-color: #e6b1c3;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #e6b1c3;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #d9d9d9;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #b8b8b8;
}

.table-hover .table-dark:hover {
  background-color: #cccccc;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #cccccc;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #ffffff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 374.98px) {
  .table-responsive-ms {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-ms > .table-bordered {
    border: 0;
  }
}
@media (max-width: 377.98px) {
  .table-responsive-plus {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-plus > .table-bordered {
    border: 0;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1499.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1749.98px) {
  .table-responsive-xxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
.form-control:focus {
  color: #495057;
  background-color: #ffffff;
  border-color: #55d0ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 212, 0.25);
}
.form-control::placeholder {
  color: #0099d4;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #BA2B5D;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(186, 43, 93, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #BA2B5D;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23BA2B5D' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BA2B5D' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #BA2B5D;
  box-shadow: 0 0 0 0.2rem rgba(186, 43, 93, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #BA2B5D;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23BA2B5D' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23BA2B5D' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #BA2B5D;
  box-shadow: 0 0 0 0.2rem rgba(186, 43, 93, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #BA2B5D;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #BA2B5D;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #BA2B5D;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #d44476;
  background-color: #d44476;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(186, 43, 93, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #BA2B5D;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #BA2B5D;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #BA2B5D;
  box-shadow: 0 0 0 0.2rem rgba(186, 43, 93, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 212, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #ffffff;
  background-color: #0099d4;
  border-color: #0099d4;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #007dae;
  border-color: #0074a1;
}
.btn-primary:focus, .btn-primary.focus {
  color: #ffffff;
  background-color: #007dae;
  border-color: #0074a1;
  box-shadow: 0 0 0 0.2rem rgba(38, 168, 218, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #ffffff;
  background-color: #0099d4;
  border-color: #0099d4;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #0074a1;
  border-color: #006b94;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 168, 218, 0.5);
}

.btn-secondary {
  color: #ffffff;
  background-color: #767676;
  border-color: #767676;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #636363;
  border-color: #5d5d5d;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #ffffff;
  background-color: #636363;
  border-color: #5d5d5d;
  box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #ffffff;
  background-color: #767676;
  border-color: #767676;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #5d5d5d;
  border-color: #565656;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5);
}

.btn-success {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #ffffff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #ffffff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #212529;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}
.btn-info:hover {
  color: #212529;
  background-color: #b5b5b5;
  border-color: #afafaf;
}
.btn-info:focus, .btn-info.focus {
  color: #212529;
  background-color: #b5b5b5;
  border-color: #afafaf;
  box-shadow: 0 0 0 0.2rem rgba(175, 176, 176, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #212529;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #212529;
  background-color: #afafaf;
  border-color: #a8a8a8;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(175, 176, 176, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #ffffff;
  background-color: #BA2B5D;
  border-color: #BA2B5D;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #9b244d;
  border-color: #912148;
}
.btn-danger:focus, .btn-danger.focus {
  color: #ffffff;
  background-color: #9b244d;
  border-color: #912148;
  box-shadow: 0 0 0 0.2rem rgba(196, 75, 117, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #ffffff;
  background-color: #BA2B5D;
  border-color: #BA2B5D;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #912148;
  border-color: #861f43;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(196, 75, 117, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #ffffff;
  background-color: #767676;
  border-color: #767676;
}
.btn-dark:hover {
  color: #ffffff;
  background-color: #636363;
  border-color: #5d5d5d;
}
.btn-dark:focus, .btn-dark.focus {
  color: #ffffff;
  background-color: #636363;
  border-color: #5d5d5d;
  box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #ffffff;
  background-color: #767676;
  border-color: #767676;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #5d5d5d;
  border-color: #565656;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(139, 139, 139, 0.5);
}

.btn-outline-primary {
  color: #0099d4;
  border-color: #0099d4;
}
.btn-outline-primary:hover {
  color: #ffffff;
  background-color: #0099d4;
  border-color: #0099d4;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 212, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0099d4;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #ffffff;
  background-color: #0099d4;
  border-color: #0099d4;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 212, 0.5);
}

.btn-outline-secondary {
  color: #767676;
  border-color: #767676;
}
.btn-outline-secondary:hover {
  color: #ffffff;
  background-color: #767676;
  border-color: #767676;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(118, 118, 118, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #767676;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #767676;
  border-color: #767676;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(118, 118, 118, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #ffffff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #c8c8c8;
  border-color: #c8c8c8;
}
.btn-outline-info:hover {
  color: #212529;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #c8c8c8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #212529;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #BA2B5D;
  border-color: #BA2B5D;
}
.btn-outline-danger:hover {
  color: #ffffff;
  background-color: #BA2B5D;
  border-color: #BA2B5D;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(186, 43, 93, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #BA2B5D;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #ffffff;
  background-color: #BA2B5D;
  border-color: #BA2B5D;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(186, 43, 93, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #767676;
  border-color: #767676;
}
.btn-outline-dark:hover {
  color: #ffffff;
  background-color: #767676;
  border-color: #767676;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(118, 118, 118, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #767676;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #ffffff;
  background-color: #767676;
  border-color: #767676;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(118, 118, 118, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0099d4;
  text-decoration: none;
}
.btn-link:hover {
  color: #006288;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 375px) {
  .dropdown-menu-ms-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-ms-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 378px) {
  .dropdown-menu-plus-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-plus-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1500px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1750px) {
  .dropdown-menu-xxxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #0099d4;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff;
  border-color: #0099d4;
  background-color: #0099d4;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 212, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #55d0ff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #ffffff;
  background-color: #88deff;
  border-color: #88deff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #ffffff;
  border: #adb5bd solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0099d4;
  background-color: #0099d4;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 153, 212, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 153, 212, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 153, 212, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffffff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 153, 212, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #55d0ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 212, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #ffffff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #55d0ff;
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 212, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(0, 153, 212, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(0, 153, 212, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f8fafc, 0 0 0 0.2rem rgba(0, 153, 212, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0099d4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #88deff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0099d4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #88deff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0099d4;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #88deff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f8fafc;
  border-color: #dee2e6 #dee2e6 #f8fafc;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #0099d4;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-ms,
.navbar .container-plus,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl,
.navbar .container-xxxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 374.98px) {
  .navbar-expand-ms > .container,
.navbar-expand-ms > .container-fluid,
.navbar-expand-ms > .container-ms,
.navbar-expand-ms > .container-plus,
.navbar-expand-ms > .container-sm,
.navbar-expand-ms > .container-md,
.navbar-expand-ms > .container-lg,
.navbar-expand-ms > .container-xl,
.navbar-expand-ms > .container-xxl,
.navbar-expand-ms > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 375px) {
  .navbar-expand-ms {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-ms .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-ms .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-ms .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-ms > .container,
.navbar-expand-ms > .container-fluid,
.navbar-expand-ms > .container-ms,
.navbar-expand-ms > .container-plus,
.navbar-expand-ms > .container-sm,
.navbar-expand-ms > .container-md,
.navbar-expand-ms > .container-lg,
.navbar-expand-ms > .container-xl,
.navbar-expand-ms > .container-xxl,
.navbar-expand-ms > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-ms .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-ms .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-ms .navbar-toggler {
    display: none;
  }
}
@media (max-width: 377.98px) {
  .navbar-expand-plus > .container,
.navbar-expand-plus > .container-fluid,
.navbar-expand-plus > .container-ms,
.navbar-expand-plus > .container-plus,
.navbar-expand-plus > .container-sm,
.navbar-expand-plus > .container-md,
.navbar-expand-plus > .container-lg,
.navbar-expand-plus > .container-xl,
.navbar-expand-plus > .container-xxl,
.navbar-expand-plus > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 378px) {
  .navbar-expand-plus {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-plus .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-plus .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-plus .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-plus > .container,
.navbar-expand-plus > .container-fluid,
.navbar-expand-plus > .container-ms,
.navbar-expand-plus > .container-plus,
.navbar-expand-plus > .container-sm,
.navbar-expand-plus > .container-md,
.navbar-expand-plus > .container-lg,
.navbar-expand-plus > .container-xl,
.navbar-expand-plus > .container-xxl,
.navbar-expand-plus > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-plus .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-plus .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-plus .navbar-toggler {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-ms,
.navbar-expand-sm > .container-plus,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl,
.navbar-expand-sm > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-ms,
.navbar-expand-sm > .container-plus,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl,
.navbar-expand-sm > .container-xxl,
.navbar-expand-sm > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-ms,
.navbar-expand-md > .container-plus,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl,
.navbar-expand-md > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-ms,
.navbar-expand-md > .container-plus,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl,
.navbar-expand-md > .container-xxl,
.navbar-expand-md > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-ms,
.navbar-expand-lg > .container-plus,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl,
.navbar-expand-lg > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-ms,
.navbar-expand-lg > .container-plus,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl,
.navbar-expand-lg > .container-xxl,
.navbar-expand-lg > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-ms,
.navbar-expand-xl > .container-plus,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl,
.navbar-expand-xl > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-ms,
.navbar-expand-xl > .container-plus,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl,
.navbar-expand-xl > .container-xxl,
.navbar-expand-xl > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1499.98px) {
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-ms,
.navbar-expand-xxl > .container-plus,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl,
.navbar-expand-xxl > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1500px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-ms,
.navbar-expand-xxl > .container-plus,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl,
.navbar-expand-xxl > .container-xxl,
.navbar-expand-xxl > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1749.98px) {
  .navbar-expand-xxxl > .container,
.navbar-expand-xxxl > .container-fluid,
.navbar-expand-xxxl > .container-ms,
.navbar-expand-xxxl > .container-plus,
.navbar-expand-xxxl > .container-sm,
.navbar-expand-xxxl > .container-md,
.navbar-expand-xxxl > .container-lg,
.navbar-expand-xxxl > .container-xl,
.navbar-expand-xxxl > .container-xxl,
.navbar-expand-xxxl > .container-xxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1750px) {
  .navbar-expand-xxxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxxl > .container,
.navbar-expand-xxxl > .container-fluid,
.navbar-expand-xxxl > .container-ms,
.navbar-expand-xxxl > .container-plus,
.navbar-expand-xxxl > .container-sm,
.navbar-expand-xxxl > .container-md,
.navbar-expand-xxxl > .container-lg,
.navbar-expand-xxxl > .container-xl,
.navbar-expand-xxxl > .container-xxl,
.navbar-expand-xxxl > .container-xxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-ms,
.navbar-expand > .container-plus,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl,
.navbar-expand > .container-xxxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-ms,
.navbar-expand > .container-plus,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl,
.navbar-expand > .container-xxxl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #ffffff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #ffffff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #ffffff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0099d4;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #006288;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 212, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #0099d4;
  border-color: #0099d4;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #ffffff;
  background-color: #0099d4;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #ffffff;
  background-color: #0074a1;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 153, 212, 0.5);
}

.badge-secondary {
  color: #ffffff;
  background-color: #767676;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #ffffff;
  background-color: #5d5d5d;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(118, 118, 118, 0.5);
}

.badge-success {
  color: #ffffff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #ffffff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #212529;
  background-color: #c8c8c8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #212529;
  background-color: #afafaf;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #ffffff;
  background-color: #BA2B5D;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #ffffff;
  background-color: #912148;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(186, 43, 93, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #ffffff;
  background-color: #767676;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #ffffff;
  background-color: #5d5d5d;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(118, 118, 118, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #00506e;
  background-color: #ccebf6;
  border-color: #b8e2f3;
}
.alert-primary hr {
  border-top-color: #a2d9ef;
}
.alert-primary .alert-link {
  color: #002b3b;
}

.alert-secondary {
  color: #3d3d3d;
  background-color: #e4e4e4;
  border-color: #d9d9d9;
}
.alert-secondary hr {
  border-top-color: #cccccc;
}
.alert-secondary .alert-link {
  color: #242424;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #686868;
  background-color: #f4f4f4;
  border-color: #f0f0f0;
}
.alert-info hr {
  border-top-color: #e3e3e3;
}
.alert-info .alert-link {
  color: #4f4f4f;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #611630;
  background-color: #f1d5df;
  border-color: #ecc4d2;
}
.alert-danger hr {
  border-top-color: #e6b1c3;
}
.alert-danger .alert-link {
  color: #370d1b;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #3d3d3d;
  background-color: #e4e4e4;
  border-color: #d9d9d9;
}
.alert-dark hr {
  border-top-color: #cccccc;
}
.alert-dark .alert-link {
  color: #242424;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #0099d4;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #ffffff;
}
.list-group-item.active {
  z-index: 2;
  color: #ffffff;
  background-color: #0099d4;
  border-color: #0099d4;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 375px) {
  .list-group-horizontal-ms {
    flex-direction: row;
  }
  .list-group-horizontal-ms > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-ms > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-ms > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-ms > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-ms > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 378px) {
  .list-group-horizontal-plus {
    flex-direction: row;
  }
  .list-group-horizontal-plus > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-plus > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-plus > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-plus > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-plus > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1500px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1750px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #00506e;
  background-color: #b8e2f3;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #00506e;
  background-color: #a2d9ef;
}
.list-group-item-primary.list-group-item-action.active {
  color: #ffffff;
  background-color: #00506e;
  border-color: #00506e;
}

.list-group-item-secondary {
  color: #3d3d3d;
  background-color: #d9d9d9;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #3d3d3d;
  background-color: #cccccc;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #ffffff;
  background-color: #3d3d3d;
  border-color: #3d3d3d;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #ffffff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #686868;
  background-color: #f0f0f0;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #686868;
  background-color: #e3e3e3;
}
.list-group-item-info.list-group-item-action.active {
  color: #ffffff;
  background-color: #686868;
  border-color: #686868;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #ffffff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #611630;
  background-color: #ecc4d2;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #611630;
  background-color: #e6b1c3;
}
.list-group-item-danger.list-group-item-action.active {
  color: #ffffff;
  background-color: #611630;
  border-color: #611630;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #ffffff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #3d3d3d;
  background-color: #d9d9d9;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #3d3d3d;
  background-color: #cccccc;
}
.list-group-item-dark.list-group-item-action.active {
  color: #ffffff;
  background-color: #3d3d3d;
  border-color: #3d3d3d;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.5;
}
.close:hover {
  color: #000000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #ffffff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #ffffff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #ffffff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #ffffff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ffffff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0099d4 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0074a1 !important;
}

.bg-secondary {
  background-color: #767676 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #5d5d5d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #c8c8c8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #afafaf !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #BA2B5D !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #912148 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #767676 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #5d5d5d !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0099d4 !important;
}

.border-secondary {
  border-color: #767676 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #c8c8c8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #BA2B5D !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #767676 !important;
}

.border-white {
  border-color: #ffffff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 375px) {
  .d-ms-none {
    display: none !important;
  }

  .d-ms-inline {
    display: inline !important;
  }

  .d-ms-inline-block {
    display: inline-block !important;
  }

  .d-ms-block {
    display: block !important;
  }

  .d-ms-table {
    display: table !important;
  }

  .d-ms-table-row {
    display: table-row !important;
  }

  .d-ms-table-cell {
    display: table-cell !important;
  }

  .d-ms-flex {
    display: flex !important;
  }

  .d-ms-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 378px) {
  .d-plus-none {
    display: none !important;
  }

  .d-plus-inline {
    display: inline !important;
  }

  .d-plus-inline-block {
    display: inline-block !important;
  }

  .d-plus-block {
    display: block !important;
  }

  .d-plus-table {
    display: table !important;
  }

  .d-plus-table-row {
    display: table-row !important;
  }

  .d-plus-table-cell {
    display: table-cell !important;
  }

  .d-plus-flex {
    display: flex !important;
  }

  .d-plus-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1500px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1750px) {
  .d-xxxl-none {
    display: none !important;
  }

  .d-xxxl-inline {
    display: inline !important;
  }

  .d-xxxl-inline-block {
    display: inline-block !important;
  }

  .d-xxxl-block {
    display: block !important;
  }

  .d-xxxl-table {
    display: table !important;
  }

  .d-xxxl-table-row {
    display: table-row !important;
  }

  .d-xxxl-table-cell {
    display: table-cell !important;
  }

  .d-xxxl-flex {
    display: flex !important;
  }

  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 375px) {
  .flex-ms-row {
    flex-direction: row !important;
  }

  .flex-ms-column {
    flex-direction: column !important;
  }

  .flex-ms-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-ms-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-ms-wrap {
    flex-wrap: wrap !important;
  }

  .flex-ms-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-ms-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-ms-fill {
    flex: 1 1 auto !important;
  }

  .flex-ms-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-ms-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-ms-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-ms-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-ms-start {
    justify-content: flex-start !important;
  }

  .justify-content-ms-end {
    justify-content: flex-end !important;
  }

  .justify-content-ms-center {
    justify-content: center !important;
  }

  .justify-content-ms-between {
    justify-content: space-between !important;
  }

  .justify-content-ms-around {
    justify-content: space-around !important;
  }

  .align-items-ms-start {
    align-items: flex-start !important;
  }

  .align-items-ms-end {
    align-items: flex-end !important;
  }

  .align-items-ms-center {
    align-items: center !important;
  }

  .align-items-ms-baseline {
    align-items: baseline !important;
  }

  .align-items-ms-stretch {
    align-items: stretch !important;
  }

  .align-content-ms-start {
    align-content: flex-start !important;
  }

  .align-content-ms-end {
    align-content: flex-end !important;
  }

  .align-content-ms-center {
    align-content: center !important;
  }

  .align-content-ms-between {
    align-content: space-between !important;
  }

  .align-content-ms-around {
    align-content: space-around !important;
  }

  .align-content-ms-stretch {
    align-content: stretch !important;
  }

  .align-self-ms-auto {
    align-self: auto !important;
  }

  .align-self-ms-start {
    align-self: flex-start !important;
  }

  .align-self-ms-end {
    align-self: flex-end !important;
  }

  .align-self-ms-center {
    align-self: center !important;
  }

  .align-self-ms-baseline {
    align-self: baseline !important;
  }

  .align-self-ms-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 378px) {
  .flex-plus-row {
    flex-direction: row !important;
  }

  .flex-plus-column {
    flex-direction: column !important;
  }

  .flex-plus-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-plus-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-plus-wrap {
    flex-wrap: wrap !important;
  }

  .flex-plus-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-plus-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-plus-fill {
    flex: 1 1 auto !important;
  }

  .flex-plus-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-plus-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-plus-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-plus-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-plus-start {
    justify-content: flex-start !important;
  }

  .justify-content-plus-end {
    justify-content: flex-end !important;
  }

  .justify-content-plus-center {
    justify-content: center !important;
  }

  .justify-content-plus-between {
    justify-content: space-between !important;
  }

  .justify-content-plus-around {
    justify-content: space-around !important;
  }

  .align-items-plus-start {
    align-items: flex-start !important;
  }

  .align-items-plus-end {
    align-items: flex-end !important;
  }

  .align-items-plus-center {
    align-items: center !important;
  }

  .align-items-plus-baseline {
    align-items: baseline !important;
  }

  .align-items-plus-stretch {
    align-items: stretch !important;
  }

  .align-content-plus-start {
    align-content: flex-start !important;
  }

  .align-content-plus-end {
    align-content: flex-end !important;
  }

  .align-content-plus-center {
    align-content: center !important;
  }

  .align-content-plus-between {
    align-content: space-between !important;
  }

  .align-content-plus-around {
    align-content: space-around !important;
  }

  .align-content-plus-stretch {
    align-content: stretch !important;
  }

  .align-self-plus-auto {
    align-self: auto !important;
  }

  .align-self-plus-start {
    align-self: flex-start !important;
  }

  .align-self-plus-end {
    align-self: flex-end !important;
  }

  .align-self-plus-center {
    align-self: center !important;
  }

  .align-self-plus-baseline {
    align-self: baseline !important;
  }

  .align-self-plus-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1500px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1750px) {
  .flex-xxxl-row {
    flex-direction: row !important;
  }

  .flex-xxxl-column {
    flex-direction: column !important;
  }

  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxxl-center {
    justify-content: center !important;
  }

  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxxl-center {
    align-items: center !important;
  }

  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxxl-center {
    align-content: center !important;
  }

  .align-content-xxxl-between {
    align-content: space-between !important;
  }

  .align-content-xxxl-around {
    align-content: space-around !important;
  }

  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxxl-auto {
    align-self: auto !important;
  }

  .align-self-xxxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxxl-center {
    align-self: center !important;
  }

  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 375px) {
  .float-ms-left {
    float: left !important;
  }

  .float-ms-right {
    float: right !important;
  }

  .float-ms-none {
    float: none !important;
  }
}
@media (min-width: 378px) {
  .float-plus-left {
    float: left !important;
  }

  .float-plus-right {
    float: right !important;
  }

  .float-plus-none {
    float: none !important;
  }
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1500px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}
@media (min-width: 1750px) {
  .float-xxxl-left {
    float: left !important;
  }

  .float-xxxl-right {
    float: right !important;
  }

  .float-xxxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 375px) {
  .m-ms-0 {
    margin: 0 !important;
  }

  .mt-ms-0,
.my-ms-0 {
    margin-top: 0 !important;
  }

  .mr-ms-0,
.mx-ms-0 {
    margin-right: 0 !important;
  }

  .mb-ms-0,
.my-ms-0 {
    margin-bottom: 0 !important;
  }

  .ml-ms-0,
.mx-ms-0 {
    margin-left: 0 !important;
  }

  .m-ms-1 {
    margin: 0.25rem !important;
  }

  .mt-ms-1,
.my-ms-1 {
    margin-top: 0.25rem !important;
  }

  .mr-ms-1,
.mx-ms-1 {
    margin-right: 0.25rem !important;
  }

  .mb-ms-1,
.my-ms-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-ms-1,
.mx-ms-1 {
    margin-left: 0.25rem !important;
  }

  .m-ms-2 {
    margin: 0.5rem !important;
  }

  .mt-ms-2,
.my-ms-2 {
    margin-top: 0.5rem !important;
  }

  .mr-ms-2,
.mx-ms-2 {
    margin-right: 0.5rem !important;
  }

  .mb-ms-2,
.my-ms-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-ms-2,
.mx-ms-2 {
    margin-left: 0.5rem !important;
  }

  .m-ms-3 {
    margin: 1rem !important;
  }

  .mt-ms-3,
.my-ms-3 {
    margin-top: 1rem !important;
  }

  .mr-ms-3,
.mx-ms-3 {
    margin-right: 1rem !important;
  }

  .mb-ms-3,
.my-ms-3 {
    margin-bottom: 1rem !important;
  }

  .ml-ms-3,
.mx-ms-3 {
    margin-left: 1rem !important;
  }

  .m-ms-4 {
    margin: 1.5rem !important;
  }

  .mt-ms-4,
.my-ms-4 {
    margin-top: 1.5rem !important;
  }

  .mr-ms-4,
.mx-ms-4 {
    margin-right: 1.5rem !important;
  }

  .mb-ms-4,
.my-ms-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-ms-4,
.mx-ms-4 {
    margin-left: 1.5rem !important;
  }

  .m-ms-5 {
    margin: 3rem !important;
  }

  .mt-ms-5,
.my-ms-5 {
    margin-top: 3rem !important;
  }

  .mr-ms-5,
.mx-ms-5 {
    margin-right: 3rem !important;
  }

  .mb-ms-5,
.my-ms-5 {
    margin-bottom: 3rem !important;
  }

  .ml-ms-5,
.mx-ms-5 {
    margin-left: 3rem !important;
  }

  .p-ms-0 {
    padding: 0 !important;
  }

  .pt-ms-0,
.py-ms-0 {
    padding-top: 0 !important;
  }

  .pr-ms-0,
.px-ms-0 {
    padding-right: 0 !important;
  }

  .pb-ms-0,
.py-ms-0 {
    padding-bottom: 0 !important;
  }

  .pl-ms-0,
.px-ms-0 {
    padding-left: 0 !important;
  }

  .p-ms-1 {
    padding: 0.25rem !important;
  }

  .pt-ms-1,
.py-ms-1 {
    padding-top: 0.25rem !important;
  }

  .pr-ms-1,
.px-ms-1 {
    padding-right: 0.25rem !important;
  }

  .pb-ms-1,
.py-ms-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-ms-1,
.px-ms-1 {
    padding-left: 0.25rem !important;
  }

  .p-ms-2 {
    padding: 0.5rem !important;
  }

  .pt-ms-2,
.py-ms-2 {
    padding-top: 0.5rem !important;
  }

  .pr-ms-2,
.px-ms-2 {
    padding-right: 0.5rem !important;
  }

  .pb-ms-2,
.py-ms-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-ms-2,
.px-ms-2 {
    padding-left: 0.5rem !important;
  }

  .p-ms-3 {
    padding: 1rem !important;
  }

  .pt-ms-3,
.py-ms-3 {
    padding-top: 1rem !important;
  }

  .pr-ms-3,
.px-ms-3 {
    padding-right: 1rem !important;
  }

  .pb-ms-3,
.py-ms-3 {
    padding-bottom: 1rem !important;
  }

  .pl-ms-3,
.px-ms-3 {
    padding-left: 1rem !important;
  }

  .p-ms-4 {
    padding: 1.5rem !important;
  }

  .pt-ms-4,
.py-ms-4 {
    padding-top: 1.5rem !important;
  }

  .pr-ms-4,
.px-ms-4 {
    padding-right: 1.5rem !important;
  }

  .pb-ms-4,
.py-ms-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-ms-4,
.px-ms-4 {
    padding-left: 1.5rem !important;
  }

  .p-ms-5 {
    padding: 3rem !important;
  }

  .pt-ms-5,
.py-ms-5 {
    padding-top: 3rem !important;
  }

  .pr-ms-5,
.px-ms-5 {
    padding-right: 3rem !important;
  }

  .pb-ms-5,
.py-ms-5 {
    padding-bottom: 3rem !important;
  }

  .pl-ms-5,
.px-ms-5 {
    padding-left: 3rem !important;
  }

  .m-ms-n1 {
    margin: -0.25rem !important;
  }

  .mt-ms-n1,
.my-ms-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-ms-n1,
.mx-ms-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-ms-n1,
.my-ms-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-ms-n1,
.mx-ms-n1 {
    margin-left: -0.25rem !important;
  }

  .m-ms-n2 {
    margin: -0.5rem !important;
  }

  .mt-ms-n2,
.my-ms-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-ms-n2,
.mx-ms-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-ms-n2,
.my-ms-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-ms-n2,
.mx-ms-n2 {
    margin-left: -0.5rem !important;
  }

  .m-ms-n3 {
    margin: -1rem !important;
  }

  .mt-ms-n3,
.my-ms-n3 {
    margin-top: -1rem !important;
  }

  .mr-ms-n3,
.mx-ms-n3 {
    margin-right: -1rem !important;
  }

  .mb-ms-n3,
.my-ms-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-ms-n3,
.mx-ms-n3 {
    margin-left: -1rem !important;
  }

  .m-ms-n4 {
    margin: -1.5rem !important;
  }

  .mt-ms-n4,
.my-ms-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-ms-n4,
.mx-ms-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-ms-n4,
.my-ms-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-ms-n4,
.mx-ms-n4 {
    margin-left: -1.5rem !important;
  }

  .m-ms-n5 {
    margin: -3rem !important;
  }

  .mt-ms-n5,
.my-ms-n5 {
    margin-top: -3rem !important;
  }

  .mr-ms-n5,
.mx-ms-n5 {
    margin-right: -3rem !important;
  }

  .mb-ms-n5,
.my-ms-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-ms-n5,
.mx-ms-n5 {
    margin-left: -3rem !important;
  }

  .m-ms-auto {
    margin: auto !important;
  }

  .mt-ms-auto,
.my-ms-auto {
    margin-top: auto !important;
  }

  .mr-ms-auto,
.mx-ms-auto {
    margin-right: auto !important;
  }

  .mb-ms-auto,
.my-ms-auto {
    margin-bottom: auto !important;
  }

  .ml-ms-auto,
.mx-ms-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 378px) {
  .m-plus-0 {
    margin: 0 !important;
  }

  .mt-plus-0,
.my-plus-0 {
    margin-top: 0 !important;
  }

  .mr-plus-0,
.mx-plus-0 {
    margin-right: 0 !important;
  }

  .mb-plus-0,
.my-plus-0 {
    margin-bottom: 0 !important;
  }

  .ml-plus-0,
.mx-plus-0 {
    margin-left: 0 !important;
  }

  .m-plus-1 {
    margin: 0.25rem !important;
  }

  .mt-plus-1,
.my-plus-1 {
    margin-top: 0.25rem !important;
  }

  .mr-plus-1,
.mx-plus-1 {
    margin-right: 0.25rem !important;
  }

  .mb-plus-1,
.my-plus-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-plus-1,
.mx-plus-1 {
    margin-left: 0.25rem !important;
  }

  .m-plus-2 {
    margin: 0.5rem !important;
  }

  .mt-plus-2,
.my-plus-2 {
    margin-top: 0.5rem !important;
  }

  .mr-plus-2,
.mx-plus-2 {
    margin-right: 0.5rem !important;
  }

  .mb-plus-2,
.my-plus-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-plus-2,
.mx-plus-2 {
    margin-left: 0.5rem !important;
  }

  .m-plus-3 {
    margin: 1rem !important;
  }

  .mt-plus-3,
.my-plus-3 {
    margin-top: 1rem !important;
  }

  .mr-plus-3,
.mx-plus-3 {
    margin-right: 1rem !important;
  }

  .mb-plus-3,
.my-plus-3 {
    margin-bottom: 1rem !important;
  }

  .ml-plus-3,
.mx-plus-3 {
    margin-left: 1rem !important;
  }

  .m-plus-4 {
    margin: 1.5rem !important;
  }

  .mt-plus-4,
.my-plus-4 {
    margin-top: 1.5rem !important;
  }

  .mr-plus-4,
.mx-plus-4 {
    margin-right: 1.5rem !important;
  }

  .mb-plus-4,
.my-plus-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-plus-4,
.mx-plus-4 {
    margin-left: 1.5rem !important;
  }

  .m-plus-5 {
    margin: 3rem !important;
  }

  .mt-plus-5,
.my-plus-5 {
    margin-top: 3rem !important;
  }

  .mr-plus-5,
.mx-plus-5 {
    margin-right: 3rem !important;
  }

  .mb-plus-5,
.my-plus-5 {
    margin-bottom: 3rem !important;
  }

  .ml-plus-5,
.mx-plus-5 {
    margin-left: 3rem !important;
  }

  .p-plus-0 {
    padding: 0 !important;
  }

  .pt-plus-0,
.py-plus-0 {
    padding-top: 0 !important;
  }

  .pr-plus-0,
.px-plus-0 {
    padding-right: 0 !important;
  }

  .pb-plus-0,
.py-plus-0 {
    padding-bottom: 0 !important;
  }

  .pl-plus-0,
.px-plus-0 {
    padding-left: 0 !important;
  }

  .p-plus-1 {
    padding: 0.25rem !important;
  }

  .pt-plus-1,
.py-plus-1 {
    padding-top: 0.25rem !important;
  }

  .pr-plus-1,
.px-plus-1 {
    padding-right: 0.25rem !important;
  }

  .pb-plus-1,
.py-plus-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-plus-1,
.px-plus-1 {
    padding-left: 0.25rem !important;
  }

  .p-plus-2 {
    padding: 0.5rem !important;
  }

  .pt-plus-2,
.py-plus-2 {
    padding-top: 0.5rem !important;
  }

  .pr-plus-2,
.px-plus-2 {
    padding-right: 0.5rem !important;
  }

  .pb-plus-2,
.py-plus-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-plus-2,
.px-plus-2 {
    padding-left: 0.5rem !important;
  }

  .p-plus-3 {
    padding: 1rem !important;
  }

  .pt-plus-3,
.py-plus-3 {
    padding-top: 1rem !important;
  }

  .pr-plus-3,
.px-plus-3 {
    padding-right: 1rem !important;
  }

  .pb-plus-3,
.py-plus-3 {
    padding-bottom: 1rem !important;
  }

  .pl-plus-3,
.px-plus-3 {
    padding-left: 1rem !important;
  }

  .p-plus-4 {
    padding: 1.5rem !important;
  }

  .pt-plus-4,
.py-plus-4 {
    padding-top: 1.5rem !important;
  }

  .pr-plus-4,
.px-plus-4 {
    padding-right: 1.5rem !important;
  }

  .pb-plus-4,
.py-plus-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-plus-4,
.px-plus-4 {
    padding-left: 1.5rem !important;
  }

  .p-plus-5 {
    padding: 3rem !important;
  }

  .pt-plus-5,
.py-plus-5 {
    padding-top: 3rem !important;
  }

  .pr-plus-5,
.px-plus-5 {
    padding-right: 3rem !important;
  }

  .pb-plus-5,
.py-plus-5 {
    padding-bottom: 3rem !important;
  }

  .pl-plus-5,
.px-plus-5 {
    padding-left: 3rem !important;
  }

  .m-plus-n1 {
    margin: -0.25rem !important;
  }

  .mt-plus-n1,
.my-plus-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-plus-n1,
.mx-plus-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-plus-n1,
.my-plus-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-plus-n1,
.mx-plus-n1 {
    margin-left: -0.25rem !important;
  }

  .m-plus-n2 {
    margin: -0.5rem !important;
  }

  .mt-plus-n2,
.my-plus-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-plus-n2,
.mx-plus-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-plus-n2,
.my-plus-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-plus-n2,
.mx-plus-n2 {
    margin-left: -0.5rem !important;
  }

  .m-plus-n3 {
    margin: -1rem !important;
  }

  .mt-plus-n3,
.my-plus-n3 {
    margin-top: -1rem !important;
  }

  .mr-plus-n3,
.mx-plus-n3 {
    margin-right: -1rem !important;
  }

  .mb-plus-n3,
.my-plus-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-plus-n3,
.mx-plus-n3 {
    margin-left: -1rem !important;
  }

  .m-plus-n4 {
    margin: -1.5rem !important;
  }

  .mt-plus-n4,
.my-plus-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-plus-n4,
.mx-plus-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-plus-n4,
.my-plus-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-plus-n4,
.mx-plus-n4 {
    margin-left: -1.5rem !important;
  }

  .m-plus-n5 {
    margin: -3rem !important;
  }

  .mt-plus-n5,
.my-plus-n5 {
    margin-top: -3rem !important;
  }

  .mr-plus-n5,
.mx-plus-n5 {
    margin-right: -3rem !important;
  }

  .mb-plus-n5,
.my-plus-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-plus-n5,
.mx-plus-n5 {
    margin-left: -3rem !important;
  }

  .m-plus-auto {
    margin: auto !important;
  }

  .mt-plus-auto,
.my-plus-auto {
    margin-top: auto !important;
  }

  .mr-plus-auto,
.mx-plus-auto {
    margin-right: auto !important;
  }

  .mb-plus-auto,
.my-plus-auto {
    margin-bottom: auto !important;
  }

  .ml-plus-auto,
.mx-plus-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1500px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1750px) {
  .m-xxxl-0 {
    margin: 0 !important;
  }

  .mt-xxxl-0,
.my-xxxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxxl-0,
.mx-xxxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxxl-0,
.my-xxxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxxl-0,
.mx-xxxl-0 {
    margin-left: 0 !important;
  }

  .m-xxxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxxl-1,
.my-xxxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxxl-1,
.mx-xxxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxxl-1,
.my-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxxl-1,
.mx-xxxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxxl-2,
.my-xxxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxxl-2,
.mx-xxxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxxl-2,
.my-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxxl-2,
.mx-xxxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxxl-3 {
    margin: 1rem !important;
  }

  .mt-xxxl-3,
.my-xxxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxxl-3,
.mx-xxxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxxl-3,
.my-xxxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxxl-3,
.mx-xxxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxxl-4,
.my-xxxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxxl-4,
.mx-xxxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxxl-4,
.my-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxxl-4,
.mx-xxxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxxl-5 {
    margin: 3rem !important;
  }

  .mt-xxxl-5,
.my-xxxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxxl-5,
.mx-xxxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxxl-5,
.my-xxxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxxl-5,
.mx-xxxl-5 {
    margin-left: 3rem !important;
  }

  .p-xxxl-0 {
    padding: 0 !important;
  }

  .pt-xxxl-0,
.py-xxxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxxl-0,
.px-xxxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxxl-0,
.py-xxxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxxl-0,
.px-xxxl-0 {
    padding-left: 0 !important;
  }

  .p-xxxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxxl-1,
.py-xxxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxxl-1,
.px-xxxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxxl-1,
.py-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxxl-1,
.px-xxxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxxl-2,
.py-xxxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxxl-2,
.px-xxxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxxl-2,
.py-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxxl-2,
.px-xxxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxxl-3 {
    padding: 1rem !important;
  }

  .pt-xxxl-3,
.py-xxxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxxl-3,
.px-xxxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxxl-3,
.py-xxxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxxl-3,
.px-xxxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxxl-4,
.py-xxxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxxl-4,
.px-xxxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxxl-4,
.py-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxxl-4,
.px-xxxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxxl-5 {
    padding: 3rem !important;
  }

  .pt-xxxl-5,
.py-xxxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxxl-5,
.px-xxxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxxl-5,
.py-xxxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxxl-5,
.px-xxxl-5 {
    padding-left: 3rem !important;
  }

  .m-xxxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxxl-n1,
.my-xxxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxxl-n1,
.mx-xxxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxxl-n1,
.my-xxxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxxl-n1,
.mx-xxxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxxl-n2,
.my-xxxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxxl-n2,
.mx-xxxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxxl-n2,
.my-xxxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxxl-n2,
.mx-xxxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxxl-n3,
.my-xxxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxxl-n3,
.mx-xxxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxxl-n3,
.my-xxxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxxl-n3,
.mx-xxxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxxl-n4,
.my-xxxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxxl-n4,
.mx-xxxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxxl-n4,
.my-xxxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxxl-n4,
.mx-xxxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxxl-n5,
.my-xxxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxxl-n5,
.mx-xxxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxxl-n5,
.my-xxxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxxl-n5,
.mx-xxxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxxl-auto {
    margin: auto !important;
  }

  .mt-xxxl-auto,
.my-xxxl-auto {
    margin-top: auto !important;
  }

  .mr-xxxl-auto,
.mx-xxxl-auto {
    margin-right: auto !important;
  }

  .mb-xxxl-auto,
.my-xxxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxxl-auto,
.mx-xxxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 375px) {
  .text-ms-left {
    text-align: left !important;
  }

  .text-ms-right {
    text-align: right !important;
  }

  .text-ms-center {
    text-align: center !important;
  }
}
@media (min-width: 378px) {
  .text-plus-left {
    text-align: left !important;
  }

  .text-plus-right {
    text-align: right !important;
  }

  .text-plus-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1500px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1750px) {
  .text-xxxl-left {
    text-align: left !important;
  }

  .text-xxxl-right {
    text-align: right !important;
  }

  .text-xxxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #ffffff !important;
}

.text-primary {
  color: #0099d4 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #006288 !important;
}

.text-secondary {
  color: #767676 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #505050 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #c8c8c8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #a2a2a2 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #BA2B5D !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #7c1d3e !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #767676 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #505050 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #ffffff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@font-face {
  font-family: "bootstrap-icons";
  src: url("./fonts/bootstrap-icons.woff2?8bd4575acf83c7696dc7a14a966660a3") format("woff2"), url("./fonts/bootstrap-icons.woff?8bd4575acf83c7696dc7a14a966660a3") format("woff");
}
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-alarm-fill::before {
  content: "";
}

.bi-alarm::before {
  content: "";
}

.bi-align-bottom::before {
  content: "";
}

.bi-align-center::before {
  content: "";
}

.bi-align-end::before {
  content: "";
}

.bi-align-middle::before {
  content: "";
}

.bi-align-start::before {
  content: "";
}

.bi-align-top::before {
  content: "";
}

.bi-alt::before {
  content: "";
}

.bi-app-indicator::before {
  content: "";
}

.bi-app::before {
  content: "";
}

.bi-archive-fill::before {
  content: "";
}

.bi-archive::before {
  content: "";
}

.bi-arrow-90deg-down::before {
  content: "";
}

.bi-arrow-90deg-left::before {
  content: "";
}

.bi-arrow-90deg-right::before {
  content: "";
}

.bi-arrow-90deg-up::before {
  content: "";
}

.bi-arrow-bar-down::before {
  content: "";
}

.bi-arrow-bar-left::before {
  content: "";
}

.bi-arrow-bar-right::before {
  content: "";
}

.bi-arrow-bar-up::before {
  content: "";
}

.bi-arrow-clockwise::before {
  content: "";
}

.bi-arrow-counterclockwise::before {
  content: "";
}

.bi-arrow-down-circle-fill::before {
  content: "";
}

.bi-arrow-down-circle::before {
  content: "";
}

.bi-arrow-down-left-circle-fill::before {
  content: "";
}

.bi-arrow-down-left-circle::before {
  content: "";
}

.bi-arrow-down-left-square-fill::before {
  content: "";
}

.bi-arrow-down-left-square::before {
  content: "";
}

.bi-arrow-down-left::before {
  content: "";
}

.bi-arrow-down-right-circle-fill::before {
  content: "";
}

.bi-arrow-down-right-circle::before {
  content: "";
}

.bi-arrow-down-right-square-fill::before {
  content: "";
}

.bi-arrow-down-right-square::before {
  content: "";
}

.bi-arrow-down-right::before {
  content: "";
}

.bi-arrow-down-short::before {
  content: "";
}

.bi-arrow-down-square-fill::before {
  content: "";
}

.bi-arrow-down-square::before {
  content: "";
}

.bi-arrow-down-up::before {
  content: "";
}

.bi-arrow-down::before {
  content: "";
}

.bi-arrow-left-circle-fill::before {
  content: "";
}

.bi-arrow-left-circle::before {
  content: "";
}

.bi-arrow-left-right::before {
  content: "";
}

.bi-arrow-left-short::before {
  content: "";
}

.bi-arrow-left-square-fill::before {
  content: "";
}

.bi-arrow-left-square::before {
  content: "";
}

.bi-arrow-left::before {
  content: "";
}

.bi-arrow-repeat::before {
  content: "";
}

.bi-arrow-return-left::before {
  content: "";
}

.bi-arrow-return-right::before {
  content: "";
}

.bi-arrow-right-circle-fill::before {
  content: "";
}

.bi-arrow-right-circle::before {
  content: "";
}

.bi-arrow-right-short::before {
  content: "";
}

.bi-arrow-right-square-fill::before {
  content: "";
}

.bi-arrow-right-square::before {
  content: "";
}

.bi-arrow-right::before {
  content: "";
}

.bi-arrow-up-circle-fill::before {
  content: "";
}

.bi-arrow-up-circle::before {
  content: "";
}

.bi-arrow-up-left-circle-fill::before {
  content: "";
}

.bi-arrow-up-left-circle::before {
  content: "";
}

.bi-arrow-up-left-square-fill::before {
  content: "";
}

.bi-arrow-up-left-square::before {
  content: "";
}

.bi-arrow-up-left::before {
  content: "";
}

.bi-arrow-up-right-circle-fill::before {
  content: "";
}

.bi-arrow-up-right-circle::before {
  content: "";
}

.bi-arrow-up-right-square-fill::before {
  content: "";
}

.bi-arrow-up-right-square::before {
  content: "";
}

.bi-arrow-up-right::before {
  content: "";
}

.bi-arrow-up-short::before {
  content: "";
}

.bi-arrow-up-square-fill::before {
  content: "";
}

.bi-arrow-up-square::before {
  content: "";
}

.bi-arrow-up::before {
  content: "";
}

.bi-arrows-angle-contract::before {
  content: "";
}

.bi-arrows-angle-expand::before {
  content: "";
}

.bi-arrows-collapse::before {
  content: "";
}

.bi-arrows-expand::before {
  content: "";
}

.bi-arrows-fullscreen::before {
  content: "";
}

.bi-arrows-move::before {
  content: "";
}

.bi-aspect-ratio-fill::before {
  content: "";
}

.bi-aspect-ratio::before {
  content: "";
}

.bi-asterisk::before {
  content: "";
}

.bi-at::before {
  content: "";
}

.bi-award-fill::before {
  content: "";
}

.bi-award::before {
  content: "";
}

.bi-back::before {
  content: "";
}

.bi-backspace-fill::before {
  content: "";
}

.bi-backspace-reverse-fill::before {
  content: "";
}

.bi-backspace-reverse::before {
  content: "";
}

.bi-backspace::before {
  content: "";
}

.bi-badge-3d-fill::before {
  content: "";
}

.bi-badge-3d::before {
  content: "";
}

.bi-badge-4k-fill::before {
  content: "";
}

.bi-badge-4k::before {
  content: "";
}

.bi-badge-8k-fill::before {
  content: "";
}

.bi-badge-8k::before {
  content: "";
}

.bi-badge-ad-fill::before {
  content: "";
}

.bi-badge-ad::before {
  content: "";
}

.bi-badge-ar-fill::before {
  content: "";
}

.bi-badge-ar::before {
  content: "";
}

.bi-badge-cc-fill::before {
  content: "";
}

.bi-badge-cc::before {
  content: "";
}

.bi-badge-hd-fill::before {
  content: "";
}

.bi-badge-hd::before {
  content: "";
}

.bi-badge-tm-fill::before {
  content: "";
}

.bi-badge-tm::before {
  content: "";
}

.bi-badge-vo-fill::before {
  content: "";
}

.bi-badge-vo::before {
  content: "";
}

.bi-badge-vr-fill::before {
  content: "";
}

.bi-badge-vr::before {
  content: "";
}

.bi-badge-wc-fill::before {
  content: "";
}

.bi-badge-wc::before {
  content: "";
}

.bi-bag-check-fill::before {
  content: "";
}

.bi-bag-check::before {
  content: "";
}

.bi-bag-dash-fill::before {
  content: "";
}

.bi-bag-dash::before {
  content: "";
}

.bi-bag-fill::before {
  content: "";
}

.bi-bag-plus-fill::before {
  content: "";
}

.bi-bag-plus::before {
  content: "";
}

.bi-bag-x-fill::before {
  content: "";
}

.bi-bag-x::before {
  content: "";
}

.bi-bag::before {
  content: "";
}

.bi-bar-chart-fill::before {
  content: "";
}

.bi-bar-chart-line-fill::before {
  content: "";
}

.bi-bar-chart-line::before {
  content: "";
}

.bi-bar-chart-steps::before {
  content: "";
}

.bi-bar-chart::before {
  content: "";
}

.bi-basket-fill::before {
  content: "";
}

.bi-basket::before {
  content: "";
}

.bi-basket2-fill::before {
  content: "";
}

.bi-basket2::before {
  content: "";
}

.bi-basket3-fill::before {
  content: "";
}

.bi-basket3::before {
  content: "";
}

.bi-battery-charging::before {
  content: "";
}

.bi-battery-full::before {
  content: "";
}

.bi-battery-half::before {
  content: "";
}

.bi-battery::before {
  content: "";
}

.bi-bell-fill::before {
  content: "";
}

.bi-bell::before {
  content: "";
}

.bi-bezier::before {
  content: "";
}

.bi-bezier2::before {
  content: "";
}

.bi-bicycle::before {
  content: "";
}

.bi-binoculars-fill::before {
  content: "";
}

.bi-binoculars::before {
  content: "";
}

.bi-blockquote-left::before {
  content: "";
}

.bi-blockquote-right::before {
  content: "";
}

.bi-book-fill::before {
  content: "";
}

.bi-book-half::before {
  content: "";
}

.bi-book::before {
  content: "";
}

.bi-bookmark-check-fill::before {
  content: "";
}

.bi-bookmark-check::before {
  content: "";
}

.bi-bookmark-dash-fill::before {
  content: "";
}

.bi-bookmark-dash::before {
  content: "";
}

.bi-bookmark-fill::before {
  content: "";
}

.bi-bookmark-heart-fill::before {
  content: "";
}

.bi-bookmark-heart::before {
  content: "";
}

.bi-bookmark-plus-fill::before {
  content: "";
}

.bi-bookmark-plus::before {
  content: "";
}

.bi-bookmark-star-fill::before {
  content: "";
}

.bi-bookmark-star::before {
  content: "";
}

.bi-bookmark-x-fill::before {
  content: "";
}

.bi-bookmark-x::before {
  content: "";
}

.bi-bookmark::before {
  content: "";
}

.bi-bookmarks-fill::before {
  content: "";
}

.bi-bookmarks::before {
  content: "";
}

.bi-bookshelf::before {
  content: "";
}

.bi-bootstrap-fill::before {
  content: "";
}

.bi-bootstrap-reboot::before {
  content: "";
}

.bi-bootstrap::before {
  content: "";
}

.bi-border-all::before {
  content: "";
}

.bi-border-bottom::before {
  content: "";
}

.bi-border-center::before {
  content: "";
}

.bi-border-inner::before {
  content: "";
}

.bi-border-left::before {
  content: "";
}

.bi-border-middle::before {
  content: "";
}

.bi-border-outer::before {
  content: "";
}

.bi-border-right::before {
  content: "";
}

.bi-border-style::before {
  content: "";
}

.bi-border-top::before {
  content: "";
}

.bi-border-width::before {
  content: "";
}

.bi-border::before {
  content: "";
}

.bi-bounding-box-circles::before {
  content: "";
}

.bi-bounding-box::before {
  content: "";
}

.bi-box-arrow-down-left::before {
  content: "";
}

.bi-box-arrow-down-right::before {
  content: "";
}

.bi-box-arrow-down::before {
  content: "";
}

.bi-box-arrow-in-down-left::before {
  content: "";
}

.bi-box-arrow-in-down-right::before {
  content: "";
}

.bi-box-arrow-in-down::before {
  content: "";
}

.bi-box-arrow-in-left::before {
  content: "";
}

.bi-box-arrow-in-right::before {
  content: "";
}

.bi-box-arrow-in-up-left::before {
  content: "";
}

.bi-box-arrow-in-up-right::before {
  content: "";
}

.bi-box-arrow-in-up::before {
  content: "";
}

.bi-box-arrow-left::before {
  content: "";
}

.bi-box-arrow-right::before {
  content: "";
}

.bi-box-arrow-up-left::before {
  content: "";
}

.bi-box-arrow-up-right::before {
  content: "";
}

.bi-box-arrow-up::before {
  content: "";
}

.bi-box-seam::before {
  content: "";
}

.bi-box::before {
  content: "";
}

.bi-braces::before {
  content: "";
}

.bi-bricks::before {
  content: "";
}

.bi-briefcase-fill::before {
  content: "";
}

.bi-briefcase::before {
  content: "";
}

.bi-brightness-alt-high-fill::before {
  content: "";
}

.bi-brightness-alt-high::before {
  content: "";
}

.bi-brightness-alt-low-fill::before {
  content: "";
}

.bi-brightness-alt-low::before {
  content: "";
}

.bi-brightness-high-fill::before {
  content: "";
}

.bi-brightness-high::before {
  content: "";
}

.bi-brightness-low-fill::before {
  content: "";
}

.bi-brightness-low::before {
  content: "";
}

.bi-broadcast-pin::before {
  content: "";
}

.bi-broadcast::before {
  content: "";
}

.bi-brush-fill::before {
  content: "";
}

.bi-brush::before {
  content: "";
}

.bi-bucket-fill::before {
  content: "";
}

.bi-bucket::before {
  content: "";
}

.bi-bug-fill::before {
  content: "";
}

.bi-bug::before {
  content: "";
}

.bi-building::before {
  content: "";
}

.bi-bullseye::before {
  content: "";
}

.bi-calculator-fill::before {
  content: "";
}

.bi-calculator::before {
  content: "";
}

.bi-calendar-check-fill::before {
  content: "";
}

.bi-calendar-check::before {
  content: "";
}

.bi-calendar-date-fill::before {
  content: "";
}

.bi-calendar-date::before {
  content: "";
}

.bi-calendar-day-fill::before {
  content: "";
}

.bi-calendar-day::before {
  content: "";
}

.bi-calendar-event-fill::before {
  content: "";
}

.bi-calendar-event::before {
  content: "";
}

.bi-calendar-fill::before {
  content: "";
}

.bi-calendar-minus-fill::before {
  content: "";
}

.bi-calendar-minus::before {
  content: "";
}

.bi-calendar-month-fill::before {
  content: "";
}

.bi-calendar-month::before {
  content: "";
}

.bi-calendar-plus-fill::before {
  content: "";
}

.bi-calendar-plus::before {
  content: "";
}

.bi-calendar-range-fill::before {
  content: "";
}

.bi-calendar-range::before {
  content: "";
}

.bi-calendar-week-fill::before {
  content: "";
}

.bi-calendar-week::before {
  content: "";
}

.bi-calendar-x-fill::before {
  content: "";
}

.bi-calendar-x::before {
  content: "";
}

.bi-calendar::before {
  content: "";
}

.bi-calendar2-check-fill::before {
  content: "";
}

.bi-calendar2-check::before {
  content: "";
}

.bi-calendar2-date-fill::before {
  content: "";
}

.bi-calendar2-date::before {
  content: "";
}

.bi-calendar2-day-fill::before {
  content: "";
}

.bi-calendar2-day::before {
  content: "";
}

.bi-calendar2-event-fill::before {
  content: "";
}

.bi-calendar2-event::before {
  content: "";
}

.bi-calendar2-fill::before {
  content: "";
}

.bi-calendar2-minus-fill::before {
  content: "";
}

.bi-calendar2-minus::before {
  content: "";
}

.bi-calendar2-month-fill::before {
  content: "";
}

.bi-calendar2-month::before {
  content: "";
}

.bi-calendar2-plus-fill::before {
  content: "";
}

.bi-calendar2-plus::before {
  content: "";
}

.bi-calendar2-range-fill::before {
  content: "";
}

.bi-calendar2-range::before {
  content: "";
}

.bi-calendar2-week-fill::before {
  content: "";
}

.bi-calendar2-week::before {
  content: "";
}

.bi-calendar2-x-fill::before {
  content: "";
}

.bi-calendar2-x::before {
  content: "";
}

.bi-calendar2::before {
  content: "";
}

.bi-calendar3-event-fill::before {
  content: "";
}

.bi-calendar3-event::before {
  content: "";
}

.bi-calendar3-fill::before {
  content: "";
}

.bi-calendar3-range-fill::before {
  content: "";
}

.bi-calendar3-range::before {
  content: "";
}

.bi-calendar3-week-fill::before {
  content: "";
}

.bi-calendar3-week::before {
  content: "";
}

.bi-calendar3::before {
  content: "";
}

.bi-calendar4-event::before {
  content: "";
}

.bi-calendar4-range::before {
  content: "";
}

.bi-calendar4-week::before {
  content: "";
}

.bi-calendar4::before {
  content: "";
}

.bi-camera-fill::before {
  content: "";
}

.bi-camera-reels-fill::before {
  content: "";
}

.bi-camera-reels::before {
  content: "";
}

.bi-camera-video-fill::before {
  content: "";
}

.bi-camera-video-off-fill::before {
  content: "";
}

.bi-camera-video-off::before {
  content: "";
}

.bi-camera-video::before {
  content: "";
}

.bi-camera::before {
  content: "";
}

.bi-camera2::before {
  content: "";
}

.bi-capslock-fill::before {
  content: "";
}

.bi-capslock::before {
  content: "";
}

.bi-card-checklist::before {
  content: "";
}

.bi-card-heading::before {
  content: "";
}

.bi-card-image::before {
  content: "";
}

.bi-card-list::before {
  content: "";
}

.bi-card-text::before {
  content: "";
}

.bi-caret-down-fill::before {
  content: "";
}

.bi-caret-down-square-fill::before {
  content: "";
}

.bi-caret-down-square::before {
  content: "";
}

.bi-caret-down::before {
  content: "";
}

.bi-caret-left-fill::before {
  content: "";
}

.bi-caret-left-square-fill::before {
  content: "";
}

.bi-caret-left-square::before {
  content: "";
}

.bi-caret-left::before {
  content: "";
}

.bi-caret-right-fill::before {
  content: "";
}

.bi-caret-right-square-fill::before {
  content: "";
}

.bi-caret-right-square::before {
  content: "";
}

.bi-caret-right::before {
  content: "";
}

.bi-caret-up-fill::before {
  content: "";
}

.bi-caret-up-square-fill::before {
  content: "";
}

.bi-caret-up-square::before {
  content: "";
}

.bi-caret-up::before {
  content: "";
}

.bi-cart-check-fill::before {
  content: "";
}

.bi-cart-check::before {
  content: "";
}

.bi-cart-dash-fill::before {
  content: "";
}

.bi-cart-dash::before {
  content: "";
}

.bi-cart-fill::before {
  content: "";
}

.bi-cart-plus-fill::before {
  content: "";
}

.bi-cart-plus::before {
  content: "";
}

.bi-cart-x-fill::before {
  content: "";
}

.bi-cart-x::before {
  content: "";
}

.bi-cart::before {
  content: "";
}

.bi-cart2::before {
  content: "";
}

.bi-cart3::before {
  content: "";
}

.bi-cart4::before {
  content: "";
}

.bi-cash-stack::before {
  content: "";
}

.bi-cash::before {
  content: "";
}

.bi-cast::before {
  content: "";
}

.bi-chat-dots-fill::before {
  content: "";
}

.bi-chat-dots::before {
  content: "";
}

.bi-chat-fill::before {
  content: "";
}

.bi-chat-left-dots-fill::before {
  content: "";
}

.bi-chat-left-dots::before {
  content: "";
}

.bi-chat-left-fill::before {
  content: "";
}

.bi-chat-left-quote-fill::before {
  content: "";
}

.bi-chat-left-quote::before {
  content: "";
}

.bi-chat-left-text-fill::before {
  content: "";
}

.bi-chat-left-text::before {
  content: "";
}

.bi-chat-left::before {
  content: "";
}

.bi-chat-quote-fill::before {
  content: "";
}

.bi-chat-quote::before {
  content: "";
}

.bi-chat-right-dots-fill::before {
  content: "";
}

.bi-chat-right-dots::before {
  content: "";
}

.bi-chat-right-fill::before {
  content: "";
}

.bi-chat-right-quote-fill::before {
  content: "";
}

.bi-chat-right-quote::before {
  content: "";
}

.bi-chat-right-text-fill::before {
  content: "";
}

.bi-chat-right-text::before {
  content: "";
}

.bi-chat-right::before {
  content: "";
}

.bi-chat-square-dots-fill::before {
  content: "";
}

.bi-chat-square-dots::before {
  content: "";
}

.bi-chat-square-fill::before {
  content: "";
}

.bi-chat-square-quote-fill::before {
  content: "";
}

.bi-chat-square-quote::before {
  content: "";
}

.bi-chat-square-text-fill::before {
  content: "";
}

.bi-chat-square-text::before {
  content: "";
}

.bi-chat-square::before {
  content: "";
}

.bi-chat-text-fill::before {
  content: "";
}

.bi-chat-text::before {
  content: "";
}

.bi-chat::before {
  content: "";
}

.bi-check-all::before {
  content: "";
}

.bi-check-circle-fill::before {
  content: "";
}

.bi-check-circle::before {
  content: "";
}

.bi-check-square-fill::before {
  content: "";
}

.bi-check-square::before {
  content: "";
}

.bi-check::before {
  content: "";
}

.bi-check2-all::before {
  content: "";
}

.bi-check2-circle::before {
  content: "";
}

.bi-check2-square::before {
  content: "";
}

.bi-check2::before {
  content: "";
}

.bi-chevron-bar-contract::before {
  content: "";
}

.bi-chevron-bar-down::before {
  content: "";
}

.bi-chevron-bar-expand::before {
  content: "";
}

.bi-chevron-bar-left::before {
  content: "";
}

.bi-chevron-bar-right::before {
  content: "";
}

.bi-chevron-bar-up::before {
  content: "";
}

.bi-chevron-compact-down::before {
  content: "";
}

.bi-chevron-compact-left::before {
  content: "";
}

.bi-chevron-compact-right::before {
  content: "";
}

.bi-chevron-compact-up::before {
  content: "";
}

.bi-chevron-contract::before {
  content: "";
}

.bi-chevron-double-down::before {
  content: "";
}

.bi-chevron-double-left::before {
  content: "";
}

.bi-chevron-double-right::before {
  content: "";
}

.bi-chevron-double-up::before {
  content: "";
}

.bi-chevron-down::before {
  content: "";
}

.bi-chevron-expand::before {
  content: "";
}

.bi-chevron-left::before {
  content: "";
}

.bi-chevron-right::before {
  content: "";
}

.bi-chevron-up::before {
  content: "";
}

.bi-circle-fill::before {
  content: "";
}

.bi-circle-half::before {
  content: "";
}

.bi-circle-square::before {
  content: "";
}

.bi-circle::before {
  content: "";
}

.bi-clipboard-check::before {
  content: "";
}

.bi-clipboard-data::before {
  content: "";
}

.bi-clipboard-minus::before {
  content: "";
}

.bi-clipboard-plus::before {
  content: "";
}

.bi-clipboard-x::before {
  content: "";
}

.bi-clipboard::before {
  content: "";
}

.bi-clock-fill::before {
  content: "";
}

.bi-clock-history::before {
  content: "";
}

.bi-clock::before {
  content: "";
}

.bi-cloud-arrow-down-fill::before {
  content: "";
}

.bi-cloud-arrow-down::before {
  content: "";
}

.bi-cloud-arrow-up-fill::before {
  content: "";
}

.bi-cloud-arrow-up::before {
  content: "";
}

.bi-cloud-check-fill::before {
  content: "";
}

.bi-cloud-check::before {
  content: "";
}

.bi-cloud-download-fill::before {
  content: "";
}

.bi-cloud-download::before {
  content: "";
}

.bi-cloud-drizzle-fill::before {
  content: "";
}

.bi-cloud-drizzle::before {
  content: "";
}

.bi-cloud-fill::before {
  content: "";
}

.bi-cloud-fog-fill::before {
  content: "";
}

.bi-cloud-fog::before {
  content: "";
}

.bi-cloud-fog2-fill::before {
  content: "";
}

.bi-cloud-fog2::before {
  content: "";
}

.bi-cloud-hail-fill::before {
  content: "";
}

.bi-cloud-hail::before {
  content: "";
}

.bi-cloud-haze-1::before {
  content: "";
}

.bi-cloud-haze-fill::before {
  content: "";
}

.bi-cloud-haze::before {
  content: "";
}

.bi-cloud-haze2-fill::before {
  content: "";
}

.bi-cloud-lightning-fill::before {
  content: "";
}

.bi-cloud-lightning-rain-fill::before {
  content: "";
}

.bi-cloud-lightning-rain::before {
  content: "";
}

.bi-cloud-lightning::before {
  content: "";
}

.bi-cloud-minus-fill::before {
  content: "";
}

.bi-cloud-minus::before {
  content: "";
}

.bi-cloud-moon-fill::before {
  content: "";
}

.bi-cloud-moon::before {
  content: "";
}

.bi-cloud-plus-fill::before {
  content: "";
}

.bi-cloud-plus::before {
  content: "";
}

.bi-cloud-rain-fill::before {
  content: "";
}

.bi-cloud-rain-heavy-fill::before {
  content: "";
}

.bi-cloud-rain-heavy::before {
  content: "";
}

.bi-cloud-rain::before {
  content: "";
}

.bi-cloud-slash-fill::before {
  content: "";
}

.bi-cloud-slash::before {
  content: "";
}

.bi-cloud-sleet-fill::before {
  content: "";
}

.bi-cloud-sleet::before {
  content: "";
}

.bi-cloud-snow-fill::before {
  content: "";
}

.bi-cloud-snow::before {
  content: "";
}

.bi-cloud-sun-fill::before {
  content: "";
}

.bi-cloud-sun::before {
  content: "";
}

.bi-cloud-upload-fill::before {
  content: "";
}

.bi-cloud-upload::before {
  content: "";
}

.bi-cloud::before {
  content: "";
}

.bi-clouds-fill::before {
  content: "";
}

.bi-clouds::before {
  content: "";
}

.bi-cloudy-fill::before {
  content: "";
}

.bi-cloudy::before {
  content: "";
}

.bi-code-slash::before {
  content: "";
}

.bi-code-square::before {
  content: "";
}

.bi-code::before {
  content: "";
}

.bi-collection-fill::before {
  content: "";
}

.bi-collection-play-fill::before {
  content: "";
}

.bi-collection-play::before {
  content: "";
}

.bi-collection::before {
  content: "";
}

.bi-columns-gap::before {
  content: "";
}

.bi-columns::before {
  content: "";
}

.bi-command::before {
  content: "";
}

.bi-compass-fill::before {
  content: "";
}

.bi-compass::before {
  content: "";
}

.bi-cone-striped::before {
  content: "";
}

.bi-cone::before {
  content: "";
}

.bi-controller::before {
  content: "";
}

.bi-cpu-fill::before {
  content: "";
}

.bi-cpu::before {
  content: "";
}

.bi-credit-card-2-back-fill::before {
  content: "";
}

.bi-credit-card-2-back::before {
  content: "";
}

.bi-credit-card-2-front-fill::before {
  content: "";
}

.bi-credit-card-2-front::before {
  content: "";
}

.bi-credit-card-fill::before {
  content: "";
}

.bi-credit-card::before {
  content: "";
}

.bi-crop::before {
  content: "";
}

.bi-cup-fill::before {
  content: "";
}

.bi-cup-straw::before {
  content: "";
}

.bi-cup::before {
  content: "";
}

.bi-cursor-fill::before {
  content: "";
}

.bi-cursor-text::before {
  content: "";
}

.bi-cursor::before {
  content: "";
}

.bi-dash-circle-dotted::before {
  content: "";
}

.bi-dash-circle-fill::before {
  content: "";
}

.bi-dash-circle::before {
  content: "";
}

.bi-dash-square-dotted::before {
  content: "";
}

.bi-dash-square-fill::before {
  content: "";
}

.bi-dash-square::before {
  content: "";
}

.bi-dash::before {
  content: "";
}

.bi-diagram-2-fill::before {
  content: "";
}

.bi-diagram-2::before {
  content: "";
}

.bi-diagram-3-fill::before {
  content: "";
}

.bi-diagram-3::before {
  content: "";
}

.bi-diamond-fill::before {
  content: "";
}

.bi-diamond-half::before {
  content: "";
}

.bi-diamond::before {
  content: "";
}

.bi-dice-1-fill::before {
  content: "";
}

.bi-dice-1::before {
  content: "";
}

.bi-dice-2-fill::before {
  content: "";
}

.bi-dice-2::before {
  content: "";
}

.bi-dice-3-fill::before {
  content: "";
}

.bi-dice-3::before {
  content: "";
}

.bi-dice-4-fill::before {
  content: "";
}

.bi-dice-4::before {
  content: "";
}

.bi-dice-5-fill::before {
  content: "";
}

.bi-dice-5::before {
  content: "";
}

.bi-dice-6-fill::before {
  content: "";
}

.bi-dice-6::before {
  content: "";
}

.bi-disc-fill::before {
  content: "";
}

.bi-disc::before {
  content: "";
}

.bi-discord::before {
  content: "";
}

.bi-display-fill::before {
  content: "";
}

.bi-display::before {
  content: "";
}

.bi-distribute-horizontal::before {
  content: "";
}

.bi-distribute-vertical::before {
  content: "";
}

.bi-door-closed-fill::before {
  content: "";
}

.bi-door-closed::before {
  content: "";
}

.bi-door-open-fill::before {
  content: "";
}

.bi-door-open::before {
  content: "";
}

.bi-dot::before {
  content: "";
}

.bi-download::before {
  content: "";
}

.bi-droplet-fill::before {
  content: "";
}

.bi-droplet-half::before {
  content: "";
}

.bi-droplet::before {
  content: "";
}

.bi-earbuds::before {
  content: "";
}

.bi-easel-fill::before {
  content: "";
}

.bi-easel::before {
  content: "";
}

.bi-egg-fill::before {
  content: "";
}

.bi-egg-fried::before {
  content: "";
}

.bi-egg::before {
  content: "";
}

.bi-eject-fill::before {
  content: "";
}

.bi-eject::before {
  content: "";
}

.bi-emoji-angry-fill::before {
  content: "";
}

.bi-emoji-angry::before {
  content: "";
}

.bi-emoji-dizzy-fill::before {
  content: "";
}

.bi-emoji-dizzy::before {
  content: "";
}

.bi-emoji-expressionless-fill::before {
  content: "";
}

.bi-emoji-expressionless::before {
  content: "";
}

.bi-emoji-frown-fill::before {
  content: "";
}

.bi-emoji-frown::before {
  content: "";
}

.bi-emoji-heart-eyes-fill::before {
  content: "";
}

.bi-emoji-heart-eyes::before {
  content: "";
}

.bi-emoji-laughing-fill::before {
  content: "";
}

.bi-emoji-laughing::before {
  content: "";
}

.bi-emoji-neutral-fill::before {
  content: "";
}

.bi-emoji-neutral::before {
  content: "";
}

.bi-emoji-smile-fill::before {
  content: "";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "";
}

.bi-emoji-smile-upside-down::before {
  content: "";
}

.bi-emoji-smile::before {
  content: "";
}

.bi-emoji-sunglasses-fill::before {
  content: "";
}

.bi-emoji-sunglasses::before {
  content: "";
}

.bi-emoji-wink-fill::before {
  content: "";
}

.bi-emoji-wink::before {
  content: "";
}

.bi-envelope-fill::before {
  content: "";
}

.bi-envelope-open-fill::before {
  content: "";
}

.bi-envelope-open::before {
  content: "";
}

.bi-envelope::before {
  content: "";
}

.bi-eraser-fill::before {
  content: "";
}

.bi-eraser::before {
  content: "";
}

.bi-exclamation-circle-fill::before {
  content: "";
}

.bi-exclamation-circle::before {
  content: "";
}

.bi-exclamation-diamond-fill::before {
  content: "";
}

.bi-exclamation-diamond::before {
  content: "";
}

.bi-exclamation-octagon-fill::before {
  content: "";
}

.bi-exclamation-octagon::before {
  content: "";
}

.bi-exclamation-square-fill::before {
  content: "";
}

.bi-exclamation-square::before {
  content: "";
}

.bi-exclamation-triangle-fill::before {
  content: "";
}

.bi-exclamation-triangle::before {
  content: "";
}

.bi-exclamation::before {
  content: "";
}

.bi-exclude::before {
  content: "";
}

.bi-eye-fill::before {
  content: "";
}

.bi-eye-slash-fill::before {
  content: "";
}

.bi-eye-slash::before {
  content: "";
}

.bi-eye::before {
  content: "";
}

.bi-eyedropper::before {
  content: "";
}

.bi-eyeglasses::before {
  content: "";
}

.bi-facebook::before {
  content: "";
}

.bi-file-arrow-down-fill::before {
  content: "";
}

.bi-file-arrow-down::before {
  content: "";
}

.bi-file-arrow-up-fill::before {
  content: "";
}

.bi-file-arrow-up::before {
  content: "";
}

.bi-file-bar-graph-fill::before {
  content: "";
}

.bi-file-bar-graph::before {
  content: "";
}

.bi-file-binary-fill::before {
  content: "";
}

.bi-file-binary::before {
  content: "";
}

.bi-file-break-fill::before {
  content: "";
}

.bi-file-break::before {
  content: "";
}

.bi-file-check-fill::before {
  content: "";
}

.bi-file-check::before {
  content: "";
}

.bi-file-code-fill::before {
  content: "";
}

.bi-file-code::before {
  content: "";
}

.bi-file-diff-fill::before {
  content: "";
}

.bi-file-diff::before {
  content: "";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "";
}

.bi-file-earmark-arrow-down::before {
  content: "";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "";
}

.bi-file-earmark-arrow-up::before {
  content: "";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "";
}

.bi-file-earmark-bar-graph::before {
  content: "";
}

.bi-file-earmark-binary-fill::before {
  content: "";
}

.bi-file-earmark-binary::before {
  content: "";
}

.bi-file-earmark-break-fill::before {
  content: "";
}

.bi-file-earmark-break::before {
  content: "";
}

.bi-file-earmark-check-fill::before {
  content: "";
}

.bi-file-earmark-check::before {
  content: "";
}

.bi-file-earmark-code-fill::before {
  content: "";
}

.bi-file-earmark-code::before {
  content: "";
}

.bi-file-earmark-diff-fill::before {
  content: "";
}

.bi-file-earmark-diff::before {
  content: "";
}

.bi-file-earmark-easel-fill::before {
  content: "";
}

.bi-file-earmark-easel::before {
  content: "";
}

.bi-file-earmark-excel-fill::before {
  content: "";
}

.bi-file-earmark-excel::before {
  content: "";
}

.bi-file-earmark-fill::before {
  content: "";
}

.bi-file-earmark-font-fill::before {
  content: "";
}

.bi-file-earmark-font::before {
  content: "";
}

.bi-file-earmark-image-fill::before {
  content: "";
}

.bi-file-earmark-image::before {
  content: "";
}

.bi-file-earmark-lock-fill::before {
  content: "";
}

.bi-file-earmark-lock::before {
  content: "";
}

.bi-file-earmark-lock2-fill::before {
  content: "";
}

.bi-file-earmark-lock2::before {
  content: "";
}

.bi-file-earmark-medical-fill::before {
  content: "";
}

.bi-file-earmark-medical::before {
  content: "";
}

.bi-file-earmark-minus-fill::before {
  content: "";
}

.bi-file-earmark-minus::before {
  content: "";
}

.bi-file-earmark-music-fill::before {
  content: "";
}

.bi-file-earmark-music::before {
  content: "";
}

.bi-file-earmark-person-fill::before {
  content: "";
}

.bi-file-earmark-person::before {
  content: "";
}

.bi-file-earmark-play-fill::before {
  content: "";
}

.bi-file-earmark-play::before {
  content: "";
}

.bi-file-earmark-plus-fill::before {
  content: "";
}

.bi-file-earmark-plus::before {
  content: "";
}

.bi-file-earmark-post-fill::before {
  content: "";
}

.bi-file-earmark-post::before {
  content: "";
}

.bi-file-earmark-ppt-fill::before {
  content: "";
}

.bi-file-earmark-ppt::before {
  content: "";
}

.bi-file-earmark-richtext-fill::before {
  content: "";
}

.bi-file-earmark-richtext::before {
  content: "";
}

.bi-file-earmark-ruled-fill::before {
  content: "";
}

.bi-file-earmark-ruled::before {
  content: "";
}

.bi-file-earmark-slides-fill::before {
  content: "";
}

.bi-file-earmark-slides::before {
  content: "";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "";
}

.bi-file-earmark-spreadsheet::before {
  content: "";
}

.bi-file-earmark-text-fill::before {
  content: "";
}

.bi-file-earmark-text::before {
  content: "";
}

.bi-file-earmark-word-fill::before {
  content: "";
}

.bi-file-earmark-word::before {
  content: "";
}

.bi-file-earmark-x-fill::before {
  content: "";
}

.bi-file-earmark-x::before {
  content: "";
}

.bi-file-earmark-zip-fill::before {
  content: "";
}

.bi-file-earmark-zip::before {
  content: "";
}

.bi-file-earmark::before {
  content: "";
}

.bi-file-easel-fill::before {
  content: "";
}

.bi-file-easel::before {
  content: "";
}

.bi-file-excel-fill::before {
  content: "";
}

.bi-file-excel::before {
  content: "";
}

.bi-file-fill::before {
  content: "";
}

.bi-file-font-fill::before {
  content: "";
}

.bi-file-font::before {
  content: "";
}

.bi-file-image-fill::before {
  content: "";
}

.bi-file-image::before {
  content: "";
}

.bi-file-lock-fill::before {
  content: "";
}

.bi-file-lock::before {
  content: "";
}

.bi-file-lock2-fill::before {
  content: "";
}

.bi-file-lock2::before {
  content: "";
}

.bi-file-medical-fill::before {
  content: "";
}

.bi-file-medical::before {
  content: "";
}

.bi-file-minus-fill::before {
  content: "";
}

.bi-file-minus::before {
  content: "";
}

.bi-file-music-fill::before {
  content: "";
}

.bi-file-music::before {
  content: "";
}

.bi-file-person-fill::before {
  content: "";
}

.bi-file-person::before {
  content: "";
}

.bi-file-play-fill::before {
  content: "";
}

.bi-file-play::before {
  content: "";
}

.bi-file-plus-fill::before {
  content: "";
}

.bi-file-plus::before {
  content: "";
}

.bi-file-post-fill::before {
  content: "";
}

.bi-file-post::before {
  content: "";
}

.bi-file-ppt-fill::before {
  content: "";
}

.bi-file-ppt::before {
  content: "";
}

.bi-file-richtext-fill::before {
  content: "";
}

.bi-file-richtext::before {
  content: "";
}

.bi-file-ruled-fill::before {
  content: "";
}

.bi-file-ruled::before {
  content: "";
}

.bi-file-slides-fill::before {
  content: "";
}

.bi-file-slides::before {
  content: "";
}

.bi-file-spreadsheet-fill::before {
  content: "";
}

.bi-file-spreadsheet::before {
  content: "";
}

.bi-file-text-fill::before {
  content: "";
}

.bi-file-text::before {
  content: "";
}

.bi-file-word-fill::before {
  content: "";
}

.bi-file-word::before {
  content: "";
}

.bi-file-x-fill::before {
  content: "";
}

.bi-file-x::before {
  content: "";
}

.bi-file-zip-fill::before {
  content: "";
}

.bi-file-zip::before {
  content: "";
}

.bi-file::before {
  content: "";
}

.bi-files-alt::before {
  content: "";
}

.bi-files::before {
  content: "";
}

.bi-film::before {
  content: "";
}

.bi-filter-circle-fill::before {
  content: "";
}

.bi-filter-circle::before {
  content: "";
}

.bi-filter-left::before {
  content: "";
}

.bi-filter-right::before {
  content: "";
}

.bi-filter-square-fill::before {
  content: "";
}

.bi-filter-square::before {
  content: "";
}

.bi-filter::before {
  content: "";
}

.bi-flag-fill::before {
  content: "";
}

.bi-flag::before {
  content: "";
}

.bi-flower1::before {
  content: "";
}

.bi-flower2::before {
  content: "";
}

.bi-flower3::before {
  content: "";
}

.bi-folder-check::before {
  content: "";
}

.bi-folder-fill::before {
  content: "";
}

.bi-folder-minus::before {
  content: "";
}

.bi-folder-plus::before {
  content: "";
}

.bi-folder-symlink-fill::before {
  content: "";
}

.bi-folder-symlink::before {
  content: "";
}

.bi-folder-x::before {
  content: "";
}

.bi-folder::before {
  content: "";
}

.bi-folder2-open::before {
  content: "";
}

.bi-folder2::before {
  content: "";
}

.bi-fonts::before {
  content: "";
}

.bi-forward-fill::before {
  content: "";
}

.bi-forward::before {
  content: "";
}

.bi-front::before {
  content: "";
}

.bi-fullscreen-exit::before {
  content: "";
}

.bi-fullscreen::before {
  content: "";
}

.bi-funnel-fill::before {
  content: "";
}

.bi-funnel::before {
  content: "";
}

.bi-gear-fill::before {
  content: "";
}

.bi-gear-wide-connected::before {
  content: "";
}

.bi-gear-wide::before {
  content: "";
}

.bi-gear::before {
  content: "";
}

.bi-gem::before {
  content: "";
}

.bi-geo-alt-fill::before {
  content: "";
}

.bi-geo-alt::before {
  content: "";
}

.bi-geo-fill::before {
  content: "";
}

.bi-geo::before {
  content: "";
}

.bi-gift-fill::before {
  content: "";
}

.bi-gift::before {
  content: "";
}

.bi-github::before {
  content: "";
}

.bi-globe::before {
  content: "";
}

.bi-globe2::before {
  content: "";
}

.bi-google::before {
  content: "";
}

.bi-graph-down::before {
  content: "";
}

.bi-graph-up::before {
  content: "";
}

.bi-grid-1x2-fill::before {
  content: "";
}

.bi-grid-1x2::before {
  content: "";
}

.bi-grid-3x2-gap-fill::before {
  content: "";
}

.bi-grid-3x2-gap::before {
  content: "";
}

.bi-grid-3x2::before {
  content: "";
}

.bi-grid-3x3-gap-fill::before {
  content: "";
}

.bi-grid-3x3-gap::before {
  content: "";
}

.bi-grid-3x3::before {
  content: "";
}

.bi-grid-fill::before {
  content: "";
}

.bi-grid::before {
  content: "";
}

.bi-grip-horizontal::before {
  content: "";
}

.bi-grip-vertical::before {
  content: "";
}

.bi-hammer::before {
  content: "";
}

.bi-hand-index-fill::before {
  content: "";
}

.bi-hand-index-thumb-fill::before {
  content: "";
}

.bi-hand-index-thumb::before {
  content: "";
}

.bi-hand-index::before {
  content: "";
}

.bi-hand-thumbs-down-fill::before {
  content: "";
}

.bi-hand-thumbs-down::before {
  content: "";
}

.bi-hand-thumbs-up-fill::before {
  content: "";
}

.bi-hand-thumbs-up::before {
  content: "";
}

.bi-handbag-fill::before {
  content: "";
}

.bi-handbag::before {
  content: "";
}

.bi-hash::before {
  content: "";
}

.bi-hdd-fill::before {
  content: "";
}

.bi-hdd-network-fill::before {
  content: "";
}

.bi-hdd-network::before {
  content: "";
}

.bi-hdd-rack-fill::before {
  content: "";
}

.bi-hdd-rack::before {
  content: "";
}

.bi-hdd-stack-fill::before {
  content: "";
}

.bi-hdd-stack::before {
  content: "";
}

.bi-hdd::before {
  content: "";
}

.bi-headphones::before {
  content: "";
}

.bi-headset::before {
  content: "";
}

.bi-heart-fill::before {
  content: "";
}

.bi-heart-half::before {
  content: "";
}

.bi-heart::before {
  content: "";
}

.bi-heptagon-fill::before {
  content: "";
}

.bi-heptagon-half::before {
  content: "";
}

.bi-heptagon::before {
  content: "";
}

.bi-hexagon-fill::before {
  content: "";
}

.bi-hexagon-half::before {
  content: "";
}

.bi-hexagon::before {
  content: "";
}

.bi-hourglass-bottom::before {
  content: "";
}

.bi-hourglass-split::before {
  content: "";
}

.bi-hourglass-top::before {
  content: "";
}

.bi-hourglass::before {
  content: "";
}

.bi-house-door-fill::before {
  content: "";
}

.bi-house-door::before {
  content: "";
}

.bi-house-fill::before {
  content: "";
}

.bi-house::before {
  content: "";
}

.bi-hr::before {
  content: "";
}

.bi-hurricane::before {
  content: "";
}

.bi-image-alt::before {
  content: "";
}

.bi-image-fill::before {
  content: "";
}

.bi-image::before {
  content: "";
}

.bi-images::before {
  content: "";
}

.bi-inbox-fill::before {
  content: "";
}

.bi-inbox::before {
  content: "";
}

.bi-inboxes-fill::before {
  content: "";
}

.bi-inboxes::before {
  content: "";
}

.bi-info-circle-fill::before {
  content: "";
}

.bi-info-circle::before {
  content: "";
}

.bi-info-square-fill::before {
  content: "";
}

.bi-info-square::before {
  content: "";
}

.bi-info::before {
  content: "";
}

.bi-input-cursor-text::before {
  content: "";
}

.bi-input-cursor::before {
  content: "";
}

.bi-instagram::before {
  content: "";
}

.bi-intersect::before {
  content: "";
}

.bi-journal-album::before {
  content: "";
}

.bi-journal-arrow-down::before {
  content: "";
}

.bi-journal-arrow-up::before {
  content: "";
}

.bi-journal-bookmark-fill::before {
  content: "";
}

.bi-journal-bookmark::before {
  content: "";
}

.bi-journal-check::before {
  content: "";
}

.bi-journal-code::before {
  content: "";
}

.bi-journal-medical::before {
  content: "";
}

.bi-journal-minus::before {
  content: "";
}

.bi-journal-plus::before {
  content: "";
}

.bi-journal-richtext::before {
  content: "";
}

.bi-journal-text::before {
  content: "";
}

.bi-journal-x::before {
  content: "";
}

.bi-journal::before {
  content: "";
}

.bi-journals::before {
  content: "";
}

.bi-joystick::before {
  content: "";
}

.bi-justify-left::before {
  content: "";
}

.bi-justify-right::before {
  content: "";
}

.bi-justify::before {
  content: "";
}

.bi-kanban-fill::before {
  content: "";
}

.bi-kanban::before {
  content: "";
}

.bi-key-fill::before {
  content: "";
}

.bi-key::before {
  content: "";
}

.bi-keyboard-fill::before {
  content: "";
}

.bi-keyboard::before {
  content: "";
}

.bi-ladder::before {
  content: "";
}

.bi-lamp-fill::before {
  content: "";
}

.bi-lamp::before {
  content: "";
}

.bi-laptop-fill::before {
  content: "";
}

.bi-laptop::before {
  content: "";
}

.bi-layer-backward::before {
  content: "";
}

.bi-layer-forward::before {
  content: "";
}

.bi-layers-fill::before {
  content: "";
}

.bi-layers-half::before {
  content: "";
}

.bi-layers::before {
  content: "";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "";
}

.bi-layout-sidebar-inset::before {
  content: "";
}

.bi-layout-sidebar-reverse::before {
  content: "";
}

.bi-layout-sidebar::before {
  content: "";
}

.bi-layout-split::before {
  content: "";
}

.bi-layout-text-sidebar-reverse::before {
  content: "";
}

.bi-layout-text-sidebar::before {
  content: "";
}

.bi-layout-text-window-reverse::before {
  content: "";
}

.bi-layout-text-window::before {
  content: "";
}

.bi-layout-three-columns::before {
  content: "";
}

.bi-layout-wtf::before {
  content: "";
}

.bi-life-preserver::before {
  content: "";
}

.bi-lightbulb-fill::before {
  content: "";
}

.bi-lightbulb-off-fill::before {
  content: "";
}

.bi-lightbulb-off::before {
  content: "";
}

.bi-lightbulb::before {
  content: "";
}

.bi-lightning-charge-fill::before {
  content: "";
}

.bi-lightning-charge::before {
  content: "";
}

.bi-lightning-fill::before {
  content: "";
}

.bi-lightning::before {
  content: "";
}

.bi-link-45deg::before {
  content: "";
}

.bi-link::before {
  content: "";
}

.bi-linkedin::before {
  content: "";
}

.bi-list-check::before {
  content: "";
}

.bi-list-nested::before {
  content: "";
}

.bi-list-ol::before {
  content: "";
}

.bi-list-stars::before {
  content: "";
}

.bi-list-task::before {
  content: "";
}

.bi-list-ul::before {
  content: "";
}

.bi-list::before {
  content: "";
}

.bi-lock-fill::before {
  content: "";
}

.bi-lock::before {
  content: "";
}

.bi-mailbox::before {
  content: "";
}

.bi-mailbox2::before {
  content: "";
}

.bi-map-fill::before {
  content: "";
}

.bi-map::before {
  content: "";
}

.bi-markdown-fill::before {
  content: "";
}

.bi-markdown::before {
  content: "";
}

.bi-mask::before {
  content: "";
}

.bi-megaphone-fill::before {
  content: "";
}

.bi-megaphone::before {
  content: "";
}

.bi-menu-app-fill::before {
  content: "";
}

.bi-menu-app::before {
  content: "";
}

.bi-menu-button-fill::before {
  content: "";
}

.bi-menu-button-wide-fill::before {
  content: "";
}

.bi-menu-button-wide::before {
  content: "";
}

.bi-menu-button::before {
  content: "";
}

.bi-menu-down::before {
  content: "";
}

.bi-menu-up::before {
  content: "";
}

.bi-mic-fill::before {
  content: "";
}

.bi-mic-mute-fill::before {
  content: "";
}

.bi-mic-mute::before {
  content: "";
}

.bi-mic::before {
  content: "";
}

.bi-minecart-loaded::before {
  content: "";
}

.bi-minecart::before {
  content: "";
}

.bi-moisture::before {
  content: "";
}

.bi-moon-fill::before {
  content: "";
}

.bi-moon-stars-fill::before {
  content: "";
}

.bi-moon-stars::before {
  content: "";
}

.bi-moon::before {
  content: "";
}

.bi-mouse-fill::before {
  content: "";
}

.bi-mouse::before {
  content: "";
}

.bi-mouse2-fill::before {
  content: "";
}

.bi-mouse2::before {
  content: "";
}

.bi-mouse3-fill::before {
  content: "";
}

.bi-mouse3::before {
  content: "";
}

.bi-music-note-beamed::before {
  content: "";
}

.bi-music-note-list::before {
  content: "";
}

.bi-music-note::before {
  content: "";
}

.bi-music-player-fill::before {
  content: "";
}

.bi-music-player::before {
  content: "";
}

.bi-newspaper::before {
  content: "";
}

.bi-node-minus-fill::before {
  content: "";
}

.bi-node-minus::before {
  content: "";
}

.bi-node-plus-fill::before {
  content: "";
}

.bi-node-plus::before {
  content: "";
}

.bi-nut-fill::before {
  content: "";
}

.bi-nut::before {
  content: "";
}

.bi-octagon-fill::before {
  content: "";
}

.bi-octagon-half::before {
  content: "";
}

.bi-octagon::before {
  content: "";
}

.bi-option::before {
  content: "";
}

.bi-outlet::before {
  content: "";
}

.bi-paint-bucket::before {
  content: "";
}

.bi-palette-fill::before {
  content: "";
}

.bi-palette::before {
  content: "";
}

.bi-palette2::before {
  content: "";
}

.bi-paperclip::before {
  content: "";
}

.bi-paragraph::before {
  content: "";
}

.bi-patch-check-fill::before {
  content: "";
}

.bi-patch-check::before {
  content: "";
}

.bi-patch-exclamation-fill::before {
  content: "";
}

.bi-patch-exclamation::before {
  content: "";
}

.bi-patch-minus-fill::before {
  content: "";
}

.bi-patch-minus::before {
  content: "";
}

.bi-patch-plus-fill::before {
  content: "";
}

.bi-patch-plus::before {
  content: "";
}

.bi-patch-question-fill::before {
  content: "";
}

.bi-patch-question::before {
  content: "";
}

.bi-pause-btn-fill::before {
  content: "";
}

.bi-pause-btn::before {
  content: "";
}

.bi-pause-circle-fill::before {
  content: "";
}

.bi-pause-circle::before {
  content: "";
}

.bi-pause-fill::before {
  content: "";
}

.bi-pause::before {
  content: "";
}

.bi-peace-fill::before {
  content: "";
}

.bi-peace::before {
  content: "";
}

.bi-pen-fill::before {
  content: "";
}

.bi-pen::before {
  content: "";
}

.bi-pencil-fill::before {
  content: "";
}

.bi-pencil-square::before {
  content: "";
}

.bi-pencil::before {
  content: "";
}

.bi-pentagon-fill::before {
  content: "";
}

.bi-pentagon-half::before {
  content: "";
}

.bi-pentagon::before {
  content: "";
}

.bi-people-fill::before {
  content: "";
}

.bi-people::before {
  content: "";
}

.bi-percent::before {
  content: "";
}

.bi-person-badge-fill::before {
  content: "";
}

.bi-person-badge::before {
  content: "";
}

.bi-person-bounding-box::before {
  content: "";
}

.bi-person-check-fill::before {
  content: "";
}

.bi-person-check::before {
  content: "";
}

.bi-person-circle::before {
  content: "";
}

.bi-person-dash-fill::before {
  content: "";
}

.bi-person-dash::before {
  content: "";
}

.bi-person-fill::before {
  content: "";
}

.bi-person-lines-fill::before {
  content: "";
}

.bi-person-plus-fill::before {
  content: "";
}

.bi-person-plus::before {
  content: "";
}

.bi-person-square::before {
  content: "";
}

.bi-person-x-fill::before {
  content: "";
}

.bi-person-x::before {
  content: "";
}

.bi-person::before {
  content: "";
}

.bi-phone-fill::before {
  content: "";
}

.bi-phone-landscape-fill::before {
  content: "";
}

.bi-phone-landscape::before {
  content: "";
}

.bi-phone-vibrate-fill::before {
  content: "";
}

.bi-phone-vibrate::before {
  content: "";
}

.bi-phone::before {
  content: "";
}

.bi-pie-chart-fill::before {
  content: "";
}

.bi-pie-chart::before {
  content: "";
}

.bi-pin-angle-fill::before {
  content: "";
}

.bi-pin-angle::before {
  content: "";
}

.bi-pin-fill::before {
  content: "";
}

.bi-pin::before {
  content: "";
}

.bi-pip-fill::before {
  content: "";
}

.bi-pip::before {
  content: "";
}

.bi-play-btn-fill::before {
  content: "";
}

.bi-play-btn::before {
  content: "";
}

.bi-play-circle-fill::before {
  content: "";
}

.bi-play-circle::before {
  content: "";
}

.bi-play-fill::before {
  content: "";
}

.bi-play::before {
  content: "";
}

.bi-plug-fill::before {
  content: "";
}

.bi-plug::before {
  content: "";
}

.bi-plus-circle-dotted::before {
  content: "";
}

.bi-plus-circle-fill::before {
  content: "";
}

.bi-plus-circle::before {
  content: "";
}

.bi-plus-square-dotted::before {
  content: "";
}

.bi-plus-square-fill::before {
  content: "";
}

.bi-plus-square::before {
  content: "";
}

.bi-plus::before {
  content: "";
}

.bi-power::before {
  content: "";
}

.bi-printer-fill::before {
  content: "";
}

.bi-printer::before {
  content: "";
}

.bi-puzzle-fill::before {
  content: "";
}

.bi-puzzle::before {
  content: "";
}

.bi-question-circle-fill::before {
  content: "";
}

.bi-question-circle::before {
  content: "";
}

.bi-question-diamond-fill::before {
  content: "";
}

.bi-question-diamond::before {
  content: "";
}

.bi-question-octagon-fill::before {
  content: "";
}

.bi-question-octagon::before {
  content: "";
}

.bi-question-square-fill::before {
  content: "";
}

.bi-question-square::before {
  content: "";
}

.bi-question::before {
  content: "";
}

.bi-rainbow::before {
  content: "";
}

.bi-receipt-cutoff::before {
  content: "";
}

.bi-receipt::before {
  content: "";
}

.bi-reception-0::before {
  content: "";
}

.bi-reception-1::before {
  content: "";
}

.bi-reception-2::before {
  content: "";
}

.bi-reception-3::before {
  content: "";
}

.bi-reception-4::before {
  content: "";
}

.bi-record-btn-fill::before {
  content: "";
}

.bi-record-btn::before {
  content: "";
}

.bi-record-circle-fill::before {
  content: "";
}

.bi-record-circle::before {
  content: "";
}

.bi-record-fill::before {
  content: "";
}

.bi-record::before {
  content: "";
}

.bi-record2-fill::before {
  content: "";
}

.bi-record2::before {
  content: "";
}

.bi-reply-all-fill::before {
  content: "";
}

.bi-reply-all::before {
  content: "";
}

.bi-reply-fill::before {
  content: "";
}

.bi-reply::before {
  content: "";
}

.bi-rss-fill::before {
  content: "";
}

.bi-rss::before {
  content: "";
}

.bi-rulers::before {
  content: "";
}

.bi-save-fill::before {
  content: "";
}

.bi-save::before {
  content: "";
}

.bi-save2-fill::before {
  content: "";
}

.bi-save2::before {
  content: "";
}

.bi-scissors::before {
  content: "";
}

.bi-screwdriver::before {
  content: "";
}

.bi-search::before {
  content: "";
}

.bi-segmented-nav::before {
  content: "";
}

.bi-server::before {
  content: "";
}

.bi-share-fill::before {
  content: "";
}

.bi-share::before {
  content: "";
}

.bi-shield-check::before {
  content: "";
}

.bi-shield-exclamation::before {
  content: "";
}

.bi-shield-fill-check::before {
  content: "";
}

.bi-shield-fill-exclamation::before {
  content: "";
}

.bi-shield-fill-minus::before {
  content: "";
}

.bi-shield-fill-plus::before {
  content: "";
}

.bi-shield-fill-x::before {
  content: "";
}

.bi-shield-fill::before {
  content: "";
}

.bi-shield-lock-fill::before {
  content: "";
}

.bi-shield-lock::before {
  content: "";
}

.bi-shield-minus::before {
  content: "";
}

.bi-shield-plus::before {
  content: "";
}

.bi-shield-shaded::before {
  content: "";
}

.bi-shield-slash-fill::before {
  content: "";
}

.bi-shield-slash::before {
  content: "";
}

.bi-shield-x::before {
  content: "";
}

.bi-shield::before {
  content: "";
}

.bi-shift-fill::before {
  content: "";
}

.bi-shift::before {
  content: "";
}

.bi-shop-window::before {
  content: "";
}

.bi-shop::before {
  content: "";
}

.bi-shuffle::before {
  content: "";
}

.bi-signpost-2-fill::before {
  content: "";
}

.bi-signpost-2::before {
  content: "";
}

.bi-signpost-fill::before {
  content: "";
}

.bi-signpost-split-fill::before {
  content: "";
}

.bi-signpost-split::before {
  content: "";
}

.bi-signpost::before {
  content: "";
}

.bi-sim-fill::before {
  content: "";
}

.bi-sim::before {
  content: "";
}

.bi-skip-backward-btn-fill::before {
  content: "";
}

.bi-skip-backward-btn::before {
  content: "";
}

.bi-skip-backward-circle-fill::before {
  content: "";
}

.bi-skip-backward-circle::before {
  content: "";
}

.bi-skip-backward-fill::before {
  content: "";
}

.bi-skip-backward::before {
  content: "";
}

.bi-skip-end-btn-fill::before {
  content: "";
}

.bi-skip-end-btn::before {
  content: "";
}

.bi-skip-end-circle-fill::before {
  content: "";
}

.bi-skip-end-circle::before {
  content: "";
}

.bi-skip-end-fill::before {
  content: "";
}

.bi-skip-end::before {
  content: "";
}

.bi-skip-forward-btn-fill::before {
  content: "";
}

.bi-skip-forward-btn::before {
  content: "";
}

.bi-skip-forward-circle-fill::before {
  content: "";
}

.bi-skip-forward-circle::before {
  content: "";
}

.bi-skip-forward-fill::before {
  content: "";
}

.bi-skip-forward::before {
  content: "";
}

.bi-skip-start-btn-fill::before {
  content: "";
}

.bi-skip-start-btn::before {
  content: "";
}

.bi-skip-start-circle-fill::before {
  content: "";
}

.bi-skip-start-circle::before {
  content: "";
}

.bi-skip-start-fill::before {
  content: "";
}

.bi-skip-start::before {
  content: "";
}

.bi-slack::before {
  content: "";
}

.bi-slash-circle-fill::before {
  content: "";
}

.bi-slash-circle::before {
  content: "";
}

.bi-slash-square-fill::before {
  content: "";
}

.bi-slash-square::before {
  content: "";
}

.bi-slash::before {
  content: "";
}

.bi-sliders::before {
  content: "";
}

.bi-smartwatch::before {
  content: "";
}

.bi-snow::before {
  content: "";
}

.bi-snow2::before {
  content: "";
}

.bi-snow3::before {
  content: "";
}

.bi-sort-alpha-down-alt::before {
  content: "";
}

.bi-sort-alpha-down::before {
  content: "";
}

.bi-sort-alpha-up-alt::before {
  content: "";
}

.bi-sort-alpha-up::before {
  content: "";
}

.bi-sort-down-alt::before {
  content: "";
}

.bi-sort-down::before {
  content: "";
}

.bi-sort-numeric-down-alt::before {
  content: "";
}

.bi-sort-numeric-down::before {
  content: "";
}

.bi-sort-numeric-up-alt::before {
  content: "";
}

.bi-sort-numeric-up::before {
  content: "";
}

.bi-sort-up-alt::before {
  content: "";
}

.bi-sort-up::before {
  content: "";
}

.bi-soundwave::before {
  content: "";
}

.bi-speaker-fill::before {
  content: "";
}

.bi-speaker::before {
  content: "";
}

.bi-speedometer::before {
  content: "";
}

.bi-speedometer2::before {
  content: "";
}

.bi-spellcheck::before {
  content: "";
}

.bi-square-fill::before {
  content: "";
}

.bi-square-half::before {
  content: "";
}

.bi-square::before {
  content: "";
}

.bi-stack::before {
  content: "";
}

.bi-star-fill::before {
  content: "";
}

.bi-star-half::before {
  content: "";
}

.bi-star::before {
  content: "";
}

.bi-stars::before {
  content: "";
}

.bi-stickies-fill::before {
  content: "";
}

.bi-stickies::before {
  content: "";
}

.bi-sticky-fill::before {
  content: "";
}

.bi-sticky::before {
  content: "";
}

.bi-stop-btn-fill::before {
  content: "";
}

.bi-stop-btn::before {
  content: "";
}

.bi-stop-circle-fill::before {
  content: "";
}

.bi-stop-circle::before {
  content: "";
}

.bi-stop-fill::before {
  content: "";
}

.bi-stop::before {
  content: "";
}

.bi-stoplights-fill::before {
  content: "";
}

.bi-stoplights::before {
  content: "";
}

.bi-stopwatch-fill::before {
  content: "";
}

.bi-stopwatch::before {
  content: "";
}

.bi-subtract::before {
  content: "";
}

.bi-suit-club-fill::before {
  content: "";
}

.bi-suit-club::before {
  content: "";
}

.bi-suit-diamond-fill::before {
  content: "";
}

.bi-suit-diamond::before {
  content: "";
}

.bi-suit-heart-fill::before {
  content: "";
}

.bi-suit-heart::before {
  content: "";
}

.bi-suit-spade-fill::before {
  content: "";
}

.bi-suit-spade::before {
  content: "";
}

.bi-sun-fill::before {
  content: "";
}

.bi-sun::before {
  content: "";
}

.bi-sunglasses::before {
  content: "";
}

.bi-sunrise-fill::before {
  content: "";
}

.bi-sunrise::before {
  content: "";
}

.bi-sunset-fill::before {
  content: "";
}

.bi-sunset::before {
  content: "";
}

.bi-symmetry-horizontal::before {
  content: "";
}

.bi-symmetry-vertical::before {
  content: "";
}

.bi-table::before {
  content: "";
}

.bi-tablet-fill::before {
  content: "";
}

.bi-tablet-landscape-fill::before {
  content: "";
}

.bi-tablet-landscape::before {
  content: "";
}

.bi-tablet::before {
  content: "";
}

.bi-tag-fill::before {
  content: "";
}

.bi-tag::before {
  content: "";
}

.bi-tags-fill::before {
  content: "";
}

.bi-tags::before {
  content: "";
}

.bi-telegram::before {
  content: "";
}

.bi-telephone-fill::before {
  content: "";
}

.bi-telephone-forward-fill::before {
  content: "";
}

.bi-telephone-forward::before {
  content: "";
}

.bi-telephone-inbound-fill::before {
  content: "";
}

.bi-telephone-inbound::before {
  content: "";
}

.bi-telephone-minus-fill::before {
  content: "";
}

.bi-telephone-minus::before {
  content: "";
}

.bi-telephone-outbound-fill::before {
  content: "";
}

.bi-telephone-outbound::before {
  content: "";
}

.bi-telephone-plus-fill::before {
  content: "";
}

.bi-telephone-plus::before {
  content: "";
}

.bi-telephone-x-fill::before {
  content: "";
}

.bi-telephone-x::before {
  content: "";
}

.bi-telephone::before {
  content: "";
}

.bi-terminal-fill::before {
  content: "";
}

.bi-terminal::before {
  content: "";
}

.bi-text-center::before {
  content: "";
}

.bi-text-indent-left::before {
  content: "";
}

.bi-text-indent-right::before {
  content: "";
}

.bi-text-left::before {
  content: "";
}

.bi-text-paragraph::before {
  content: "";
}

.bi-text-right::before {
  content: "";
}

.bi-textarea-resize::before {
  content: "";
}

.bi-textarea-t::before {
  content: "";
}

.bi-textarea::before {
  content: "";
}

.bi-thermometer-half::before {
  content: "";
}

.bi-thermometer-high::before {
  content: "";
}

.bi-thermometer-low::before {
  content: "";
}

.bi-thermometer-snow::before {
  content: "";
}

.bi-thermometer-sun::before {
  content: "";
}

.bi-thermometer::before {
  content: "";
}

.bi-three-dots-vertical::before {
  content: "";
}

.bi-three-dots::before {
  content: "";
}

.bi-toggle-off::before {
  content: "";
}

.bi-toggle-on::before {
  content: "";
}

.bi-toggle2-off::before {
  content: "";
}

.bi-toggle2-on::before {
  content: "";
}

.bi-toggles::before {
  content: "";
}

.bi-toggles2::before {
  content: "";
}

.bi-tools::before {
  content: "";
}

.bi-tornado::before {
  content: "";
}

.bi-trash-fill::before {
  content: "";
}

.bi-trash::before {
  content: "";
}

.bi-trash2-fill::before {
  content: "";
}

.bi-trash2::before {
  content: "";
}

.bi-tree-fill::before {
  content: "";
}

.bi-tree::before {
  content: "";
}

.bi-triangle-fill::before {
  content: "";
}

.bi-triangle-half::before {
  content: "";
}

.bi-triangle::before {
  content: "";
}

.bi-trophy-fill::before {
  content: "";
}

.bi-trophy::before {
  content: "";
}

.bi-tropical-storm::before {
  content: "";
}

.bi-truck-flatbed::before {
  content: "";
}

.bi-truck::before {
  content: "";
}

.bi-tsunami::before {
  content: "";
}

.bi-tv-fill::before {
  content: "";
}

.bi-tv::before {
  content: "";
}

.bi-twitch::before {
  content: "";
}

.bi-twitter::before {
  content: "";
}

.bi-type-bold::before {
  content: "";
}

.bi-type-h1::before {
  content: "";
}

.bi-type-h2::before {
  content: "";
}

.bi-type-h3::before {
  content: "";
}

.bi-type-italic::before {
  content: "";
}

.bi-type-strikethrough::before {
  content: "";
}

.bi-type-underline::before {
  content: "";
}

.bi-type::before {
  content: "";
}

.bi-ui-checks-grid::before {
  content: "";
}

.bi-ui-checks::before {
  content: "";
}

.bi-ui-radios-grid::before {
  content: "";
}

.bi-ui-radios::before {
  content: "";
}

.bi-umbrella-fill::before {
  content: "";
}

.bi-umbrella::before {
  content: "";
}

.bi-union::before {
  content: "";
}

.bi-unlock-fill::before {
  content: "";
}

.bi-unlock::before {
  content: "";
}

.bi-upc-scan::before {
  content: "";
}

.bi-upc::before {
  content: "";
}

.bi-upload::before {
  content: "";
}

.bi-vector-pen::before {
  content: "";
}

.bi-view-list::before {
  content: "";
}

.bi-view-stacked::before {
  content: "";
}

.bi-vinyl-fill::before {
  content: "";
}

.bi-vinyl::before {
  content: "";
}

.bi-voicemail::before {
  content: "";
}

.bi-volume-down-fill::before {
  content: "";
}

.bi-volume-down::before {
  content: "";
}

.bi-volume-mute-fill::before {
  content: "";
}

.bi-volume-mute::before {
  content: "";
}

.bi-volume-off-fill::before {
  content: "";
}

.bi-volume-off::before {
  content: "";
}

.bi-volume-up-fill::before {
  content: "";
}

.bi-volume-up::before {
  content: "";
}

.bi-vr::before {
  content: "";
}

.bi-wallet-fill::before {
  content: "";
}

.bi-wallet::before {
  content: "";
}

.bi-wallet2::before {
  content: "";
}

.bi-watch::before {
  content: "";
}

.bi-water::before {
  content: "";
}

.bi-whatsapp::before {
  content: "";
}

.bi-wifi-1::before {
  content: "";
}

.bi-wifi-2::before {
  content: "";
}

.bi-wifi-off::before {
  content: "";
}

.bi-wifi::before {
  content: "";
}

.bi-wind::before {
  content: "";
}

.bi-window-dock::before {
  content: "";
}

.bi-window-sidebar::before {
  content: "";
}

.bi-window::before {
  content: "";
}

.bi-wrench::before {
  content: "";
}

.bi-x-circle-fill::before {
  content: "";
}

.bi-x-circle::before {
  content: "";
}

.bi-x-diamond-fill::before {
  content: "";
}

.bi-x-diamond::before {
  content: "";
}

.bi-x-octagon-fill::before {
  content: "";
}

.bi-x-octagon::before {
  content: "";
}

.bi-x-square-fill::before {
  content: "";
}

.bi-x-square::before {
  content: "";
}

.bi-x::before {
  content: "";
}

.bi-youtube::before {
  content: "";
}

.bi-zoom-in::before {
  content: "";
}

.bi-zoom-out::before {
  content: "";
}

@font-face {
  font-family: "DIN Pro";
  src: url("/fonts/DINPro-Light.woff2") format("woff2"), url("/fonts/DINPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("/fonts/DINPro-LightItalic.woff2") format("woff2"), url("/fonts/DINPro-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro";
  src: url("/fonts/DINPro-Medium.woff2") format("woff2"), url("/fonts/DINPro-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("/fonts/DINPro-Italic.woff2") format("woff2"), url("/fonts/DINPro-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro";
  src: url("/fonts/DINPro-MediumItalic.woff2") format("woff2"), url("/fonts/DINPro-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "DIN Pro";
  src: url("/fonts/DINPro-Bold.woff2") format("woff2"), url("/fonts/DINPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "DIN Pro";
  src: url("/fonts/DINPro-Black.woff2") format("woff2"), url("/fonts/DINPro-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "subaru-icons";
  src: url("/fonts/subaru-icons.eot?h67hzt");
  src: url("/fonts/subaru-icons.eot?h67hzt#iefix") format("embedded-opentype"), url("/fonts/subaru-icons.ttf?h67hzt") format("truetype"), url("/fonts/subaru-icons.woff?h67hzt") format("woff"), url("/fonts/subaru-icons.svg?h67hzt#subaru-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "subaru-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-event-calendar:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-brochure-outlined:before {
  content: "";
}

.icon-location-outlined:before {
  content: "";
}

.icon-drive-outlined:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-finance-outlined:before {
  content: "";
}

.icon-upload:before {
  content: "";
}

.icon-Group-453:before {
  content: "";
}

.icon-profile:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-arrow-up-right:before {
  content: "";
}

.icon-delete:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-font:before {
  content: "";
}

.icon-shrink:before {
  content: "";
}

.icon-expand:before {
  content: "";
}

.icon-skip-forward:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-skip-back:before {
  content: "";
}

.icon-loop:before {
  content: "";
}

.icon-chat:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-close:before {
  content: "";
}

.icon-right:before {
  content: "";
}

.icon-up:before {
  content: "";
}

.icon-down:before {
  content: "";
}

.icon-left:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-chevron-up:before {
  content: "";
}

.icon-chevron-left:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-chevron-right-slim:before {
  content: "";
}

.icon-chevron-left-slim:before {
  content: "";
}

.icon-chevron-up-slim:before {
  content: "";
}

.icon-restart:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

html {
  scroll-behavior: smooth;
}

body {
  font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
}

.inline {
  display: inline-block;
}

.py-med {
  padding: 6rem 0;
}
@media (max-width: 991.98px) {
  .py-med {
    padding: 3rem 0;
  }
}

.grecaptcha-badge {
  display: none;
}

.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
@media (max-width: 991.98px) {
  .map-responsive {
    padding: 0;
    height: 32vh;
  }
}

.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media (max-width: 991.98px) {
  .map-responsive iframe {
    position: static;
  }
}

.blue-box {
  background: #0099d4;
  padding: 0.75rem 1rem;
}
.blue-box h2 {
  text-transform: uppercase;
  text-align: center;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0;
}

.find h3 {
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .find h3 {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .find {
    padding: 2rem 0;
  }
}
.find .tel {
  font-weight: 700;
}

.cta-box .card-body {
  padding: 0;
}
.cta-box .copy {
  padding: 3rem 5rem 2rem;
}
@media (max-width: 991.98px) {
  .cta-box .copy {
    padding: 3rem;
  }
}
@media (max-width: 767.98px) {
  .cta-box .copy {
    padding: 2rem 3rem 1rem;
  }
}
.cta-box .copy h3 {
  font-size: 2.25rem;
  font-weight: 700;
  text-transform: uppercase;
}
@media (max-width: 1199.98px) {
  .cta-box .copy h3 {
    font-size: 1.75rem;
  }
}
@media (max-width: 767.98px) {
  .cta-box .copy h3 {
    font-size: 1.5rem;
    line-height: 1.3;
  }
}

.content-area {
  background: transparent;
  color: #403F3F;
  padding: 6rem 0;
}
@media (max-width: 1749.98px) {
  .content-area {
    padding: 3rem 0;
  }
}
.content-area .card {
  padding: 2rem 1.5rem !important;
}
.content-area .card .card-body {
  padding: 0;
}

.review-section {
  background-color: #ffffff;
}
.review-section .review-section_cards {
  margin-top: 1.5rem;
}
@media (min-width: 768px) {
  .review-section .review-section_cards {
    margin-top: 3rem;
  }
}
.review-section .review-section_cards .col .card {
  background-color: transparent;
  color: #ffffff;
  display: flex;
  justify-content: center;
  text-align: center;
}
.review-section .review-section_cards .col .card .review-section_card_img {
  margin: 0 auto;
  width: 50px;
  margin-bottom: 1.5em;
}
@media (min-width: 768px) {
  .review-section .review-section_cards .col .card .review-section_card_img {
    width: 65px;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 992px) {
  .review-section .review-section_cards .col .card .review-section_card_img {
    width: 80px;
    margin-bottom: 1rem;
  }
}
.review-section .review-section_cards .col:nth-of-type(4n+1) {
  background-color: #403F3F;
}
.review-section .review-section_cards .col:nth-of-type(4n+2) {
  background-color: #212121;
}
.review-section .review-section_cards .col:nth-of-type(4n+3) {
  background-color: #403F3F;
}
@media (max-width: 991.98px) {
  .review-section .review-section_cards .col:nth-of-type(4n+3) {
    background-color: #212121;
  }
}
@media (max-width: 767.98px) {
  .review-section .review-section_cards .col:nth-of-type(4n+3) {
    background-color: #403F3F;
  }
}
.review-section .review-section_cards .col:nth-of-type(4n+4) {
  background-color: #212121;
}
@media (max-width: 991.98px) {
  .review-section .review-section_cards .col:nth-of-type(4n+4) {
    background-color: #403F3F;
  }
}
@media (max-width: 767.98px) {
  .review-section .review-section_cards .col:nth-of-type(4n+4) {
    background-color: #212121;
  }
}
.review-section .review-section_details, .review-section .review-section_listing {
  background-color: #E7E7E7;
  padding: 2rem 1rem;
  text-align: center;
}
@media (min-width: 768px) {
  .review-section .review-section_details, .review-section .review-section_listing {
    padding: 2rem;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .review-section .review-section_details, .review-section .review-section_listing {
    padding: 3rem;
  }
}
.review-section .review-section_details .review-section_details_star_rating, .review-section .review-section_listing .review-section_details_star_rating {
  align-items: center;
  display: inline-flex;
}
.review-section .review-section_details .review-section_details_star_rating .review-section_details_star_rating_number, .review-section .review-section_listing .review-section_details_star_rating .review-section_details_star_rating_number {
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  color: #0099d4;
  display: flex;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  margin-right: 0.65rem;
  width: 40px;
}
.review-section .review-section_details .review-section_details_star_rating .review-section_details_star_rating_stars i, .review-section .review-section_listing .review-section_details_star_rating .review-section_details_star_rating_stars i {
  color: #ffbb06;
  font-size: 1.25rem;
}
.review-section .review-section_details .review-section_details_star_rating .dealer-title_stars, .review-section .review-section_listing .review-section_details_star_rating .dealer-title_stars {
  display: inline-flex;
  position: relative;
}
.review-section .review-section_details .review-section_details_star_rating .dealer-title_stars:hover .cls-2, .review-section .review-section_listing .review-section_details_star_rating .dealer-title_stars:hover .cls-2 {
  fill: #9f7400;
}
.review-section .review-section_details .review-section_details_star_rating .dealer-title_stars svg, .review-section .review-section_listing .review-section_details_star_rating .dealer-title_stars svg {
  width: 110px;
}
@media (max-width: 767.98px) {
  .review-section .review-section_details .review-section_details_star_rating .dealer-title_stars svg, .review-section .review-section_listing .review-section_details_star_rating .dealer-title_stars svg {
    width: 75px;
  }
}
.review-section .review-section_details .review-section_details_star_rating .dealer-title_stars svg .cls-1, .review-section .review-section_listing .review-section_details_star_rating .dealer-title_stars svg .cls-1 {
  fill: #ffffff;
}
.review-section .review-section_details .review-section_details_star_rating .dealer-title_stars .dealer-title_stars_overlay, .review-section .review-section_listing .review-section_details_star_rating .dealer-title_stars .dealer-title_stars_overlay {
  position: absolute;
  right: 0;
  top: 0;
}
.review-section .review-section_details .review-section_details_star_rating .dealer-title_stars .dealer-title_stars_overlay .cls-2, .review-section .review-section_listing .review-section_details_star_rating .dealer-title_stars .dealer-title_stars_overlay .cls-2 {
  fill: #ffbb06;
}
.review-section .review-section_details .review-section_customer_metrics_list, .review-section .review-section_listing .review-section_customer_metrics_list {
  list-style-type: none;
  padding-left: 0;
}
@media (min-width: 1200px) {
  .review-section .review-section_details .review-section_customer_metrics_list, .review-section .review-section_listing .review-section_customer_metrics_list {
    column-count: 4;
  }
}
@media (min-width: 992px) {
  .review-section .review-section_details .review-section_customer_metrics_list, .review-section .review-section_listing .review-section_customer_metrics_list {
    column-count: 3;
  }
}
@media (max-width: 1199.98px) {
  .review-section .review-section_details .review-section_customer_metrics_list, .review-section .review-section_listing .review-section_customer_metrics_list {
    column-count: 2;
  }
}
@media (max-width: 991.98px) {
  .review-section .review-section_details .review-section_customer_metrics_list, .review-section .review-section_listing .review-section_customer_metrics_list {
    column-count: 1;
  }
}
.review-section .review-section_details .review-section_customer_metrics_list .review-section_customer_metrics_list_score, .review-section .review-section_listing .review-section_customer_metrics_list .review-section_customer_metrics_list_score {
  color: #0099d4;
  font-weight: 700;
}
.review-section .review-section_details .review-section_details_breakdown, .review-section .review-section_listing .review-section_details_breakdown {
  color: #909090;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 700;
  margin-top: 0.35rem;
  text-transform: uppercase;
  transition: ease color 300ms;
}
.review-section .review-section_details .review-section_details_breakdown:hover, .review-section .review-section_listing .review-section_details_breakdown:hover {
  color: #0099d4;
}
.review-section .review-section_details .review-section_details_score_rating, .review-section .review-section_listing .review-section_details_score_rating {
  display: inline-flex;
  align-items: center;
}
.review-section .review-section_details .review-section_details_score_rating .review-section_details_score_rating_number, .review-section .review-section_listing .review-section_details_score_rating .review-section_details_score_rating_number {
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  color: #0099d4;
  display: flex;
  font-weight: 700;
  height: 70px;
  justify-content: center;
  margin-right: 0.65rem;
  width: 70px;
}
.review-section .review-section_customer_service_item {
  display: block;
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  .review-section .review-section_customer_service_item {
    display: inline-flex;
    margin-bottom: 0;
  }
}
.review-section .review-section_customer_service_item.review-section_customer_service_item_enrolment i {
  color: #0099d4;
}
.review-section .review-section_customer_service_item.review-section_customer_service_item_gold i {
  color: #ffbb06;
}
.review-section .review-section_customer_service_item.review-section_customer_service_item_silver i {
  color: #898989;
}
.review-section .review-section_customer_service_item.review-section_customer_service_item_bronze i {
  color: #a67901;
}
.review-section .review-section_customer_service_item .review-section_customer_service_item_title {
  font-weight: 700;
  padding-right: 1rem;
  text-transform: uppercase;
}
.review-section .review-section_customer_service_item .review-section_customer_service_item_awards {
  cursor: pointer;
  font-weight: 700;
  color: #0099d4;
  transition: ease all 0.25s;
}
.review-section .review-section_customer_service_item .review-section_customer_service_item_awards:hover {
  color: #00658d;
}
.review-section .review-section_listing {
  margin-top: 1rem;
  position: relative;
}
.review-section .review-section_listing:after {
  background: linear-gradient(0deg, #E7E7E7 0%, rgba(255, 255, 255, 0) 100%);
  bottom: 0;
  content: "";
  height: 150px;
  left: 0;
  position: absolute;
  right: 2rem;
}
@media (min-width: 992px) {
  .review-section .review-section_listing:after {
    bottom: 3rem;
    right: 4rem;
  }
}
.review-section .review-section_listing .review-section_listing_wrapper {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.review-section .review-section_listing .review-section_listing_wrapper .review-section_listing_item {
  display: flex;
  margin-bottom: 2rem;
  padding-right: 2rem;
}
.review-section .review-section_listing .review-section_listing_wrapper .review-section_listing_item .review-section_listing_initials {
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  color: #0099d4;
  display: flex;
  font-weight: 700;
  justify-content: center;
  margin-right: 1rem;
  height: 50px;
  width: 50px;
}
@media (min-width: 992px) {
  .review-section .review-section_listing .review-section_listing_wrapper .review-section_listing_item .review-section_listing_initials {
    height: 70px;
    width: 70px;
  }
}
@media (min-width: 768px) {
  .review-section .review-section_listing .review-section_listing_wrapper .review-section_listing_item .review-section_listing_initials {
    height: 60px;
    width: 60px;
  }
}
.review-section .review-section_listing .review-section_listing_wrapper .review-section_listing_item .review-section_listing_review {
  width: 100%;
}
.review-section .review-section_listing .review-section_listing_wrapper .review-section_listing_item .review-section_listing_review .review-section_listing_review_score {
  font-weight: 700;
  text-transform: uppercase;
}
.review-section .review-section_listing .review-section_listing_wrapper .review-section_listing_item .review-section_listing_review .review-section_listing_review_score .primary {
  color: #0099d4;
}
.review-section .review-section_listing .review-section_listing_wrapper .review-section_listing_item .review-section_listing_review .review-section_listing_review_date {
  font-weight: 500;
  text-transform: uppercase;
}
.review-section .review-section_listing ::-webkit-scrollbar {
  border-radius: 0;
  -webkit-appearance: none;
  width: 7px;
}
.review-section .review-section_listing ::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #0099d4;
}
.review-section .review-section_listing ::-webkit-scrollbar-track {
  background: #ffffff;
}
.review-section .review-section_listing ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.average-star_breakdown .average-star_breakdown_stars_item .average-star_breakdown_stars_icons {
  display: inline-flex;
}
.average-star_breakdown .average-star_breakdown_stars_item .average-star_breakdown_stars_icons i {
  color: #ffbb06;
  font-size: 1.75rem;
  padding-right: 0.5rem;
}
.average-star_breakdown .average-star_breakdown_stars_item .average-star_breakdown_stars_score {
  align-items: center;
  background-color: #0099d4;
  border-radius: 100%;
  color: #ffffff;
  display: inline-flex;
  font-weight: 700;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.average-score_breakdown {
  column-count: 2;
}
.average-score_breakdown .average-score_breakdown_stars_item {
  margin-bottom: 1rem;
}
.average-score_breakdown .average-score_breakdown_stars_item .average-star_breakdown_stars_icons {
  display: inline-flex;
}
.average-score_breakdown .average-score_breakdown_stars_item .average-star_breakdown_stars_score {
  align-items: center;
  background-color: #0099d4;
  border-radius: 100%;
  color: #ffffff;
  display: inline-flex;
  font-weight: 700;
  height: 30px;
  justify-content: center;
  width: 30px;
}

.custom-select.form-control {
  color: rgba(10, 10, 10, 0.9);
}

.enquiry-form {
  margin: 4rem 0 0;
  position: relative;
}
@media (max-width: 991.98px) {
  .enquiry-form {
    margin: 2rem 0 0;
  }
}
.enquiry-form .btn-primary {
  margin-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (max-width: 767.98px) {
  .enquiry-form .btn-primary {
    width: 100%;
  }
}
.enquiry-form ::-webkit-input-placeholder {
  /* Edge */
  color: rgba(10, 10, 10, 0.9);
}
.enquiry-form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(10, 10, 10, 0.9);
}
.enquiry-form ::placeholder {
  color: rgba(10, 10, 10, 0.9);
}

.form-control {
  border-radius: 0;
  border-color: #707070;
  color: #0099d4;
  height: 48px;
}

textarea.form-control {
  height: 31vh;
}

.form-check-label {
  font-size: 0.75rem;
}

.bg-dark {
  background-color: #000000 !important;
}

.bg-mid {
  background-color: #403F3F;
}

.bg-footer {
  background-color: #212121;
}

.bg-light-grey {
  background-color: #f5f5f5;
}

.bg-light-grey-alt {
  background-color: #E7E7E7;
}

.dark-grad-bg {
  background: #222222;
  background: -moz-linear-gradient(180deg, #222222 0%, #393939 100%);
  background: -webkit-linear-gradient(180deg, #222222 0%, #393939 100%);
  background: linear-gradient(180deg, #222222 0%, #393939 100%);
  color: #ffffff;
}
.dark-grad-bg .card {
  color: #ffffff;
}

.light-grad-bg {
  background: #d5d5d5;
  background: -moz-linear-gradient(180deg, #d5d5d5 0%, whitesmoke 100%);
  background: -webkit-linear-gradient(180deg, #d5d5d5 0%, whitesmoke 100%);
  background: linear-gradient(180deg, #d5d5d5 0%, whitesmoke 100%);
}

body {
  font-family: "DIN Pro", sans-serif;
  font-weight: 300;
  letter-spacing: -0.025em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  text-transform: uppercase;
  font-weight: 700;
}

.small-text {
  font-size: 0.75rem;
}

.intro-bold {
  font-weight: 700;
  font-size: 1.25rem;
}

.btn {
  border-radius: 0;
}

.btn-primary {
  border-radius: 0;
}

.main-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  position: absolute;
  top: 0;
}
.main-nav .main-nav-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(0.75);
}
@media (max-width: 1199.98px) {
  .main-nav .main-nav-logo {
    width: 8.5rem;
  }
}
@media (max-width: 991.98px) {
  .main-nav {
    height: 100%;
    top: 0;
  }
  .main-nav .main-nav-logo {
    height: 100%;
  }
  .main-nav .main-nav-logo img {
    height: 100%;
  }
}
.main-nav .main-nav-toggle {
  cursor: pointer;
  border: 0;
  padding: 2rem;
  position: absolute;
  background: transparent;
  right: 0;
  z-index: 200;
  color: currentColor;
  transition: color 0.4s ease-out;
  height: 80px;
}
@media (max-width: 1199.98px) {
  .main-nav .main-nav-toggle {
    height: 5.875rem;
  }
}
@media (max-width: 377.98px) {
  .main-nav .main-nav-toggle {
    padding: 2rem 1rem;
  }
}
.main-nav .main-nav-toggle svg {
  width: 1.5rem;
}
.main-nav .main-nav-toggle span {
  display: block;
  width: 1.5rem;
  height: 0.125rem;
  background-color: currentColor;
  border-radius: 0.5rem;
  position: relative;
  transition: background-color 0.4s ease-out;
}
@media (max-width: 377.98px) {
  .main-nav .main-nav-toggle span {
    width: 1.5rem;
  }
}
.main-nav .main-nav-toggle span::before, .main-nav .main-nav-toggle span::after {
  content: "";
  display: block;
  background-color: currentColor;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-0.5rem);
  transition: transform 0.3s ease-out;
}
.main-nav .main-nav-toggle span::after {
  transform: translateY(0.5rem);
}

.main-nav-content {
  width: 100%;
  background-color: #212121;
  height: 100vh;
  z-index: 120;
  position: fixed;
  overflow-y: auto;
  left: 100%;
  top: 0;
  font-weight: 300;
  transition: transform 0.3s ease-out;
  padding: 1.25rem 3.25rem 2rem 2rem;
}
.main-nav-content .nav-search {
  margin: 0 2rem 1.5rem 0;
}
.main-nav-content .nav-search input {
  height: 3.25rem;
  color: #ffffff;
  border: 1px solid transparent;
  background-color: #000;
}
.main-nav-content .nav-search input:focus {
  border-color: #888888;
  box-shadow: none;
}
.main-nav-content .subaru-accordion .card {
  background: transparent;
}
.main-nav-content .subaru-accordion .card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  text-transform: none;
  text-align: left;
  padding-left: 2rem;
  font-weight: 500;
  color: #ffffff;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.main-nav-content .subaru-accordion .card .card-header:hover {
  opacity: 1;
  left: 0;
  background-color: #171717;
}
.main-nav-content .subaru-accordion .card .card-header.big {
  font-size: 1.125rem;
}
.main-nav-content .subaru-accordion .card .card-header svg {
  width: 16px;
  height: 16px;
  cursor: pointer;
  color: white;
  fill: none;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #F0F0F0;
  display: block;
  pointer-events: none;
  height: 1rem;
  width: 1rem;
  transform: rotate(180deg);
}
.main-nav-content .subaru-accordion .card .card-header.collapsed svg {
  transform: rotate(0deg);
}
.main-nav-content .subaru-accordion .card .card-body {
  background: transparent;
  padding: 0.5rem 2rem;
}
.main-nav-content .subaru-accordion .card .card-body ul.footer-list {
  margin: 0;
  padding-left: 0;
}
.main-nav-content .subaru-accordion .card .card-body ul.footer-list li a {
  display: block;
}
.main-nav-content .subaru-accordion .card .card-body ul.footer-list li a,
.main-nav-content .subaru-accordion .card .card-body ul.footer-list li .card-header {
  font-size: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  padding-left: 0.75rem;
  padding-right: 2rem;
  line-height: 1.65rem;
  font-weight: 500;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.main-nav-content .subaru-accordion .card .card-body ul.footer-list li a:hover,
.main-nav-content .subaru-accordion .card .card-body ul.footer-list li .card-header:hover {
  opacity: 1;
  left: 0;
  background-color: #171717;
}
@media (min-width: 768px) {
  .main-nav-content .subaru-accordion .card .card-body ul.footer-list li a,
.main-nav-content .subaru-accordion .card .card-body ul.footer-list li .card-header {
    padding-left: 1.5rem;
  }
}
.main-nav-content .subaru-accordion .card .card-body ul.footer-list li .subaru-accordion .card-header {
  font-size: 1rem;
  padding-right: 0 !important;
}
.main-nav-content .subaru-accordion .card .card-body ul.footer-list li .subaru-accordion .card-body {
  background: transparent;
  padding-left: 0.75rem;
  padding-right: 0;
}
@media (min-width: 768px) {
  .main-nav-content .subaru-accordion .card .card-body ul.footer-list li .subaru-accordion .card-body {
    padding-left: 1.5rem;
  }
}
.main-nav-content .subaru-accordion .accordion-content .card .card-body {
  background: transparent;
}
.main-nav-content hr {
  margin-left: 2rem;
  margin-right: 2rem;
  border-color: #333;
}
@media (min-width: 768px) {
  .main-nav-content {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .main-nav-content {
    width: 33.33333%;
    padding-top: 5rem;
  }
}
@media (min-width: 1200px) {
  .main-nav-content {
    width: 25%;
  }
}
.main-nav-content ul {
  margin: 0;
  padding: 0;
}
.main-nav-content ul li {
  list-style-type: none;
}
.main-nav-content .menu-main-menu-container {
  margin-left: -2rem;
  margin-right: -3.25rem;
  padding: 0.75rem 0;
}
.main-nav-content .menu-main-menu-container:not(:last-child) {
  position: relative;
  padding-bottom: 1rem;
}
.main-nav-content .menu-main-menu-container:not(:last-child)::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 4rem);
  border-bottom: 1px solid #333;
}
.main-nav-content .menu-main-menu-container:not(:first-of-type) {
  padding-top: 1rem;
}
.main-nav-content .menu-item-list > .menu-item {
  display: block;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.5;
  position: relative;
  font-size: 0.875rem;
  letter-spacing: normal;
  cursor: pointer;
}
.main-nav-content .menu-item-list > .menu-item > a {
  display: block;
  color: currentColor;
  padding: 0.5rem 2rem;
  font-size: inherit;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: 300ms;
  text-decoration: none;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.main-nav-content .menu-item-list > .menu-item > a:hover {
  opacity: 1;
  left: 0;
  text-decoration: none;
  background-color: #171717;
}
.main-nav-content .menu-item-list > .menu-item.is-accordion-submenu-parent[aria-expanded=true] > a {
  background-color: #000000;
}
@media (min-width: 992px) {
  .main-nav-content .menu-item-list > .menu-item {
    font-size: 1rem;
  }
}
.main-nav-content .menu-item-list .menu-item a {
  font-weight: 500;
  font-size: 1rem;
  position: relative;
  transition: background 0.3s ease, color 0.3s ease;
}
.main-nav-content .menu-item-list .menu-item.active a {
  background-color: transparent;
  transition: background 0.3s ease, color 0.3s ease;
}
.main-nav-content .menu-item-list .menu-item.has-submenu-toggle > a {
  margin-right: 0;
}
.main-nav-content .menu-item-list .menu-item .submenu-toggle {
  color: currentColor;
  height: rem-calc(42);
  width: rem-calc(42);
  right: 1rem;
}
.main-nav-content .menu-item-list .menu-item .submenu-toggle:hover {
  background-color: #000000;
}
.main-nav-content .menu-item-list .menu-item .submenu-toggle::after {
  content: "";
  width: auto;
  height: auto;
  color: currentColor;
  border: 0;
  font-size: 0.75rem;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "subaru-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent:not(.is-submenu-item) > a {
  z-index: 5;
  display: inline-block;
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent:not(.is-submenu-item) > a:hover {
  background-color: rgba(0, 0, 0, 0);
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent:not(.is-submenu-item) > .submenu-toggle {
  right: 0;
  width: 100%;
  text-align: right;
  padding-right: 2rem;
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent:not(.is-submenu-item) > .submenu-toggle[aria-expanded=true] {
  background-color: #000000;
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent:not(.is-submenu-item) > .submenu-toggle[aria-expanded=true]:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent:not(.is-submenu-item) > .submenu-toggle:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative;
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  content: "";
  width: auto;
  height: auto;
  border: 0;
  right: 2rem;
  margin-top: -0.5em;
  font-size: 0.75rem;
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "subaru-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent.is-submenu-item {
  display: flex;
  flex-wrap: wrap;
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent.is-submenu-item .submenu-toggle {
  position: relative;
  top: auto;
  right: auto;
  height: rem-calc(30);
  flex-grow: 1;
  text-align: left;
  padding-left: 0.75rem;
}
.main-nav-content .menu-item-list .menu-item.is-accordion-submenu-parent.is-submenu-item .submenu-toggle:hover {
  background: transparent;
}
.main-nav-content .menu-item-list .menu-item .is-accordion-submenu {
  padding: 0.5rem 1rem 0.75rem 3rem;
  background-color: rgba(0, 0, 0, 0.2);
  flex: 0 0 100%;
}
.main-nav-content .menu-item-list .menu-item .is-accordion-submenu a {
  font-size: 0.9rem;
  padding: 0.5rem 0;
  color: currentColor;
}
.main-nav-content .menu-item-list .menu-item .is-accordion-submenu a::after {
  position: static;
  display: inline-block;
  margin-left: 0.5rem;
}
.main-nav-content .menu-item-list .menu-item .is-accordion-submenu .is-accordion-submenu {
  padding-top: 0;
  padding-left: 2rem;
  background: none;
}
.main-nav-content .menu-item-list .menu-item .is-accordion-submenu .is-accordion-submenu a {
  font-weight: 300;
}

.main-nav.main-nav-open .main-nav-content {
  transform: translateX(-100%);
}
.main-nav.main-nav-open .main-nav-toggle {
  color: #ffffff;
}
.main-nav.main-nav-open .main-nav-toggle span {
  background-color: transparent;
}
.main-nav.main-nav-open .main-nav-toggle span::before {
  transform: rotate(45deg);
}
.main-nav.main-nav-open .main-nav-toggle span::after {
  transform: rotate(-45deg);
}

/* --------------------------------

Primary style

-------------------------------- */
*, *::after, *::before {
  box-sizing: border-box;
}

/* --------------------------------

1. Auto-Hiding Navigation - Simple

-------------------------------- */
.cd-auto-hide-header {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  /* Force Hardware Acceleration */
  transform: translateZ(0);
  will-change: transform;
  transition: transform 0.5s;
}
.cd-auto-hide-header::after {
  clear: both;
  content: "";
  display: block;
}
.cd-auto-hide-header.is-hidden {
  transform: translateY(-100%);
}
@media only screen and (min-width: 1024px) {
  .cd-auto-hide-header {
    height: 80px;
  }
}

.header-wrapper {
  height: 60px;
}
@media only screen and (min-width: 1024px) {
  .header-wrapper {
    height: 80px;
  }
}

.cd-auto-hide-header .logo,
.cd-auto-hide-header .nav-trigger {
  position: absolute;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
}

.img-logo {
  height: 65px;
}
@media (max-width: 991.98px) {
  .img-logo {
    height: 47px;
  }
}

.cd-auto-hide-header .logo {
  left: 5%;
}
.cd-auto-hide-header .logo a, .cd-auto-hide-header .logo img {
  display: block;
}

.cd-auto-hide-header .nav-trigger {
  /* vertically align its content */
  display: table;
  height: 100%;
  padding: 0 1em;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #403F3F;
  font-weight: bold;
  right: 0;
  border-left: 1px solid #f2f2f2;
}
.cd-auto-hide-header .nav-trigger span {
  /* vertically align inside parent element */
  display: table-cell;
  vertical-align: middle;
}
.cd-auto-hide-header .nav-trigger em, .cd-auto-hide-header .nav-trigger em::after, .cd-auto-hide-header .nav-trigger em::before {
  /* this is the menu icon */
  display: block;
  position: relative;
  height: 2px;
  width: 22px;
  background-color: #403F3F;
  backface-visibility: hidden;
}
.cd-auto-hide-header .nav-trigger em {
  /* this is the menu central line */
  margin: 6px auto 14px;
  transition: background-color 0.2s;
}
.cd-auto-hide-header .nav-trigger em::before, .cd-auto-hide-header .nav-trigger em::after {
  position: absolute;
  content: "";
  left: 0;
  transition: transform 0.2s;
}
.cd-auto-hide-header .nav-trigger em::before {
  /* this is the menu icon top line */
  transform: translateY(-6px);
}
.cd-auto-hide-header .nav-trigger em::after {
  /* this is the menu icon bottom line */
  transform: translateY(6px);
}
@media only screen and (min-width: 1024px) {
  .cd-auto-hide-header .nav-trigger {
    display: none;
  }
}

.cd-auto-hide-header.nav-open .nav-trigger em {
  /* transform menu icon into a 'X' icon */
  background-color: rgba(255, 255, 255, 0);
}
.cd-auto-hide-header.nav-open .nav-trigger em::before {
  /* rotate top line */
  transform: rotate(-45deg);
}
.cd-auto-hide-header.nav-open .nav-trigger em::after {
  /* rotate bottom line */
  transform: rotate(45deg);
}

.cd-primary-nav {
  display: inline-block;
  float: right;
  height: 100%;
  padding-right: 5%;
}
.cd-primary-nav > ul {
  position: absolute;
  z-index: 2;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  display: none;
  box-shadow: 0 14px 20px rgba(0, 0, 0, 0.2);
}
.cd-primary-nav > ul a {
  /* target primary-nav links */
  display: block;
  height: 50px;
  line-height: 50px;
  padding-left: 5%;
  color: #403F3F;
  font-size: 1.25rem;
  border-top: 1px solid #f2f2f2;
}
.cd-primary-nav > ul a:hover, .cd-primary-nav > ul a.active {
  color: #0099d4;
}
@media only screen and (min-width: 1024px) {
  .cd-primary-nav {
    /* vertically align its content */
    display: table;
  }
  .cd-primary-nav > ul {
    /* vertically align inside parent element */
    display: table-cell;
    vertical-align: middle;
    /* reset mobile style */
    position: relative;
    width: auto;
    top: 0;
    padding: 0;
    background-color: transparent;
    box-shadow: none;
  }
  .cd-primary-nav > ul::after {
    clear: both;
    content: "";
    display: block;
  }
  .cd-primary-nav > ul li {
    display: inline-block;
    float: left;
    margin-right: 1.5em;
  }
  .cd-primary-nav > ul li:last-of-type {
    margin-right: 0;
  }
  .cd-primary-nav > ul a {
    /* reset mobile style */
    height: auto;
    line-height: normal;
    padding: 0;
    border: none;
  }
}

.nav-open .cd-primary-nav ul,
.cd-primary-nav ul:target {
  /*
      show primary nav - mobile only
      :target is used to show navigation on no-js devices
  */
  display: block;
}
@media only screen and (min-width: 1024px) {
  .nav-open .cd-primary-nav ul,
.cd-primary-nav ul:target {
    display: table-cell;
  }
}

/* --------------------------------

2. Auto-Hiding Navigation - with Sub Nav

-------------------------------- */
.cd-secondary-nav {
  position: relative;
  z-index: 1;
  clear: both;
  width: 100%;
  height: 90px;
  background-color: #403F3F;
  /* Force Hardware Acceleration */
  transform: translateZ(0);
  will-change: transform;
  transition: transform 0.5s;
}
.cd-secondary-nav.nav-end::after {
  opacity: 0;
}
.cd-secondary-nav ul, .cd-secondary-nav li, .cd-secondary-nav a {
  height: 100%;
}
.cd-secondary-nav ul {
  /* enables a flex context for all its direct children */
  display: flex;
  padding: 0 5%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.cd-secondary-nav ul::after {
  clear: both;
  content: "";
  display: block;
}
.cd-secondary-nav li {
  display: inline-block;
  float: left;
  /* do not shrink - elements float on the right of the element */
  flex-shrink: 0;
}
.cd-secondary-nav li:last-of-type {
  padding-right: 20px;
}
.cd-secondary-nav a {
  display: block;
  color: #ffffff;
  opacity: 0.6;
  line-height: 90px;
  padding: 0 1em;
}
.cd-secondary-nav a:hover, .cd-secondary-nav a.active {
  opacity: 1;
}
@media only screen and (min-width: 1024px) {
  .cd-secondary-nav {
    height: 90px;
    overflow: visible;
  }
  .cd-secondary-nav ul {
    /* reset mobile style */
    display: block;
    text-align: center;
  }
  .cd-secondary-nav li {
    /* reset mobile style */
    float: none;
    flex-shrink: 1;
  }
  .cd-secondary-nav a {
    line-height: 90px;
  }
  .cd-secondary-nav a.active {
    box-shadow: inset 0 -3px #0099d4;
  }
}

/* --------------------------------

3. Auto-Hiding Navigation - with Sub Nav + Hero Image

-------------------------------- */
.cd-secondary-nav.fixed {
  position: fixed;
  top: 60px;
}
.cd-secondary-nav.slide-up {
  transform: translateY(-60px);
}
@media only screen and (min-width: 1024px) {
  .cd-secondary-nav.fixed {
    top: 80px;
    /* fixes a bug where nav and subnab move with a slight delay */
    box-shadow: 0 -6px 0 #403F3F;
  }
  .cd-secondary-nav.slide-up {
    transform: translateY(-80px);
  }
}

/* --------------------------------

Main content

-------------------------------- */
.cd-main-content {
  overflow: hidden;
}
.cd-main-content.sub-nav {
  /* to be used if there is sub nav */
  padding-top: 150px;
}
.cd-main-content.sub-nav-hero {
  /* to be used if there is hero image + subnav */
  padding-top: 0;
}
.cd-main-content.sub-nav-hero.secondary-nav-fixed {
  margin-top: 90px;
}
@media only screen and (min-width: 1024px) {
  .cd-main-content {
    padding-top: 80px;
  }
  .cd-main-content.sub-nav-hero.secondary-nav-fixed {
    margin-top: 90px;
  }
}

.dealer-title {
  align-items: center;
  color: #ffffff;
  display: inline-flex;
  font-weight: 700;
  font-size: 1.75rem;
  text-transform: uppercase;
  line-height: 1;
}
@media (max-width: 1499.98px) {
  .dealer-title {
    font-size: 1.5rem;
  }
}
@media (max-width: 991.98px) {
  .dealer-title {
    font-size: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .dealer-title {
    display: block;
    font-size: 1rem;
  }
}
.dealer-title .dealer-title_link {
  align-items: center;
  display: inline-flex;
  opacity: 1;
}
@media (max-width: 767.98px) {
  .dealer-title .dealer-title_link {
    padding-left: 0;
    margin-top: 0.5rem;
  }
}
.dealer-title .dealer-title_link:hover {
  text-decoration: none;
}
.dealer-title .dealer-title_link:hover .dealer-title_score {
  background-color: #ffffff;
  color: #0099d4;
}
.dealer-title .dealer-title_link .dealer-title_score {
  align-items: center;
  background-color: #0099d4;
  border-radius: 100%;
  color: #ffffff;
  display: flex;
  font-size: 0.8rem;
  font-weight: 700;
  height: 30px;
  justify-content: center;
  margin: 0 0.5rem;
  width: 30px;
  transition: ease all 300ms;
}
@media (max-width: 767.98px) {
  .dealer-title .dealer-title_link .dealer-title_score {
    height: 20px;
    width: 20px;
    font-size: 0.6rem;
    margin: 0 0.5rem 0 0;
  }
}
.dealer-title .dealer-title_link .dealer-title_stars {
  display: inline-flex;
  position: relative;
}
.dealer-title .dealer-title_link .dealer-title_stars:hover .cls-2 {
  fill: #9f7400;
}
.dealer-title .dealer-title_link .dealer-title_stars svg {
  width: 110px;
}
@media (max-width: 767.98px) {
  .dealer-title .dealer-title_link .dealer-title_stars svg {
    width: 75px;
  }
}
.dealer-title .dealer-title_link .dealer-title_stars svg .cls-1 {
  fill: #ffffff;
}
.dealer-title .dealer-title_link .dealer-title_stars .dealer-title_stars_overlay {
  position: absolute;
  right: 0;
  top: 0;
}
.dealer-title .dealer-title_link .dealer-title_stars .dealer-title_stars_overlay .cls-2 {
  fill: #ffbb06;
}

.dealer-address {
  color: #ffffff;
  line-height: 1;
  display: block;
  margin-left: 0;
  vertical-align: super;
  margin-top: 0.5rem;
}
@media (max-width: 1499.98px) {
  .dealer-address {
    font-size: 0.75rem;
  }
}
@media (max-width: 1199.98px) {
  .dealer-address {
    margin-left: 0;
  }
}
@media (max-width: 991.98px) {
  .dealer-address {
    display: none;
  }
}

.border-right {
  border-right: 1px solid #ffffff;
  margin-right: 2rem;
  padding-right: 2rem;
}
@media (max-width: 767.98px) {
  .border-right {
    margin-right: 0.5rem;
    padding-right: 0.5rem;
  }
}

.dealer-tel {
  color: #ffffff;
  font-weight: 700;
  line-height: 1;
  font-size: 1.25rem;
  display: inline-block;
}
.dealer-tel a {
  display: inline-block;
}
@media (max-width: 1199.98px) {
  .dealer-tel {
    font-size: 1rem;
  }
}
@media (max-width: 767.98px) {
  .dealer-tel {
    font-size: 0.75rem;
  }
}

.header-contact {
  color: #ffffff;
}
.header-contact a {
  height: inherit;
  color: #ffffff;
  line-height: 1;
  opacity: 1;
  position: relative;
  left: 0;
  transition: left 0.3s ease, opacity 0.3s ease;
}
.header-contact a:hover {
  text-decoration: none;
  left: 5px;
  opacity: 0.5;
}
.header-contact i {
  margin-right: 0.5rem;
}

/*
    adjust the positioning of in-page links
    http://nicolasgallagher.com/jump-links-and-viewport-positioning/
*/
.cd-main-content.sub-nav :target::before,
.cd-main-content.sub-nav-hero :target::before {
  display: block;
  content: "";
  margin-top: -90px;
  height: 90px;
  visibility: hidden;
}
@media only screen and (min-width: 1024px) {
  .cd-main-content.sub-nav :target::before,
.cd-main-content.sub-nav-hero :target::before {
    margin-top: -90px;
    height: 90px;
  }
}

/* --------------------------------

Intro Section

-------------------------------- */
.cd-hero {
  /* vertically align its content */
  display: table;
  width: 100%;
  margin-top: 60px;
  height: 300px;
  background-size: cover;
}
.cd-hero .cd-hero-content {
  /* vertically align inside parent element */
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .cd-hero {
    height: 400px;
  }
}
@media only screen and (min-width: 1024px) {
  .cd-hero {
    height: 600px;
    margin-top: 80px;
  }
}

.banner {
  height: 89vh;
}
@media (max-width: 767.98px) {
  .banner {
    height: 49vh;
  }
}

.hero-caption {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column;
  margin-left: 6rem;
  z-index: 1;
}
@media (max-width: 767.98px) {
  .hero-caption {
    margin-left: 0;
  }
}
.hero-caption .hero-text-wrapper {
  background: black;
  background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0) 100%);
  color: #ffffff;
  padding: 1rem;
  position: relative;
  z-index: 5;
  padding: 3rem 7em 3rem 3rem;
  height: auto;
  width: 35rem;
}
@media (max-width: 767.98px) {
  .hero-caption .hero-text-wrapper {
    background: #000000;
    width: 100vw;
    padding: 2rem;
  }
}
.hero-caption .hero-text-wrapper .title h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 1.1;
  padding-left: 0.25rem;
  margin-bottom: 0.2em;
  box-shadow: inset 0.2em 0 0 -1px #0099d4;
}
@media (max-width: 767.98px) {
  .hero-caption .hero-text-wrapper .title h1 {
    font-size: 1.875rem;
  }
}
.hero-caption .hero-text-wrapper .sub-title {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.4;
  margin-top: 1.5rem;
}
@media (max-width: 767.98px) {
  .hero-caption .hero-text-wrapper .sub-title {
    font-size: 1.125rem;
  }
}
.hero-caption .hero-text-wrapper .btn-primary {
  margin-top: 1.5rem;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .mobile-table {
    margin-left: 155px;
  }
  .mobile-table.scrollable-x {
    width: inherit;
  }
  .mobile-table .spec-table {
    margin-left: 0;
    margin-right: 0;
  }
  .mobile-table .spec-table th:first-child {
    width: inherit;
  }
  .mobile-table .spec-table th:first-child span {
    width: 140px;
    font-weight: normal;
    font-size: 11px;
  }
  .mobile-table .spec-table tbody tr th:first-child {
    position: absolute;
    left: 0;
    border: 0;
    max-height: inherit;
  }
  .mobile-table .spec-table tbody tr td {
    height: 45px;
  }
}
.table-dark {
  font-size: 0.9375rem;
  table-layout: fixed;
}
.table-dark:not(.layout-fixed) {
  table-layout: auto;
}
.table-dark thead,
.table-dark tbody,
.table-dark tfoot {
  background: none;
  border: 0;
}
.table-dark thead th,
.table-dark thead td,
.table-dark tbody th,
.table-dark tbody td,
.table-dark tfoot th,
.table-dark tfoot td {
  color: #ffffff;
  border: 0;
  border-bottom: 1px solid #fff;
}
.table-dark thead th,
.table-dark tbody th,
.table-dark tfoot th {
  background-color: #171717;
}
.table-dark thead th:not(:last-child),
.table-dark tbody th:not(:last-child),
.table-dark tfoot th:not(:last-child) {
  border-right: 1px solid #fff;
}
.table-dark thead td,
.table-dark tbody td,
.table-dark tfoot td {
  background-color: #262525;
}
.table-dark thead tr th,
.table-dark thead tr td {
  background-color: #171717;
}
.table-dark thead th {
  text-transform: uppercase;
  vertical-align: top;
}
.table-dark tbody th {
  text-align: left;
}
.table-dark tbody td {
  text-align: center;
}
.table-dark select {
  margin: 0;
}

.table-light {
  margin: 0;
}
.table-light .display-h4 {
  margin: 0 !important;
  padding: 0.4rem 1rem 0;
  font-size: 1.3rem;
  font-weight: bold;
}
.table-light tbody {
  background-color: #EAEAEA;
}
.table-light thead,
.table-light tbody,
.table-light tfoot {
  border: 0;
}
.table-light thead th,
.table-light thead td,
.table-light thead tr,
.table-light tbody th,
.table-light tbody td,
.table-light tbody tr,
.table-light tfoot th,
.table-light tfoot td,
.table-light tfoot tr {
  color: #000;
  border: 0;
  padding: 0.4rem 1rem;
  background: none;
}
.table-light tbody th {
  text-align: left;
}

.finance-table tbody td {
  text-align: left;
}
.finance-table tbody td:last-child {
  text-align: right;
}
.finance-table thead {
  font-size: 1.15em;
}

.spec-table {
  table-layout: auto;
  width: 100%;
  margin: 0;
  border: 0;
}
.spec-table thead,
.spec-table tbody,
.spec-table tfoot {
  background: none;
  border: 0;
}
.spec-table thead {
  font-size: 1.25rem;
}
.spec-table thead tr {
  background-color: transparent;
}
.spec-table thead th,
.spec-table thead td {
  color: #ffffff;
  line-height: 1.2;
  padding: 0.75rem 1rem;
}
.spec-table thead th strong,
.spec-table thead td strong {
  display: block;
}
.spec-table tbody {
  font-size: 1.25rem;
}
@media (max-width: 767.98px) {
  .spec-table tbody {
    font-size: 1rem;
  }
}
.spec-table tbody tr {
  background: transparent;
}
.spec-table tbody tr th,
.spec-table tbody tr td {
  background-color: rgba(255, 255, 255, 0.1);
}
.spec-table tbody tr:nth-child(2n) th,
.spec-table tbody tr:nth-child(2n) td {
  background-color: rgba(255, 255, 255, 0.05);
}
.spec-table tbody tr th:first-child {
  text-align: right;
  width: 32%;
}
.spec-table tbody tr th:first-child strong {
  display: block;
}
.spec-table tbody th,
.spec-table tbody td {
  line-height: 1.2;
  color: #ffffff;
  padding: 0.75rem 1rem;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
}
.spec-table tfoot {
  font-size: 0.6875rem;
}
.spec-table tfoot td {
  padding-top: 2rem;
}
.spec-table tfoot p {
  font-weight: 300;
}
.spec-table tfoot p:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .spec-table {
    margin: 0;
    border: 0;
  }
  .spec-table thead,
.spec-table tbody {
    font-size: 1rem;
  }
  .spec-table thead th,
.spec-table thead td,
.spec-table tbody th,
.spec-table tbody td {
    border: 2px solid #111;
    padding: 0.75rem 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .spec-table {
    white-space: nowrap;
    margin: 0;
    border: 0;
  }
  .spec-table thead,
.spec-table tbody {
    font-size: 0.9rem;
  }
  .spec-table thead th,
.spec-table thead td,
.spec-table tbody th,
.spec-table tbody td {
    border: 2px solid #111;
    padding: 0.3rem 0.5rem;
    text-align: center;
  }
  .spec-table th:first-child {
    white-space: normal;
    width: 200px;
  }
  .spec-table th:first-child span {
    width: 150px;
    display: block;
    white-space: normal;
  }
  .spec-table thead th {
    padding: 0.4rem !important;
    text-align: center;
    width: 100%;
  }
}

.events-table {
  table-layout: auto;
  max-width: 100%;
  margin: 0;
}
.events-table thead,
.events-table tbody,
.events-table tfoot {
  background: none;
  border: 0;
}
.events-table thead {
  font-size: 0.75em;
}
.events-table thead tr {
  background-color: transparent;
}
.events-table thead th,
.events-table thead td {
  line-height: 1.2;
  padding: 0.75rem;
}
.events-table thead th strong,
.events-table thead td strong {
  display: block;
}
.events-table tbody {
  font-size: 0.6875rem;
}
.events-table tbody tr {
  background: transparent;
}
.events-table tbody tr th,
.events-table tbody tr td {
  background-color: #F0F0F0;
}
.events-table tbody tr:nth-child(2n) th,
.events-table tbody tr:nth-child(2n) td {
  background-color: #F7F7F7;
}
.events-table tbody tr th:first-child {
  text-align: right;
  max-width: 25%;
}
@media (max-width: 991.98px) {
  .events-table tbody tr th:first-child {
    background: transparent;
  }
}
.events-table tbody tr th:first-child strong {
  display: block;
}
.events-table tbody th,
.events-table tbody td {
  line-height: 1.2;
  padding: 0.75rem;
}
.events-table tfoot {
  font-size: 0.6875rem;
}
.events-table tfoot td {
  padding-top: 2rem;
}
.events-table tfoot p {
  font-weight: 300;
}
.events-table tfoot p:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .events-table thead,
.events-table tbody {
    font-size: 1rem;
  }
  .events-table thead th,
.events-table thead td,
.events-table tbody th,
.events-table tbody td {
    border: 2px solid #fff;
    padding: 0.75rem 1.25rem;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .events-table {
    white-space: nowrap;
    margin: 1rem;
  }
  .events-table thead,
.events-table tbody {
    font-size: 0.9rem;
  }
  .events-table thead th,
.events-table thead td,
.events-table tbody th,
.events-table tbody td {
    border: 2px solid #fff;
    padding: 0.3rem 0.5rem;
    text-align: center;
  }
  .events-table th:first-child {
    white-space: normal;
    width: 200px;
  }
  .events-table th:first-child span {
    width: 150px;
    display: block;
    white-space: normal;
  }
  .events-table thead th {
    padding: 0.4rem !important;
    text-align: center;
    width: 100%;
  }
}

.card {
  border-radius: 0;
  border: 0;
}

.box-grid .item {
  max-width: none;
  flex-grow: 1;
}
.box-grid .card {
  background: #403F3F;
  color: #ffffff;
}
.box-grid .card .icon {
  height: 12vw;
}
@media (max-width: 767.98px) {
  .box-grid .card .icon {
    height: 18vw;
  }
}
.box-grid .card .card-body {
  padding: 0 3rem;
}
@media (max-width: 1199.98px) {
  .box-grid .card .card-body {
    padding: 2rem;
  }
}
@media (max-width: 767.98px) {
  .box-grid .card .card-body {
    padding: 1rem 2rem;
  }
}
.box-grid .card .card-body h3 {
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .box-grid .card .card-body h3 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
}
.box-grid .item:nth-of-type(even) .card {
  background: #212121;
}

.cta-item .card .icon {
  height: 9vw;
}
@media (max-width: 767.98px) {
  .cta-item .card .icon {
    height: 18vw;
  }
}
.cta-item .card .card-body {
  padding: 3rem 5rem;
}
@media (max-width: 1199.98px) {
  .cta-item .card .card-body {
    padding: 2rem;
  }
}
@media (max-width: 767.98px) {
  .cta-item .card .card-body {
    padding: 2rem;
  }
}
.cta-item .card .card-body h3 {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
@media (max-width: 767.98px) {
  .cta-item .card .card-body h3 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }
}
@media (max-width: 767.98px) {
  .cta-item .card .card-body .description {
    font-size: 0.75rem;
  }
}
.cta-item .card .card-body .btn-primary {
  margin-top: 1rem;
}

.intro-cards .card {
  background-color: white;
  border: 1px solid #f2f2f2;
  padding: 3rem;
  border-radius: 2px;
}
.intro-cards .card .card-body {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.intro-cards .card .card-body .bold {
  font-weight: 700;
}

.nav-pills {
  margin-top: 1px;
}
.nav-pills .nav-link {
  background-color: #403F3F;
  border-radius: 0;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1rem;
}
@media (max-width: 1199.98px) {
  .nav-pills .nav-link {
    font-size: 1rem;
  }
}
@media (max-width: 991.98px) {
  .nav-pills .nav-link {
    font-size: 0.75rem;
  }
}

.tab-content .card .card-body {
  padding: 0;
}
.tab-content .card .card-body.times {
  padding: 5rem;
  color: #ffffff;
}
.tab-content .find {
  font-size: 1.5rem;
  padding: 5rem;
}
@media (max-width: 1199.98px) {
  .tab-content .find {
    font-size: 1rem;
  }
}
@media (max-width: 991.98px) {
  .tab-content .find {
    font-size: 1.125rem;
  }
}
@media (max-width: 767.98px) {
  .tab-content .find {
    font-size: 1rem;
    padding: 2rem;
  }
}
.tab-content .find h3 {
  text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 1199.98px) {
  .tab-content .find h3 {
    font-size: 1rem;
  }
}

.subaru-accordion {
  margin-top: 1px;
}
.subaru-accordion .card {
  border-radius: 0;
  border: 0;
  background: transparent;
}
.subaru-accordion .card .card-header {
  background: #403F3F;
  border-radius: 0;
  padding: 1.5rem 0;
  position: relative;
  cursor: pointer;
  display: block;
  border-bottom: 0;
}
.subaru-accordion .card .card-header.collapsed:after,
.subaru-accordion .card .card-header h3 {
  margin-top: 0;
  font-size: 1.25rem;
  padding-left: 3rem;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}
@media (max-width: 991.98px) {
  .subaru-accordion .card .card-header.collapsed:after,
.subaru-accordion .card .card-header h3 {
    font-size: 1rem;
    padding: 0 70px 0 15px;
  }
}
.subaru-accordion .card .card-body {
  background: #ffffff;
  padding: 2rem 3rem;
  margin-bottom: 0;
}
.subaru-accordion .card .card-body.times {
  background: #000000;
  color: #ffffff;
}
@media (max-width: 377.98px) {
  .subaru-accordion .card .card-body {
    padding: 1.5rem;
  }
}
.subaru-accordion .card .card-body ul {
  padding-left: 30px;
}
.subaru-accordion .card .card-body ul li {
  list-style: circle;
}

.dealer-footer {
  height: 100px;
}
@media (max-width: 767.98px) {
  .dealer-footer {
    height: 70px;
  }
}

.footer-titles {
  padding: 1rem 0;
  color: #ffffff;
}
.footer-titles a {
  color: #ffffff;
  transition: opacity 0.3s ease;
}
.footer-titles a:hover {
  text-decoration: none;
  opacity: 0.2;
}

ul.footer-list {
  margin: 2rem 0;
  padding: 0;
}
ul.footer-list li {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul.footer-list li a {
  color: #ffffff;
  padding: 0.5rem 0;
  display: inline-block;
  left: 0;
  position: relative;
  transition: left 0.3s ease, opacity 0.3s ease;
}
ul.footer-list li a:hover {
  left: 5px;
  text-decoration: none;
  opacity: 0.2;
}

.bg-footer .subaru-accordion {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.bg-footer .subaru-accordion .card {
  background: transparent;
}
.bg-footer .subaru-accordion .card .card-header {
  background: transparent;
  padding: 1rem 0;
}
.bg-footer .subaru-accordion .card .card-header:after {
  background: transparent;
  width: inherit;
}
.bg-footer .subaru-accordion .card .card-header h3 {
  text-transform: none;
  text-align: left;
  padding-left: 0;
}
.bg-footer .subaru-accordion .card .card-body {
  background: transparent;
  padding: 0;
}
.bg-footer .subaru-accordion .card .card-body ul.footer-list {
  margin: 0;
  padding-left: 0;
}
.bg-footer .subaru-accordion .card .card-body ul.footer-list li a {
  opacity: 0.5;
}
.bg-footer .subaru-accordion .accordion-content .card .card-body {
  background: transparent;
}

ul.footer-social {
  margin-top: 1rem;
  margin-bottom: 0;
}
ul.footer-social li {
  text-decoration: none;
}
ul.footer-social li a {
  color: #767676;
  transition: color 0.3s ease;
}
ul.footer-social li a:hover {
  color: #0099d4;
}
ul.footer-social li a i {
  color: #767676;
  font-size: 2rem;
  transition: color 0.3s ease;
}
ul.footer-social li a i:hover {
  color: #0099d4;
}
@media (min-width: 576px) {
  ul.footer-social {
    margin-top: 1.5rem;
  }
}

.bottom-footer {
  color: #ffffff;
  padding: 1.5rem 0;
}
@media (min-width: 576px) {
  .bottom-footer {
    padding-bottom: 2.5rem;
  }
}
.bottom-footer h5 {
  text-transform: none;
  font-weight: 500;
  font-size: 1rem;
}
.bottom-footer img.im-logo {
  display: block;
  max-width: 100%;
  margin-bottom: 1rem;
  height: 4rem;
  width: auto;
}
.bottom-footer p {
  box-sizing: border-box;
  margin: 0;
  text-align: left;
  font-weight: 200;
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.bottom-footer .bottom-footer__row {
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .bottom-footer .bottom-footer__row {
    flex-wrap: nowrap;
  }
}
.bottom-footer .copyright__col {
  width: 100%;
}
@media (min-width: 768px) {
  .bottom-footer .copyright__col {
    width: auto;
    flex-shrink: 1;
  }
}
.bottom-footer .social-links__col {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (min-width: 576px) {
  .bottom-footer .social-links__col {
    display: none;
    width: auto;
  }
}
@media (min-width: 768px) {
  .bottom-footer .social-links__col {
    display: flex;
    margin-left: 1.5rem;
    flex-shrink: 0;
  }
}
.bottom-footer .social-links__col-2 {
  display: none;
  width: auto;
  margin-left: 1.5rem;
}
@media (min-width: 576px) {
  .bottom-footer .social-links__col-2 {
    display: block;
  }
}
@media (min-width: 768px) {
  .bottom-footer .social-links__col-2 {
    display: none;
  }
}

.modal.cookie-hide {
  display: none !important;
}
.modal .modal-content {
  background-color: #F5F5F5;
  border-radius: 0;
  padding: 2rem;
}
.modal .modal-content .modal-body {
  padding: 0;
}
.modal .modal-content .modal-body .modal-logo {
  padding: 3rem;
}
@media (max-width: 377.98px) {
  .modal .modal-content .modal-body .modal-logo {
    padding: 2rem;
  }
}

.mobile-modal-fix {
  display: flex;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .mobile-modal-fix {
    display: block;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .mobile-modal-fix {
    display: block;
  }
}
.award-modal .modal-content {
  background-color: #ffffff;
  top: 50px;
}
@media (max-width: 991.98px) {
  .award-modal .modal-content {
    padding: 0rem;
  }
}
.award-modal .close {
  position: absolute;
  top: -3rem;
  right: 0;
  background: white;
  opacity: 1;
  padding: 1rem;
  font-size: 1rem;
}
.award-modal .close:hover {
  color: #0099d4;
  opacity: 1 !important;
}
.award-modal h6 {
  font-weight: 400;
}
.award-modal .driven-to-do {
  position: relative;
  margin-bottom: -18px;
  width: 300px;
  font-weight: bold;
}
.award-modal .letter-spacing {
  letter-spacing: 1px;
}
.award-modal .logo {
  width: 175px;
}
@media (max-width: 991.98px) {
  .award-modal .logo {
    width: 120px;
  }
  .award-modal h1 {
    font-size: 1.5rem;
  }
  .award-modal h3, .award-modal h5 {
    font-size: 1rem;
  }
  .award-modal .letter-spacing {
    letter-spacing: 0px;
  }
}
.award-modal.award-modal-gold .border {
  border: 34px solid #ceab52 !important;
}
.award-modal.award-modal-gold .main-color {
  color: #ceab52;
}
@media (max-width: 991.98px) {
  .award-modal.award-modal-gold .border {
    border: 10px solid #ceab52 !important;
  }
}
.award-modal.award-modal-silver .border {
  border: 34px solid #d1d3cf !important;
}
.award-modal.award-modal-silver .main-color {
  color: #d1d3cf;
}
@media (max-width: 991.98px) {
  .award-modal.award-modal-silver .border {
    border: 10px solid #d1d3cf !important;
  }
}
.award-modal.award-modal-bronze .border {
  border: 34px solid #906840 !important;
}
.award-modal.award-modal-bronze .main-color {
  color: #906840;
}
@media (max-width: 991.98px) {
  .award-modal.award-modal-bronze .border {
    border: 10px solid #906840 !important;
  }
}
.award-modal.award-modal-enrolment .border {
  border: 34px solid #0099d4 !important;
}
.award-modal.award-modal-enrolment .main-color {
  color: #0099d4;
}
@media (max-width: 991.98px) {
  .award-modal.award-modal-enrolment .border {
    border: 10px solid #0099d4 !important;
  }
}

.gm-ui-hover-effect {
  opacity: 0.2;
}

.gm-style .gm-style-iw-c {
  padding: 0;
}

.gm-style .gm-style-iw-d {
  padding: 0.75rem;
  background: #0099d4;
}

.gm-style .gm-style-iw-t::after {
  top: -2px;
}

.gm-style .gm-style-iw {
  font-family: "DIN Pro", sans-serif !important;
  font-weight: 700 !important;
  color: #ffffff !important;
}

@media (max-width: 377.98px) {
  .gm-style img {
    max-width: 100px;
  }
}

.gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg, #0099d4 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
}

.gm-style .gm-style-iw-c {
  background-color: #0099d4;
  border-radius: 0;
}

.gm-style .gm-style-iw-d {
  overflow: auto !important;
}
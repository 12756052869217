.banner {
    height: 89vh;
    @include media-breakpoint-down(sm) {
        height: 49vh;
    }
}

.hero-caption {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    flex-flow: column;
    margin-left: 6rem;
    z-index: 1;
    @include media-breakpoint-down(sm) {
        margin-left: 0;
    }
    .hero-text-wrapper {
        background: rgb(0,0,0);
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        color: #ffffff;
        padding: 1rem;
        position: relative;
        z-index: 5;
        padding: 3rem 7em 3rem 3rem;
        height: auto;
        width: 35rem;
        @include media-breakpoint-down(sm) {
            background: $black;
            width: 100vw;
            padding: 2rem;
        }
        .title h1 {
            font-weight: 700;
            text-transform: uppercase;
            font-size: 2.5rem;
            line-height: 1.1;
            padding-left: .25rem;
            margin-bottom: .2em;
            box-shadow: inset 0.2em 0 0 -1px $primary;
            @include media-breakpoint-down(sm) {
                font-size: 1.875rem;
            }
        }
        .sub-title {
            font-size: 1.5rem;
            font-weight: 300;
            line-height: 1.4;
            margin-top: 1.5rem;
            @include media-breakpoint-down(sm) {
                font-size: 1.125rem;
            }
        }
        .btn-primary {
            margin-top: 1.5rem;
        }
    }
}
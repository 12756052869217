html {
  scroll-behavior: smooth;
}
body {
    font-variant-ligatures: none;
  font-feature-settings: "liga" 0;
}
.inline {
    display: inline-block;
}
.py-med {
    padding: 6rem 0;
    @include media-breakpoint-down(md) {
        padding: 3rem 0;
    }
}
.grecaptcha-badge {
    display: none;
}
.map-responsive{
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    @include media-breakpoint-down(md) {
        padding: 0;
        height: 32vh;
    }
}
.map-responsive iframe{
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    @include media-breakpoint-down(md) {
        position: static;
    }
}
.blue-box {
    background: $primary;
    padding: 0.75rem 1rem;
    h2 {
        text-transform: uppercase;
        text-align: center;
        color: $white;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 0;
    }
}
.find {
    h3 {
        text-transform: uppercase;
        font-weight: 700;
        @include media-breakpoint-down(md) {
            font-size: 1.5rem;
        }
    }
    @include media-breakpoint-down(md) {
        padding: 2rem 0;
    }
    .tel {
        font-weight: 700;
    }
}
.cta-box {
    .card-body {
        padding: 0;
    }
    .copy {
        padding: 3rem 5rem 2rem;
        @include media-breakpoint-down(md) {
            padding: 3rem;
        }
        @include media-breakpoint-down(sm) {
            padding: 2rem 3rem 1rem;
        }
        h3 {
            font-size: 2.25rem;
            font-weight: 700;
            text-transform: uppercase;
            @include media-breakpoint-down(lg) {
                font-size: 1.75rem;
            }
            @include media-breakpoint-down(sm) {
                font-size: 1.5rem;
                line-height: 1.3;
            }
        }
    }
}
.content-area {
    background: transparent;
    color: #403F3F;;
    padding: 6rem 0;
    @include media-breakpoint-down(xxl) {
        padding: 3rem 0;
    }
    .card {
        padding: 2rem 1.5rem !important;
        .card-body {
            padding: 0;
        }
    }
}
.review-section {
    background-color: $white;
    // The review cards section with the images within
    .review-section_cards {
        margin-top: 1.5rem;
        @include media-breakpoint-up(md) {
            margin-top: 3rem;
        }
        .col {
            .card {
                background-color: transparent;
                color: $white;
                display: flex;
                justify-content: center;
                text-align: center;
                .review-section_card_img {
                    margin: 0 auto;
                    width: 50px;
                    margin-bottom: 1.5em;
                    @include media-breakpoint-up(md) {
                        width: 65px;
                        margin-bottom: 0.75rem;
                    }
                    @include media-breakpoint-up(lg) {
                        width: 80px;
                        margin-bottom: 1rem;
                    }
                }
            }
            &:nth-of-type(4n+1) {
                background-color: $midgrey;
            }
            &:nth-of-type(4n+2) {
                background-color: $darkgrey;
            }
            &:nth-of-type(4n+3) {
                background-color: $midgrey;
                @include media-breakpoint-down(md) {
                    background-color: $darkgrey;
                }
                @include media-breakpoint-down(sm) {
                    background-color: $midgrey;
                }
            }
            &:nth-of-type(4n+4) {
               background-color: $darkgrey;
                @include media-breakpoint-down(md) {
                    background-color: $midgrey;
                }
                @include media-breakpoint-down(sm) {
                    background-color: $darkgrey;
                }
            }
        }
    }
    // The details section underneath the cards
    .review-section_details, .review-section_listing {
        background-color: $lightgrey-alt;
        padding: 2rem 1rem;
        text-align: center;
        @include media-breakpoint-up(md) {
            padding: 2rem;
            text-align: left;
        }
        @include media-breakpoint-up(lg) {
            padding: 3rem
        }    
        .review-section_details_star_rating {
            align-items: center;
            display: inline-flex;
            .review-section_details_star_rating_number {
                align-items: center;
                background-color: $white;
                border-radius: 100%;
                color: $primary;
                display: flex;
                font-weight: 700;
                height: 40px;
                justify-content: center;
                margin-right: 0.65rem;
                width: 40px;
            }
            .review-section_details_star_rating_stars {
                i {
                    color: $review-stars;
                    font-size: 1.25rem;
                }
            }
            .dealer-title_stars {
                display: inline-flex; 
                position: relative;
                &:hover {
                    .cls-2 {
                        fill: darken($review-stars, 20%);
                    }
                }
                svg {
                    width: 110px;
                    @include media-breakpoint-down(sm) {
                        width: 75px;
                    }
                    .cls-1 {
                        fill: $white;
                    }
                }
                .dealer-title_stars_overlay {
                    position: absolute; 
                    right: 0; 
                    top: 0;
                    .cls-2 {
                        fill: $review-stars;
                    }
                }
            }
        }
        .review-section_customer_metrics_list {
            list-style-type: none;
            padding-left: 0;
            @include media-breakpoint-up(xl) {
                column-count: 4;
            }
            @include media-breakpoint-up(lg) {
                column-count: 3;
            }
            @include media-breakpoint-down(lg) {
                column-count: 2;
            }
            @include media-breakpoint-down(md) {
                column-count: 1;
            }
            .review-section_customer_metrics_list_score {
                color: $primary;
                font-weight: 700;
            }
        }   
        .review-section_details_breakdown {
            color: lighten($secondary, 10%);
            cursor: pointer;
            font-size: 0.8rem;
            font-weight: 700;
            margin-top: 0.35rem;
            text-transform: uppercase;
            transition: ease color 300ms;
            &:hover {
                color: $primary;
            }
        }
        .review-section_details_score_rating {
            display: inline-flex;
            align-items: center;
            .review-section_details_score_rating_number {
                align-items: center;
                background-color: $white;
                border-radius: 100%;
                color: $primary;
                display: flex;
                font-weight: 700;
                height: 70px;
                justify-content: center;
                margin-right: 0.65rem;
                width: 70px;
            }
        }
    }
    .review-section_customer_service_item {
        display: block;
        margin-bottom: 1rem;
        @include media-breakpoint-up(md) {
            display: inline-flex;
            margin-bottom: 0;
        }
         &.review-section_customer_service_item_enrolment {
            i {
                color: $primary;
            }
        }
        &.review-section_customer_service_item_gold {
            i {
                color: $review-stars;
            }
        }
        &.review-section_customer_service_item_silver { 
            i {
                color: $review-stars-silver;
            }
        }
        &.review-section_customer_service_item_bronze {
            i {
                color: $review-stars-bronze;
            }
        }
        .review-section_customer_service_item_title {
            font-weight: 700;
            padding-right: 1rem;
            text-transform: uppercase;
        }
        .review-section_customer_service_item_awards {
            cursor: pointer;
            font-weight: 700;
            color: $primary;
            transition: ease all 0.25s;
            &:hover {
                color: $primarydark;
            }
        }
    }
    .review-section_listing {
        margin-top: 1rem;
        position: relative;
        &:after {
            background: linear-gradient(0deg, $lightgrey-alt 0%, rgba(255,255,255,0) 100%);
            bottom: 0;
            content: "";
            height: 150px;
            left: 0;
            position: absolute;
            right: 2rem;
            @include media-breakpoint-up(lg) {
                bottom: 3rem;
                right: 4rem;
            }
        }
        .review-section_listing_wrapper {
            max-height: 400px;
            overflow-x: hidden;
            overflow-y: scroll;
            .review-section_listing_item {
                display: flex;
                margin-bottom: 2rem;
                padding-right: 2rem;
                .review-section_listing_initials {
                    align-items: center;
                    background-color: $white;
                    border-radius: 100%;
                    color: $primary;
                    display: flex;
                    font-weight: 700;
                    justify-content: center;
                    margin-right: 1rem;
                    height: 50px;
                    width: 50px;
                    @include media-breakpoint-up(lg) {
                        height: 70px;
                        width: 70px;
                    }
                    @include media-breakpoint-up(md) {
                        height: 60px;
                        width: 60px;
                    }
                }
                .review-section_listing_review {
                    width: 100%;
                    .review-section_listing_review_score {
                        font-weight: 700;
                        text-transform: uppercase;
                        .primary {
                            color: $primary;
                        }
                    }
                    .review-section_listing_review_date {
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                }
            }
        }
        @include scroll-override;
    }
}
.average-star_breakdown {
    .average-star_breakdown_stars_item {
        .average-star_breakdown_stars_icons {
            display: inline-flex;
            i {
                color: $review-stars;
                font-size: 1.75rem;
                padding-right: 0.5rem;
            }
        }
        .average-star_breakdown_stars_score {
            align-items: center;
            background-color: $primary;
            border-radius: 100%;
            color: $white;
            display: inline-flex;
            font-weight: 700;
            height: 30px;
            justify-content: center;
            width: 30px;
        }
    }
}
.average-score_breakdown {
    column-count: 2;
    .average-score_breakdown_stars_item {
        margin-bottom: 1rem;
        .average-star_breakdown_stars_icons {
            display: inline-flex;
        }
        .average-star_breakdown_stars_score {
            align-items: center;
            background-color: $primary;
            border-radius: 100%;
            color: $white;
            display: inline-flex;
            font-weight: 700;
            height: 30px;
            justify-content: center;
            width: 30px;
        }
    }
}
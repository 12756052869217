.nav-pills {
    margin-top: 1px;
    .nav-link {
        background-color: $midgrey;
        border-radius: 0;
        color: $white;
        text-transform: uppercase;
        font-size: 1.25rem;
        font-weight: 700;
        padding: 1rem;
        @include media-breakpoint-down(lg) {
            font-size: 1rem;
        }
        @include media-breakpoint-down(md) {
            font-size: 0.75rem;
        }
    }
}
.tab-content {
    .card {
        .card-body {
            padding: 0;
            &.times {
                padding: 5rem;
                color: $white;
            }
        }
    }
    .find {
        font-size: 1.5rem;
        padding: 5rem;
        @include media-breakpoint-down(lg) {
            font-size: 1rem;
        }
        @include media-breakpoint-down(md) {
            font-size: 1.125rem;
        }
        @include media-breakpoint-down(sm) {
            font-size: 1rem;
            padding: 2rem;
        }
        h3 {
            text-transform: uppercase;
            font-weight: 700;
            @include media-breakpoint-down(lg) {
                font-size: 1rem;
            }
        }
    }
}
/* --------------------------------

Primary style

-------------------------------- */

*, *::after, *::before {
    box-sizing: border-box;
}

/* --------------------------------

1. Auto-Hiding Navigation - Simple

-------------------------------- */

.cd-auto-hide-header {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height-small;
    background-color: $color-3;
    @include clearfix;
    /* Force Hardware Acceleration */
    transform: translateZ(0);
    will-change: transform;
    transition: transform .5s;

    &.is-hidden {
        transform: translateY(-100%);
    }

    @include MQ(L) {
        height: $header-height;
    }
}
.header-wrapper {
    height: $header-height-small;

    @include MQ(L) {
        height: $header-height;
    }
}
.cd-auto-hide-header .logo,
.cd-auto-hide-header .nav-trigger {
    position: absolute;
    @include center(y);
}
.img-logo {
    height: 65px;
    @include media-breakpoint-down(md) {
      height: 47px;
    }
}
.cd-auto-hide-header .logo {
    left: 5%;

    a, img {
        display: block;
    }
}

.cd-auto-hide-header .nav-trigger {
    /* vertically align its content */
    display: table;
    height: 100%;
    padding: 0 1em;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $color-1;
    font-weight: bold;
    right: 0;
    border-left: 1px solid shade($color-3, 5%);

    span {
        /* vertically align inside parent element */
        display: table-cell;
        vertical-align: middle;
    }

    em, em::after, em::before {
        /* this is the menu icon */
        display: block;
        position: relative;
        height: 2px;
        width: 22px;
        background-color: $color-1;
        backface-visibility: hidden;
    }

    em {
        /* this is the menu central line */
        margin: 6px auto 14px;
        transition: background-color .2s;
    }

    em::before, em::after {
        position: absolute;
        content: '';
        left: 0;
        transition: transform .2s;
    }

    em::before {
        /* this is the menu icon top line */
        transform: translateY(-6px);
    }

    em::after {
        /* this is the menu icon bottom line */
        transform: translateY(6px);
    }

    @include MQ(L) {
        display: none;
    }
}

.cd-auto-hide-header.nav-open .nav-trigger {
    em {
        /* transform menu icon into a 'X' icon */
        background-color: rgba($color-3, 0);
    }

    em::before {
        /* rotate top line */
        transform: rotate(-45deg);
    }

    em::after {
        /* rotate bottom line */
        transform: rotate(45deg);
    }
}

.cd-primary-nav {
    display: inline-block;
    float: right;
    height: 100%;
    padding-right: 5%;

    > ul {
        position: absolute;
        z-index: 2;
        top: $header-height-small;
        left: 0;
        width: 100%;
        background-color: $color-3;
        display: none;
        box-shadow: 0 14px 20px rgba(#000, .2);

        a {
            /* target primary-nav links */
            display: block;
            height: 50px;
            line-height: 50px;
            padding-left: 5%;
            color: $color-1;
            font-size: 1.25rem;
            border-top: 1px solid shade($color-3, 5%);

            &:hover, &.active {
                color: $color-2;
            }
        }
    }

    @include MQ(L) {
        /* vertically align its content */
        display: table;

        > ul {
            /* vertically align inside parent element */
            display: table-cell;
            vertical-align: middle;
            /* reset mobile style */
            position: relative;
            width: auto;
            top: 0;
            padding: 0;
            @include clearfix;
            background-color: transparent;
            box-shadow: none;

            li {
                display: inline-block;
                float: left;
                margin-right: 1.5em;

                &:last-of-type {
                    margin-right: 0;
                }
            }

            a {
                /* reset mobile style */
                height: auto;
                line-height: normal;
                padding: 0;
                border: none;
            }
        }
    }
}

.nav-open .cd-primary-nav ul,
.cd-primary-nav ul:target {
    /*
        show primary nav - mobile only
        :target is used to show navigation on no-js devices
    */
    display: block;

    @include MQ(L) {
        display: table-cell;
    }
}

/* --------------------------------

2. Auto-Hiding Navigation - with Sub Nav

-------------------------------- */

.cd-secondary-nav {
    position: relative;
    z-index: 1;
    clear: both;
    width: 100%;
    height: $secondary-nav-height-small;
    background-color: $color-1;
    /* Force Hardware Acceleration */
    transform: translateZ(0);
    will-change: transform;
    transition: transform .5s;

    &.nav-end::after {
        opacity: 0;
    }

    ul, li, a {
        height: 100%;
    }

    ul {
        /* enables a flex context for all its direct children */
        display: flex;
        padding: 0 5%;
        @include clearfix;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    li {
        display: inline-block;
        float: left;
        /* do not shrink - elements float on the right of the element */
        flex-shrink: 0;

        &:last-of-type {
            padding-right: 20px;
        }
    }

    a {
        display: block;
        color: $color-3;
        opacity: .6;
        line-height: $secondary-nav-height-small;
        padding: 0 1em;

        &:hover, &.active {
            opacity: 1;
        }
    }

    @include MQ(L) {
        height: $secondary-nav-height;
        overflow: visible;

        ul {
            /* reset mobile style */
            display: block;
            text-align: center;
        }

        li {
            /* reset mobile style */
            float: none;
            flex-shrink: 1;
        }

        a {
            line-height: $secondary-nav-height;

            &.active {
                box-shadow: inset 0 -3px $color-2;
            }
        }
    }
}

/* --------------------------------

3. Auto-Hiding Navigation - with Sub Nav + Hero Image

-------------------------------- */

.cd-secondary-nav {
    &.fixed {
        position: fixed;
        top: $header-height-small;
    }

    &.slide-up {
        transform: translateY(-$header-height-small)
    }

    @include MQ(L) {

        &.fixed {
            top: $header-height;
            /* fixes a bug where nav and subnab move with a slight delay */
            box-shadow: 0 -6px 0 $color-1;
        }

        &.slide-up {
            transform: translateY(-$header-height);
        }
    }
}


/* --------------------------------

Main content

-------------------------------- */

.cd-main-content {
    overflow: hidden;

    &.sub-nav {
        /* to be used if there is sub nav */
        padding-top: $header-height-small + $secondary-nav-height-small;
    }

    &.sub-nav-hero {
        /* to be used if there is hero image + subnav */
        padding-top: 0;

        &.secondary-nav-fixed {
            margin-top: $secondary-nav-height-small;
        }
    }

    @include MQ(L) {
        padding-top: $header-height;
        &.sub-nav-hero.secondary-nav-fixed {
            margin-top: $secondary-nav-height;
        }
    }
}
.dealer-title {
    align-items: center;
    color: $white;
    display: inline-flex;
    font-weight: 700;
    font-size: 1.75rem;
    text-transform: uppercase;
    line-height: 1;
    @include media-breakpoint-down(xl) {
        font-size: 1.5rem;
    }
    @include media-breakpoint-down(md) {
        font-size: 1.125rem;
    }
    @include media-breakpoint-down(sm) {
        display: block;
        font-size: 1rem;
    }
    .dealer-title_link {
        align-items: center;
        display: inline-flex;
        opacity: 1;
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            margin-top: 0.5rem;
        }
        &:hover {
            text-decoration: none;
            .dealer-title_score {
                background-color: $white;
                color: $primary;
            }
        }
        .dealer-title_score {
            align-items: center;
            background-color: $primary;
            border-radius: 100%;
            color: $white;
            display: flex;
            font-size: 0.8rem;
            font-weight: 700;
            height: 30px;
            justify-content: center;
            margin: 0 0.5rem;
            width: 30px;
            transition: ease all 300ms;
            @include media-breakpoint-down(sm) {
                height: 20px;
                width: 20px;
                font-size: 0.6rem;
                margin: 0 0.5rem 0 0;
            }
        }
        .dealer-title_stars {
            display: inline-flex; 
            position: relative;
            &:hover {
                .cls-2 {
                    fill: darken($review-stars, 20%);
                }
            }
            svg {
                width: 110px;
                @include media-breakpoint-down(sm) {
                    width: 75px;
                }
                .cls-1 {
                    fill: $white;
                }
            }
            .dealer-title_stars_overlay {
                position: absolute; 
                right: 0; 
                top: 0;
                .cls-2 {
                    fill: $review-stars;
                }
            }
        }
    }
}
.dealer-address {
    color: $white;
    line-height: 1;
    display: block;
    margin-left: 0;
    vertical-align: super;
    margin-top: 0.5rem;
    @include media-breakpoint-down(xl) {
        font-size: 0.75rem;
    }
    @include media-breakpoint-down(lg) {
        margin-left: 0;
    }
    @include media-breakpoint-down(md) {
        display: none;
    }
}
.border-right {
    border-right: 1px solid $white;
    margin-right: 2rem;
    padding-right: 2rem;
    @include media-breakpoint-down(sm) {
        margin-right: 0.5rem;
        padding-right: 0.5rem;
    }
}
.dealer-tel {
    color: $white;
    font-weight: 700;
    line-height: 1;
    font-size: 1.25rem;
    display: inline-block;
    a {
        display: inline-block;
    }
    @include media-breakpoint-down(lg) {
        font-size: 1rem;
    }
    @include media-breakpoint-down(sm) {
        font-size: 0.75rem;
    }
}
.header-contact {
    color: $white;
    a {
        height: inherit;
        color: $white;
        line-height: 1;
        opacity: 1;
        position: relative;
        left: 0;
        transition: left 0.3s ease,
                    opacity 0.3s ease;
        &:hover {
            text-decoration: none;
            left: 5px;
            opacity: 0.5;
        }
    }
    i {
        margin-right: 0.5rem;
    }
}
/*
    adjust the positioning of in-page links
    http://nicolasgallagher.com/jump-links-and-viewport-positioning/
*/

.cd-main-content.sub-nav :target::before,
.cd-main-content.sub-nav-hero :target::before {
    display: block;
    content: "";
    margin-top: -$secondary-nav-height-small;
    height: $secondary-nav-height-small;
    visibility: hidden;

    @include MQ(L) {
        margin-top: -$secondary-nav-height;
        height: $secondary-nav-height;
    }
}

/* --------------------------------

Intro Section

-------------------------------- */

.cd-hero {
    /* vertically align its content */
    display: table;
    width: 100%;
    margin-top: $header-height-small;
    height: 300px;
    // background: url(images/cd-hero-background.jpg) no-repeat center center;
    background-size: cover;

    .cd-hero-content {
        /* vertically align inside parent element */
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    @include MQ(M) {
        height: 400px;
    }

    @include MQ(L) {
        height: 600px;
        margin-top: $header-height;
    }
}
.bg-dark {
    background-color: $black !important;
}
.bg-mid {
    background-color: $midgrey;
}
.bg-footer {
    background-color: #212121;
}
.bg-light-grey {
    background-color: $lightgrey;
}
.bg-light-grey-alt {
    background-color: $lightgrey-alt;
}
.dark-grad-bg {
    background: rgb(34,34,34);
    background: -moz-linear-gradient(180deg, rgba(34,34,34,1) 0%, rgba(57,57,57,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(34,34,34,1) 0%, rgba(57,57,57,1) 100%);
    background: linear-gradient(180deg, rgba(34,34,34,1) 0%, rgba(57,57,57,1) 100%);
    color: $white;
    .card {
        color: $white;
    }
}
.light-grad-bg {
    background: rgb(213,213,213);
    background: -moz-linear-gradient(180deg, rgba(213,213,213,1) 0%, rgba(245,245,245,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(213,213,213,1) 0%, rgba(245,245,245,1) 100%);
    background: linear-gradient(180deg, rgba(213,213,213,1) 0%, rgba(245,245,245,1) 100%);
}
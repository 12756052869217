.dealer-footer {
    height: 100px;
    @include media-breakpoint-down(sm) {
        height: 70px;
    }
}
.footer-titles {
    padding: 1rem 0;
    color: $white;
    a {
        color: $white;
        transition: opacity 0.3s ease;
        &:hover {
            text-decoration: none;
            opacity: .2;
        }
    }
}
ul.footer-list {
    margin: 2rem 0;
    padding: 0;
    li {
        list-style: none;
        padding: 0;
        margin: 0;
        a {
            color: $white;
            padding: 0.5rem 0;
            display: inline-block;
            left: 0;
            position: relative;
            transition: left 0.3s ease,
                        opacity 0.3s ease;
            &:hover {
                left: 5px;
                text-decoration: none;
                opacity: .2;
            }
        }
    }
}
.bg-footer {
    .subaru-accordion {
        margin-top: 2rem;
        margin-bottom: 3rem;
        .card {
            background: transparent;
            .card-header {
                background: transparent;
                padding: 1rem 0;
                &:after {
                    background: transparent;
                    width: inherit;
                }
                h3 {
                    text-transform: none;
                    text-align: left;
                    padding-left: 0;
                }
            }
            .card-body {
                background: transparent;
                padding: 0;
                ul.footer-list {
                    margin: 0;
                    padding-left: 0;
                    li {
                        a {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
        .accordion-content {
            .card {
                .card-body {
                    background: transparent;
                }
            }
        }
    }
}
ul.footer-social {
    margin-top: 1rem;
    margin-bottom: 0;
    li {
        text-decoration: none;
        a {
            color: $secondary;
            transition: color 0.3s ease;
            &:hover {
                 color: $primary;
            }
            i {
               color: $secondary;
               font-size: 2rem;
               transition: color 0.3s ease;
               &:hover {
                    color: $primary;
               }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        margin-top: 1.5rem;
    }
}
.bottom-footer {
    color: $white;
    padding: 1.5rem 0;
    @include media-breakpoint-up(sm) {
        padding-bottom: 2.5rem;
    }
    h5 {
        text-transform: none;
        font-weight: 500;
        font-size: 1rem;
    }

    img.im-logo {
        display: block;
        max-width: 100%;
        margin-bottom: 1rem;
        height: 4rem;
        width: auto;
    }

    p {
        box-sizing: border-box;
        margin: 0;
        text-align: left;
        font-weight: 200;
        color: rgba(255, 255, 255, 0.5);
        font-size: .875rem;
        line-height: 1.5rem;
    }

    .bottom-footer__row {
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }
    }
    
    .copyright__col {
        width: 100%;
        
        @include media-breakpoint-up(md) {
            width: auto;
            flex-shrink: 1;
        }
    }
    
    .social-links__col {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        
        @include media-breakpoint-up(sm) {
            display: none;
            width: auto;
        }
        
        @include media-breakpoint-up(md) {
            display: flex;
            margin-left: 1.5rem;
            flex-shrink: 0;
        }
    }
    
    .social-links__col-2 {
        display: none;
        width: auto;
        margin-left: 1.5rem;
        
        @include media-breakpoint-up(sm) {
            display: block;
        }
        
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
}
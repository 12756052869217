// fonts

$primary-font: 'Hind', sans-serif;
$secondary-font: 'David Libre', serif;

// header size

$header-height-small: 60px;
$header-height: 80px;

$nav-height: 5.875rem;
$nav-height-small: 80px;
$logo-size: 8.5rem;

$secondary-nav-height-small: 90px;
$secondary-nav-height: 90px;

$font-size-base: 1rem;
// Body
$body-bg: #f8fafc;
// Colors
$primary: #0099d4;
$primarydark: #00658d;
$secondary: #767676;
$secondary-light: #767676;
$dark: $secondary;
$extra-light: #FAF8F4;
$lightgrey: #f5f5f5;
$lightgrey-alt: #E7E7E7;
$darkgrey: #212121;
$midgrey: #403F3F;
$danger: #BA2B5D;
$black: #000000;
$info: #c8c8c8;
$white: #ffffff;
$text-color: #0a0a0a;
$map-popover: $primary;
$review-stars: #ffbb06;
$review-stars-silver: #898989;
$review-stars-bronze: #a67901;



$colors: (
  primary: #0099d4,
  lightgrey: #f5f5f5,
  midgrey: #e7e7e7,
  darkgrey: rgb(60, 60, 59),
  white: #ffffff,
  black: #000,
  pink: #FFCBFD,
  darkerGrey: #212121
);

$gradients: (
  darkgrey-y:
    linear-gradient(180deg, rgba(57, 57, 57, 1) 0%, rgba(34, 34, 34, 1) 100%),
  lightgrey-y: linear-gradient(180deg, #d5d5d5 0%, #f5f5f5 60%),
  lightgrey-radial:
    radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(#eaeae8, 1) 66%),
);

// Alpha
$solid: 1.0;
$alpha: 0.8;

// FORMS
$input-placeholder-color: $primary;

// NAVIGATION

// colors

$color-1: $midgrey; // YANKEES BLUE
$color-2: $primary; // PLUM
$color-3: #ffffff; // WHITE
$color-4: #ECF0F1; // ANTI-FLASH WHITE

// fonts

$primary-font: caecilia, sans-serif;
$secondary-font: proxima-nova, sans-serif;

// header size

// $header-height-small: 60px;
// $header-height: 80px;

// $secondary-nav-height-small: 100px;
// $secondary-nav-height: 90px;

// $secondary-margin-offset: 55px;


@mixin transition {
    transition: background 0.3s ease,
                   opacity 0.3s ease,
                     color 0.3s ease,
                      left 0.3s ease;
}

@mixin boxshadow {
  -webkit-box-shadow: 10px 10px 30px 10px rgba(0,0,0,0.2);
  box-shadow: 10px 10px 30px 10px rgba(0,0,0,0.2);

}

$grid-breakpoints: (
  xs: 0,
  ms: 375px,
  plus: 378px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
  xxxl: 1750px
);

$container-max-widths: (
  ms: 375px,
  plus: 430px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1410px,
  xxxl: 1660px
);

$cards-per-line: (
    xs: 1,
    ms: 1,
    plus: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 3
);

$two-cards-per-line: (
    xs: 1,
    ms: 1,
    plus: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
    xxl: 2
);

$three-cards-per-line: (
    xs: 1,
    ms: 1,
    plus: 1,
    sm: 1,
    md: 2,
    lg: 2,
    xl: 3,
    xxl: 3
);

$four-cards-per-line: (
    xs: 1,
    ms: 1,
    plus: 1,
    sm: 2,
    md: 2,
    lg: 3,
    xl: 3,
    xxl: 4
);

@mixin scroll-override {
  ::-webkit-scrollbar {
      border-radius: 0;
      -webkit-appearance: none;
      width: 7px;
  }
  ::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: $primary;
  }
  // Track 
  ::-webkit-scrollbar-track {
    background: $white;
  }
  // Handle on hover 
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
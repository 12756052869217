.card {
    border-radius: 0;
    border: 0;
}
.box-grid {
    .item {
        max-width: none;
        flex-grow: 1;
    }
    
    .card {
        background: $midgrey;
        color: $white;
        .icon {
            height: 12vw;
            @include media-breakpoint-down(sm) {
                height: 18vw;
            }
        }
        .card-body {
            padding: 0 3rem;
            @include media-breakpoint-down(lg) {
                padding: 2rem;
            }
            @include media-breakpoint-down(sm) {
                padding: 1rem 2rem;
            }
            h3 {
                margin-bottom: 1.5rem;
                text-transform: uppercase;
                @include media-breakpoint-down(sm) {
                    font-size: 1.25rem;
                    margin-bottom: 0.75rem;
                }
            }
        }
    }
    .item:nth-of-type(even) .card {
        background: $darkgrey;
    }
}
.cta-item {
    .card {
        .icon {
            height: 9vw;
            @include media-breakpoint-down(sm) {
                height: 18vw;
            }
        }
        .card-body {
            padding: 3rem 5rem;
            @include media-breakpoint-down(lg) {
                padding: 2rem;
            }
            @include media-breakpoint-down(sm) {
                padding: 2rem;
            }
            h3 {
                margin-bottom: 0.5rem;
                text-transform: uppercase;
                @include media-breakpoint-down(sm) {
                    font-size: 1.25rem;
                    margin-bottom: 0.75rem;
                }
            }
            .description {
                @include media-breakpoint-down(sm) {
                    font-size: 0.75rem;
                }
            }
            .btn-primary {
                margin-top: 1rem;
            }
        }
    }
}
.intro-cards {
    .card {
        background-color: white;
        border: 1px solid darken($white, 5%);
        padding: 3rem;
        border-radius: 2px;
        .card-body {
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            .bold {
                font-weight: 700;
            }
        }
    }
}
.gm-ui-hover-effect {
    opacity: 0.2;
}
.gm-style .gm-style-iw-c {
    padding: 0;
}
.gm-style .gm-style-iw-d {
    padding: 0.75rem;
    background: $map-popover;
}
.gm-style .gm-style-iw-t::after {
    top: -2px;
}
.gm-style .gm-style-iw {
    font-family: 'DIN Pro', sans-serif !important;
    font-weight: 700 !important;
    color: $white !important;
}
.gm-style img {
    @include media-breakpoint-down(ms) {
        max-width: 100px;
    }
}
.gm-style .gm-style-iw-t::after {
    background:linear-gradient(45deg,rgba(0,153,212,1) 50%,rgba(255,255,255,0) 51%,rgba(255,255,255,0) 100%);
}
.gm-style .gm-style-iw-c {
    background-color: $map-popover;
    border-radius: 0;
}
.gm-style .gm-style-iw-d {
    overflow: auto !important;
}







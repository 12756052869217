@include media-breakpoint-only(sm) {
    .mobile-table {
        margin-left: 155px;
        &.scrollable-x {
            width: inherit;
        }
        .spec-table {
            margin-left: 0;
            margin-right: 0;
        }
        .spec-table th:first-child {
            width: inherit;
        }
        .spec-table th:first-child span {
            width: 140px;
            font-weight: normal;
            font-size: 11px;
        }
        .spec-table tbody tr th:first-child {
            position: absolute;
            left: 0;
            border: 0;
            max-height: inherit;
        }
        .spec-table tbody tr td {
            height: 45px;
        }
    }
}
.table-dark {
  font-size: 0.9375rem;
  table-layout: fixed;

  &:not(.layout-fixed) {
    table-layout: auto;
  }

  thead,
  tbody,
  tfoot {
    background: none;
    border: 0;

    th,
    td {
      color: #ffffff;
      border: 0;
      border-bottom: 1px solid #fff;
    }

    th {
      background-color: #171717;

      &:not(:last-child) {
        border-right: 1px solid #fff;
      }
    }

    td {
      background-color: #262525;
    }
  }

  thead {
    tr {
      th,
      td {
        background-color: #171717;
      }
    }

    th {
      text-transform: uppercase;
      vertical-align: top;
    }
  }

  tbody {
    th {
      text-align: left;
    }

    td {
      text-align: center;
    }
  }

  select {
    margin: 0;
  }
}

.table-light {
  margin: 0;
  .display-h4 {
    margin: 0 !important;
    padding: .4rem 1rem 0;
    font-size: 1.3rem;
    font-weight: bold;
  }

  tbody {
    background-color: #EAEAEA;
  }

  thead,
  tbody,
  tfoot {
    border: 0;
    th,
    td,
    tr{
      color: #000;
      border: 0;
      padding: .4rem 1rem;
      background: none;
    }
  }

  tbody {
    th {
      text-align: left;
    }
  }
}

.finance-table {
  tbody {
    td {
      text-align: left;

      &:last-child {
        text-align: right;
      }
    }
  }
  thead {
    font-size: 1.15em;
  }
}

.spec-table {
  table-layout: auto;
  width: 100%;
  margin: 0;
  border: 0;
  thead,
  tbody,
  tfoot {
    background: none;
    border: 0;
  }

  thead {
    font-size: 1.25rem;

    tr {
      background-color: transparent;
    }

    th,
    td {
      color: #ffffff;
      line-height: 1.2;
      padding: 0.75rem 1rem;

      strong {
        display: block;
      }
    }
  }

  tbody {
    font-size: 1.25rem;
    @include media-breakpoint-down(sm) {
        font-size: 1rem;
    }
    tr {
      background: transparent;

      th,
      td {
        background-color: rgba(255, 255, 255, 0.1);
      }

      &:nth-child(2n) {
        th,
        td {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }

      th {
        &:first-child {
          text-align: right;
          width: 32%;

          strong {
            display: block;
          }
        }
      }
    }

    th,
    td {
      line-height: 1.2;
      color: #ffffff;
      padding: 0.75rem 1rem;
      border-right: 2px solid #000;
      border-bottom: 2px solid #000;
    }
  }

  tfoot {
    font-size: 0.6875rem;

    td {
      padding-top: 2rem;
    }

    p {
      font-weight: 300;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-only(md) {
    margin: 0;
    border: 0;
    thead,
    tbody {
      font-size: 1rem;

      th,
      td {
        border: 2px solid #111;
        padding: 0.75rem 1.25rem;
      }
    }
  }
  @include media-breakpoint-only(sm) {
    white-space: nowrap;
    margin: 0;
    border: 0;
    thead,
    tbody {
      font-size: 0.9rem;

      th,
      td {
        border: 2px solid #111;
        padding: 0.3rem 0.5rem;
        text-align: center;
      }
    }
    th {
      &:first-child {
        white-space: normal;
        width: 200px;
        span {
          width: 150px;
          display: block;
          white-space: normal;
        }
      }
    }
    thead {
      th {
        padding: 0.4rem !important;
        text-align: center;
        width: 100%;
      }
    }
  }
}

.events-table {
  table-layout: auto;
  max-width: 100%;
  margin: 0;

  thead,
  tbody,
  tfoot {
    background: none;
    border: 0;
  }

  thead {
    font-size: 0.75em;

    tr {
      background-color: transparent;
    }

    th,
    td {
      line-height: 1.2;
      padding: 0.75rem;

      strong {
        display: block;
      }
    }
  }

  tbody {
    font-size: 0.6875rem;

    tr {
      background: transparent;

      th,
      td {
        background-color: #F0F0F0;
      }

      &:nth-child(2n) {
        th,
        td {
          background-color: #F7F7F7;
        }
      }

      th {
        &:first-child {
          text-align: right;
          max-width: 25%;
          @include media-breakpoint-down(md) {
            background: transparent;
          }

          strong {
            display: block;
          }
        }
      }
    }

    th,
    td {
      line-height: 1.2;
      padding: 0.75rem;
    }
  }

  tfoot {
    font-size: 0.6875rem;

    td {
      padding-top: 2rem;
    }

    p {
      font-weight: 300;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-only(md) {
    thead,
    tbody {
      font-size: 1rem;

      th,
      td {
        border: 2px solid #fff;
        padding: 0.75rem 1.25rem;
      }
    }
  }
  @include media-breakpoint-only(sm) {
      white-space: nowrap;
     margin: 1rem;
     thead,
     tbody {
       font-size: 0.9rem;

       th,
       td {
         border: 2px solid #fff;
         padding: 0.3rem 0.5rem;
         text-align: center;
       }
     }
     th {
       &:first-child {
         white-space: normal;
         width: 200px;
         span {
           width: 150px;
           display: block;
           white-space: normal;
         }
       }
     }
     thead {
       th {
         padding: 0.4rem !important;
         text-align: center;
         width: 100%;
       }
     }
  }
}

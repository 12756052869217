.modal {
    &.cookie-hide {
        display: none !important;
    }
    .modal-content {
        background-color: #F5F5F5;
        border-radius: 0;
        padding: 2rem;
        .modal-body {
            padding: 0;
            .modal-logo {
                padding: 3rem;
                @include media-breakpoint-down(ms) {
                    padding: 2rem;
                }
            }
        }
    }
}
.mobile-modal-fix {
    display: flex;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .mobile-modal-fix {
        display: block;
    }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .mobile-modal-fix {
        display: block;
    }
}

.award-modal {
    .modal-content {
        background-color: $white;
        top: 50px;
        @include media-breakpoint-down(md) {
            padding: 0rem;
        }
    }
    .close {
        position: absolute;
        top: -3rem;
        right: 0;
        background: white;
        opacity: 1;
        padding: 1rem;
        font-size: 1rem;
        &:hover {
            color: $primary;
            opacity: 1 !important;
        }
    }
    h6 { font-weight: 400;}
    .driven-to-do { position: relative; margin-bottom: -18px; width: 300px; font-weight: bold;}
    .letter-spacing { letter-spacing: 1px ;}
    .logo { width: 175px; }
    @include media-breakpoint-down(md) {
       .logo {
            width: 120px;
        }
        h1 {
            font-size: 1.5rem;
        }
        h3, h5 {
            font-size: 1rem;
        } 
        .letter-spacing { letter-spacing: 0px ;}
    }
     &.award-modal-gold {
        .border{ border: 34px solid #ceab52 !important; }
        .main-color { color: #ceab52; }
        @include media-breakpoint-down(md) {
            .border { border: 10px solid #ceab52 !important;}
        }
    }
    &.award-modal-silver {
        .border{ border: 34px solid #d1d3cf !important; }
        .main-color { color: #d1d3cf; }
        @include media-breakpoint-down(md) {
            .border { border: 10px solid #d1d3cf !important;}
        }
    }
    &.award-modal-bronze {
        .border{ border: 34px solid #906840 !important; }
        .main-color { color: #906840; }
        @include media-breakpoint-down(md) {
             .border {border: 10px solid #906840 !important;}
        }
    }
    &.award-modal-enrolment {
        .border{ border: 34px solid $primary !important; }
        .main-color { color: $primary; }
        @include media-breakpoint-down(md) {
             .border {border: 10px solid $primary !important;}
        }
    }
}
